import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Grid, Box, IconButton, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as yup from 'yup';
import { Formik } from "formik";
import useRequestResource from '../../hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import geomotionNoiseMonitoringBotLink from "../../images/geomotion_noise_monitoring_telegram_link.png"

const validationSchema = yup.object({
  username: yup.string().required("Username is required").max(150, "Max Length is 150!"),
  email: yup.string().required("Email is required").max(255, "Max Length is 255!"),
  phone_number: yup.string().max(255, "Max Length is 255!"),
  first_name: yup.string().max(255, "Max Length is 255!"),
  last_name: yup.string().max(255, "Max Length is 255!"),
  telegram_handler: yup.string().max(150, "Max Length is 150!")
})

const validationPasswordSchema = yup.object({
  old_password: yup.string().required("Current password is required").max(150, "Max Length is 150!"),
  new_password: yup.string().required("New password is required").max(150, "Max Length is 150!"),
  new_password_confirmation: yup.string().required("New password confirmation is required").max(150, "Max Length is 150!")
})


export default function UserView() {
  const id = localStorage.getItem("user_id")
  const navigate = useNavigate()
  const { getResource, updateResource, resources } = useRequestResource({ endpoint: "auth", query: "user", resourceLabel: "User" })
  const { updateResource: updatePassword } = useRequestResource({ endpoint: "auth", query: "user/change-password", resourceLabel: "Password" })
  const [is_active, setIsActive] = useState(false)
  const [is_manager, setIsManager] = useState(false)
  const [able_to_reset_password, setCanResetPassword] = useState(false)
  const [has_sms_enabled, setSmsEnable] = useState(false)
  const [has_email_enabled, setEmailEnable] = useState(false)
  const [has_telegram_enabled, setTelegramEnable] = useState(false)
  const [has_mobile_enabled, setMobileEnable] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [devicePermissions, setDevicePermissions] = useState([])


  const [initialValues, setInitialValues] = useState({
    username: "",
    email: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    telegram_handler: "",
    is_superuser: false,
    is_staff: false
  });

  const [initialPasswordValues, setInitialPasswordValues] = useState({
    username: "",
    old_password: "",
    new_password: "",
    new_password_confirmation: ""
  });

  useEffect(() => {
    if (id) {
      getResource(id);
    }
  }, [id, getResource]);

  useEffect(() => {
    if (resources) {
      const data = resources
      setInitialValues({
        username: data.username === null ? "" : data.username,
        email: data.email === null ? "" : data.email,
        phone_number: data.phone_number === null ? "" : data.phone_number,
        first_name: data.first_name === null ? "" : data.first_name,
        last_name: data.last_name === null ? "" : data.last_name,
        telegram_handler: data.telegram_handler === null ? "" : data.telegram_handler,
        is_superuser: data.is_superuser === null ? false : data.is_superuser,
        is_staff: data.is_staff === null? false : data.is_staff
      })
      setInitialPasswordValues({
        username: data.username === null ? "" : data.username
      })
      setDevicePermissions(resources.device_permissions)
      setIsActive(data.is_active)
      setIsManager(data.is_manager)
      setCanResetPassword(data.able_to_reset_password)
      setSmsEnable(data.has_sms_enabled)
      setEmailEnable(data.has_email_enabled)
      setTelegramEnable(data.has_telegram_enabled)
      setMobileEnable(data.has_push_enabled)
      

    }
  }, [resources])

  function handleInformationSubmit(values) {
    const formattedValues = {
      username: values.username,
      email: values.email,
      phone_number: values.phone_number,
      first_name: values.first_name,
      last_name: values.last_name,
      telegram_handler : values.telegram_handler,
      is_active: is_active,
      is_manager: is_manager,
      able_to_reset_password: able_to_reset_password,
      is_superuser: values.is_superuser,
      is_staff: values.is_staff,
      has_sms_enabled : has_sms_enabled,
      has_email_enabled : has_email_enabled,
      has_telegram_enabled : has_telegram_enabled,
      has_push_enabled : has_mobile_enabled,
      device_permissions: devicePermissions.map(obj => obj.id)
    }
    updateResource(id, formattedValues, () => {
      window.location.reload()
    })
  }

  function handlePasswordChange(values) {
    if (values.new_password !== values.new_password_confirmation) {
      setPasswordError(true)
    } else {
      const formattedValues = {
        username: values.username,
        old_password: values.old_password,
        new_password: values.new_password
      }
      updatePassword(id, formattedValues, () => {
        window.location.reload()
      })
    }

  }

  function handleCheck(value, type) {
    switch (type) {
        case "Sms": setSmsEnable(prev => !prev); break;
        case "Telegram": setTelegramEnable(prev => !prev); break;
        case "Email": setEmailEnable(prev => !prev); break;
        case "Mobile": setMobileEnable(prev => !prev); break;
        default : 
    }
}

  return (
    <Card>
      <Card>
        <Card.Header>
          Profile Settings
        </Card.Header>
        <Card.Body>
          <Formik onSubmit={handleInformationSubmit}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {
              (formik) => {
                return (
                  <form onSubmit={formik.handleSubmit}>
                    <Grid xs={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="username"
                          label="Username"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          error={formik.touched.username && Boolean(formik.errors.username)}
                          helperText={formik.touched.username && formik.errors.username}
                          {...formik.getFieldProps('username')}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="email"
                          label="Email"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          {...formik.getFieldProps('email')}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="phone_number"
                          label="Phone Number"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          {...formik.getFieldProps('phone_number')}
                          error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                          helperText={formik.touched.phone_number && formik.errors.phone_number}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="telegram_handler"
                          label="Telegram Handler"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          {...formik.getFieldProps('telegram_handler')}
                          error={formik.touched.telegram_handler && Boolean(formik.errors.telegram_handler)}
                          helperText={formik.touched.telegram_handler && formik.errors.telegram_handler}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="first_name"
                          label="First Name"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          {...formik.getFieldProps('first_name')}
                          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                          helperText={formik.touched.first_name && formik.errors.first_name}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="last_name"
                          label="Last Name"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          {...formik.getFieldProps('last_name')}
                          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                          helperText={formik.touched.last_name && formik.errors.last_name}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <div> Notifications </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-3">
                            <Form.Check
                                style={{ margin: '20px' }}
                                onChange={(value) => handleCheck(value, 'Sms')}
                                inline
                                checked={has_sms_enabled}
                                label='SMS'
                                name="Notifications"
                                type='checkbox'
                                id={`inline-checkbox-Sms`}
                            />
                            <Form.Check
                                style={{ margin: '20px' }}
                                onChange={(value) => handleCheck(value, 'Email')}
                                inline
                                checked={has_email_enabled}
                                label='Email'
                                name="Notifications"
                                type='checkbox'
                                id={`inline-checkbox-Email`}
                            />
                            <Form.Check
                                style={{ margin: '20px' }}
                                onChange={(value) => handleCheck(value, 'Telegram')}
                                inline
                                checked={has_telegram_enabled}
                                label='Telegram'
                                name="Notifications"
                                type='checkbox'
                                id={`inline-checkbox-Telegram`}
                            />
                            <Form.Check
                                style={{ margin: '20px' }}
                                onChange={(value) => handleCheck(value, 'Mobile')}
                                inline
                                checked={has_mobile_enabled}
                                label='Mobile'
                                name="Notifications"
                                type='checkbox'
                                id={`inline-checkbox-Mobile`}
                            />
                      </div>
                      <div style={{ margin: '10px' }}></div>

                      <Grid item xs={12}>
                        <Grid item xs={12}>
                            Telegram Bot Link (Click or Scan on the QR code) - Start chatting with the bot to receive alerts
                        </Grid>
                        <div style={{ margin: '10px' }}></div>
                        <Grid item xs={12}>
                          <a href="https://t.me/geomotion_noise_monitoring_bot">
                           <img src={geomotionNoiseMonitoringBotLink} alt="Geomotion Noise Monitoring Bot"></img>
                          </a>
                        </Grid>   
                      </Grid>

                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <Button
                          type='submit'
                          size="medium"
                          variant="contained"
                          color="primary"
                        >
                          Update Information
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )
              }
            }
          </Formik>
        </Card.Body>
      </Card>
      <div style={{ margin: '20px' }}></div>
      {
        able_to_reset_password ? 
        <Card>
        <Card.Header>
          Change Password
        </Card.Header>
        <Card.Body>
          <Formik onSubmit={handlePasswordChange}
            initialValues={initialPasswordValues}
            enableReinitialize
            validationSchema={validationPasswordSchema}
          >
            {
              (formik) => {
                return (
                  <form onSubmit={formik.handleSubmit}>
                    <Grid xs={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="old_password"
                          label="Current Password"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                          helperText={formik.touched.old_password && formik.errors.old_password}
                          {...formik.getFieldProps('old_password')}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="new_password"
                          label="New Password"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                          helperText={formik.touched.new_password && formik.errors.new_password}
                          {...formik.getFieldProps('new_password')}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="new_password_confirmation"
                            label="Confirm Password"
                            helperText="Confirm Password must match the Password Above"
                            error={passwordError}
                            onChange={(e) => {
                                if (e.target.value !== formik.values.new_password) {
                                    setPasswordError(true)
                                } else {
                                    setPasswordError(false)
                                }
                                formik.handleChange(e);
                            }}
                        />
                      </Grid>
                      <div style={{ margin: '20px' }}></div>
                      <Grid item xs={12}>
                        <Button
                          type='submit'
                          size="medium"
                          variant="contained"
                          color="primary"
                        >
                          Change password
                        </Button>
                      </Grid>
                    </Grid>
                    
                  </form>
                )
              }
            }

          </Formik>
        </Card.Body>
      </Card> : <div></div>
      }


    </Card>

  )
}