import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import "../../App.css"
import Form from 'react-bootstrap/Form';
import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';


const validationSchema = yup.object({
    client_name: yup.string().required("Client name is required").max(255, "Max Length is 255!"),
    consultant_name: yup.string().max(255, "Max Length is 255!"),
    project_title: yup.string().required("Project title is required").max(255, "Max Length is 255!"),
    location: yup.string().required("Location is required").max(255, "Max Length is 255!"),
    access_geologists: yup.array()
})

const EditInstrument = () => {
    const {id} = useParams();
    const [creationDate, handleCreationDateChange] = useState(null)
    const [decommissionedDate, handleDecommissionedDateChange] = useState(null)
    const [lastCalibrationDate, handleLastCalibrationDateChange] = useState(null)
    const [nextCalibrationDate, handleNextCalibrationDateChange] = useState(null)
    const [lastmodifiedDate, handleLastModifiedDateChange] = useState(null)
    const groupInfiniteScrollRef = useRef(null); 
    const userInfiniteScrollRef = useRef(null);
    const locationInfiniteScrollRef = useRef(null);
    const projectInfiniteScrollRef = useRef(null);
    const parserInfiniteScrollRef = useRef(null);
    const accessGroupTextField = useRef(null); 
    const accessUserTextField = useRef(null); 
    const locationTextField = useRef(null);
    const projectTextField = useRef(null);
    const parserTextField = useRef(null); 
    const [location, setLocation] = useState(null)
    const [project, setProject] = useState(null)
    const [parser, setParser] = useState(null)
    const { getResourceList: getLocationList, resourceList: locationList, currentListSize: currentLocationListSize } = useRequestResource({ endpoint: "auth", query: "locations", resourceLabel: "Location List" });
    const { getResourceList: getProjectList, resourceList: projectList, currentListSize: currentProjectListSize } = useRequestResource({ endpoint: "auth", query: "projects", resourceLabel: "Project List" });
    const { getResourceList: getParserList, resourceList: parserList, currentListSize: currentParserListSize } = useRequestResource({ endpoint: "auth", query: "dataparsers", resourceLabel: "Parser List" });
    const { getResourceList: getAccessGroupList, resourceList: accessGroupList, currentListSize: currentAccessGroupListSize } = useRequestResource({ endpoint: "auth", query: "accessgroups", resourceLabel: "Access Group List" });
    const { getResourceList: getAccessUserList, resourceList: accessUserList, currentListSize: currentAccessUserListSize } = useRequestResource({ endpoint: "auth", query: "users", resourceLabel: "User List" });
    const { getResource, updateResource, resources }  = useRequestResource({ endpoint: "auth", query: "instrument", resourceLabel: "Instrument" });
    const navigate = useNavigate();
    const [ showGroupInfiniteScroll, setGroupInfiniteScroll ] = useState(false)
    const [ showUserInfiniteScroll, setUserInfiniteScroll ] = useState(false)
    const [ showLocationInfiniteScroll, setLocationInfiniteScroll ] = useState(false)
    const [ showProjectInfiniteScroll, setProjectInfiniteScroll ] = useState(false)
    const [ showParserInfiniteScroll, setParserInfiniteScroll ] = useState(false)
    const [ accessGroups, setAccessGroups ] = useState([])
    const [ accessUsers, setAccessUsers ] = useState([])
    let offset = 25;

    const intervalOptions = [
        { value: '1 minute', label: '1 minute' },
        { value: '5 minute', label: '5 minute' },
        { value: '30 second', label: '30 second' }
    ]

    const readingOptions = [
        { value: 'NR', label: 'New Reading' },
        { value: 'HR', label: 'Historical Reading' },
    ]

    const [initialValues, setInitialValues] = useState({
        project: "",
        is_inactive: true,
        location: "",
        creation_date: "",
        decommissionedDate: "",
        lastCalibrationDate: "",
        nextCalibrationDate: "",
        last_modified_date: "",
        parser: "",
        interval: "",
        processing_mode: "",
        instrument_name: "",
        instrument_brand: "",
        instrument_model: "",
        instrument_class: "",
        instrument_serial_number: "",
        access_groups: [],
        access_users: []
    });

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [getResource, id])

    useEffect(() => {
        if (resources) {
            setInitialValues({
                project: resources.project ? resources.project : null,
                is_inactive: resources.is_inactive,
                location: resources.instrument_location ? resources.instrument_location : null,
                creation_date: creationDate ? creationDate : null,
                decommissioned_date: decommissionedDate ? decommissionedDate : null,
                last_calibration_date: lastCalibrationDate ? lastCalibrationDate : null,
                next_calibration_date: nextCalibrationDate ? nextCalibrationDate : null,
                last_modified_date: lastmodifiedDate ? lastmodifiedDate : null,
                parser: resources.data_parser ? resources.data_parser : null,
                interval: resources.interval_timing ? resources.interval_timing : null,
                processing_mode: resources.processing_mode ? resources.processing_mode : null,
                instrument_name: resources.instrument_name ? resources.instrument_name : null,
                instrument_brand: resources.instrument_brand ? resources.instrument_brand : null,
                instrument_model: resources.instrument_model ? resources.instrument_model : null,
                instrument_class: resources.instrument_class ? resources.instrument_class : null,
                instrument_serial_number: resources.instrument_serial_number ? resources.instrument_serial_number : null,
                access_groups: accessGroups.map(obj => obj.id),
                access_users: accessUsers.map(obj => obj.id)
            })
            if (resources.access_groups) {
                setAccessGroups(resources.access_groups)
            }
            if (resources.access_users) {
                setAccessUsers(resources.access_users)
            }
            if (resources.project) {
                setProject(resources.project_name)
            }
            if (resources.instrument_location) {
                setLocation(resources.instrument_location_name)
            }
            if (resources.data_parser) {
                setParser(resources.dataparser_name)
            }
            if (resources.creation_date) {
                handleCreationDateChange(new Date(resources.creation_date))
            }
            if (resources.decommissioned_date) {
                handleDecommissionedDateChange(new Date(resources.decommissioned_date))
            }
            if (resources.last_calibration_date) {
                handleLastCalibrationDateChange(new Date(resources.last_calibration_date))
            }
            if (resources.last_modified_date) {
                handleLastModifiedDateChange(new Date(resources.last_modified_date))
            }
            if (resources.next_calibration_date) {
                handleNextCalibrationDateChange(new Date(resources.next_calibration_date))
            }
        }
    }, [resources])

    const fetchGroupData = () => {
        getAccessGroupList(offset)
    }

    const fetchLocationData = () => {
        getLocationList(offset)
    }

    const fetchProjectData = () => {
        getProjectList(offset)
    }

    const fetchParserData = () => {
        getParserList(offset)
    }

    const fetchUserData = () => {
        getAccessUserList(offset)
    }

    useEffect(() => {
        getAccessGroupList();
        getAccessUserList();
        getLocationList()
        getProjectList()
        getParserList()
    }, [getAccessGroupList, getAccessUserList, getLocationList, getProjectList, getParserList])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (groupInfiniteScrollRef.current && !groupInfiniteScrollRef.current.contains(event.target)) {
            if (accessGroupTextField.current && accessGroupTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setGroupInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (userInfiniteScrollRef.current && !userInfiniteScrollRef.current.contains(event.target)) {
            if (accessUserTextField.current && accessUserTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setUserInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (locationInfiniteScrollRef.current && !locationInfiniteScrollRef.current.contains(event.target)) {
            if (locationTextField.current && locationTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setLocationInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (projectInfiniteScrollRef.current && !projectInfiniteScrollRef.current.contains(event.target)) {
            if (projectTextField.current && projectTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setProjectInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (parserInfiniteScrollRef.current && !parserInfiniteScrollRef.current.contains(event.target)) {
            if (parserTextField.current && parserTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setParserInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            project: values.project ? values.project : null,
            path: values.path ? values.path : null,
            is_inactive: values.is_inactive,
            location: values.location ? values.location : null,
            creation_date: creationDate ? creationDate : null,
            decommissioned_date: decommissionedDate ? decommissionedDate : null,
            last_calibration_date: lastCalibrationDate ? lastCalibrationDate : null,
            next_calibration_date: nextCalibrationDate ? nextCalibrationDate : null,
            last_modified_date: lastmodifiedDate ? lastmodifiedDate : null,
            data_parser: values.parser ? values.parser : null,
            interval_timing: values.interval ? values.interval : null,
            processing_mode: values.processing_mode ? values.processing_mode : null,
            instrument_name: values.instrument_name ? values.instrument_name : null,
            instrument_brand: values.instrument_brand ? values.instrument_brand : null,
            instrument_model: values.instrument_model ? values.instrument_model : null,
            instrument_class: values.instrument_class ? values.instrument_class : null,
            instrument_serial_number: values.instrument_serial_number ? values.instrument_serial_number : null,
            access_groups: accessGroups.map(obj => obj.id),
            access_users: accessUsers.map(obj => obj.id)
        }

        updateResource(id, formattedValues, () => {
            navigate(`/instruments`)
        })
    }

    function addGroup(group) {
        const unique = {}
        const results = []
        for (const geo of accessGroups) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = group.id
        if (!unique[key]) {
            results.push(group)
        }
        setAccessGroups(results)
    }

    function deleteGroup(option) {
        const set = new Set([...accessGroups])
        set.delete(option)
        setAccessGroups([...set])
    }

    const access_groups_list = 
    (
        <Grid item xs={12}>
            {accessGroups.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.label}
                            <IconButton size="small" onClick={() => {deleteGroup(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    function addUser(user) {
        const unique = {}
        const results = []
        for (const geo of accessUsers) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = user.id
        if (!unique[key]) {
            results.push(user)
        }
        setAccessUsers(results)
    }

    function deleteUser(option) {
        const set = new Set([...accessUsers])
        set.delete(option)
        setAccessUsers([...set])
    }

    const access_users_list = 
    (
        <Grid item xs={12}>
            {accessUsers.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.first_name} {option.last_name}
                            <IconButton size="small" onClick={() => {deleteUser(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <div style={{alignItems: "start"}}>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Project Information
                </Typography>

                <br />
            <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return <form onSubmit={formik.handleSubmit} style={{textAlign: "start"}}>
                                <Grid container spacing={3} style={{alignItems: "start"}}>
                                <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="Location"
                                                label={location ? "" : "Location"}
                                                {...formik.getFieldProps('Location')}
                                                value={location}
                                                onChange={(e) => {formik.setFieldValue("location", "")}}
                                                ref={locationTextField}
                                                onClick={() => setLocationInfiniteScroll(true)}
                                                error={(formik.touched.location) && Boolean(formik.errors.location)}
                                                helperText={(formik.touched.location) && formik.errors.location}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showLocationInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={locationInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={locationList.results.length}
                                                    next={fetchLocationData}
                                                    hasMore={currentLocationListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {locationList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("location", option.id)
                                                                formik.setFieldTouched("location", false)
                                                                formik.setFieldError("location", "")
                                                                setLocation(`${option.location_name}`)
                                                                setLocationInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.location_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="project"
                                                label={project ? "" : "Project"}
                                                {...formik.getFieldProps('project')}
                                                value={project}
                                                onChange={(e) => {formik.setFieldValue("project", "")}}
                                                ref={projectTextField}
                                                onClick={() => setProjectInfiniteScroll(true)}
                                                error={(formik.touched.project) && Boolean(formik.errors.project)}
                                                helperText={(formik.touched.project) && formik.errors.project}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showProjectInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={projectInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={projectList.results.length}
                                                    next={fetchProjectData}
                                                    hasMore={currentProjectListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {projectList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("project", option.id)
                                                                formik.setFieldTouched("project", false)
                                                                formik.setFieldError("project", "")
                                                                setProject(`${option.project_name}`)
                                                                setProjectInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="parser"
                                                label={parser ? "" : "Data Parser"}
                                                {...formik.getFieldProps('parser')}
                                                value={parser}
                                                onChange={(e) => {formik.setFieldValue("parser", "")}}
                                                ref={parserTextField}
                                                onClick={() => setParserInfiniteScroll(true)}
                                                error={(formik.touched.parser) && Boolean(formik.errors.parser)}
                                                helperText={(formik.touched.parser) && formik.errors.parser}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showParserInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={parserInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={parserList.results.length}
                                                    next={fetchParserData}
                                                    hasMore={currentParserListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {parserList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("parser", option.id)
                                                                formik.setFieldTouched("parser", false)
                                                                formik.setFieldError("parser", "")
                                                                setParser(`${option.name}`)
                                                                setParserInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="Is Inactive" checked={formik.values.is_inactive} onChange={(e) => {formik.setFieldValue("is_inactive", e.target.checked)}}/>
                                            </Form.Group>
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span style={{marginLeft: "25px"}}>Creation Date</span>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Creation Date'
                                                selected={ creationDate }
                                                onChange={ handleCreationDateChange }
                                                name="creationDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span style={{marginLeft: "25px"}}>Decommissioned Date</span>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Decommissioned Date'
                                                selected={ decommissionedDate }
                                                onChange={ handleDecommissionedDateChange }
                                                name="decommissionedDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span style={{marginLeft: "25px"}}>Last calibration Date</span>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Last calibration Date'
                                                selected={ lastCalibrationDate }
                                                onChange={ handleLastCalibrationDateChange }
                                                name="lastCalibrationDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span style={{marginLeft: "25px"}}>Next calibration Date</span>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Next calibration Date'
                                                selected={ nextCalibrationDate }
                                                onChange={ handleNextCalibrationDateChange }
                                                name="nextCalibrationDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span style={{marginLeft: "25px"}}>Last modified Date</span>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Last modified Date'
                                                selected={ lastmodifiedDate }
                                                onChange={ handleLastModifiedDateChange }
                                                name="lastModifiedDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="access_groups"
                                                label="Access Groups"
                                                value=""
                                                ref={accessGroupTextField}
                                                onClick={() => setGroupInfiniteScroll(true)}
                                                error={formik.touched.access_groups && Boolean(formik.errors.access_groups)}
                                                helperText={formik.touched.access_groups && formik.errors.access_groups}
                                            >
                                            </TextField>
                                        </Grid>
                                        

                                        { showGroupInfiniteScroll ? <Grid item xs={12}>

                                        <div ref={groupInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={accessGroupList.results.length}
                                                    next={fetchGroupData}
                                                    hasMore={currentAccessGroupListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {accessGroupList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                addGroup(option)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                                </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            {access_groups_list}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="access_users"
                                                label="Access Users"
                                                value=""
                                                ref={accessUserTextField}
                                                onClick={() => setUserInfiniteScroll(true)}
                                                error={formik.touched.access_users && Boolean(formik.errors.access_users)}
                                                helperText={formik.touched.access_users && formik.errors.access_users}
                                            >
                                            </TextField>
                                        </Grid>
                                        

                                        { showUserInfiniteScroll ? <Grid item xs={12}>

                                        <div ref={userInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={accessUserList.results.length}
                                                    next={fetchUserData}
                                                    hasMore={currentAccessUserListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {accessUserList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                addUser(option)
                                                            }
                                                        }>
                                                            {option.first_name} {option.last_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                                </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            {access_users_list}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="interval"
                                                label="Interval"
                                                {...formik.getFieldProps('interval')}
                                                select
                                                value={formik.values.interval}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.interval && Boolean(formik.errors.interval)}
                                                helperText={formik.touched.interval && formik.errors.interval}
                                            >
                                                {intervalOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="processing_mode"
                                                label="Processing mode"
                                                {...formik.getFieldProps('processing_mode')}
                                                select
                                                value={formik.values.processing_mode}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.processing_mode && Boolean(formik.errors.processing_mode)}
                                                helperText={formik.touched.processing_mode && formik.errors.processing_mode}
                                            >
                                                {readingOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="instrument_name"
                                        label="Instrument Name"
                                        value={formik.values.instrument_name}
                                        {...formik.getFieldProps('instrument_name')}
                                        error={formik.touched.instrument_name && Boolean(formik.errors.instrument_name)}
                                        helperText={formik.touched.instrument_name && formik.errors.instrument_name}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="instrument_serial_number"
                                        label="Instrument Serial Number"
                                        {...formik.getFieldProps('instrument_serial_number')}
                                        error={formik.touched.instrument_serial_number && Boolean(formik.errors.instrument_serial_number)}
                                        helperText={formik.touched.instrument_serial_number && formik.errors.instrument_serial_number}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="instrument_model"
                                        label="Instrument Model"
                                        {...formik.getFieldProps('instrument_model')}
                                        error={formik.touched.instrument_model && Boolean(formik.errors.instrument_model)}
                                        helperText={formik.touched.instrument_model && formik.errors.instrument_model}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="instrument_brand"
                                        label="Instrument Brand"
                                        {...formik.getFieldProps('instrument_brand')}
                                        error={formik.touched.instrument_brand && Boolean(formik.errors.instrument_brand)}
                                        helperText={formik.touched.instrument_brand && formik.errors.instrument_brand}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="instrument_class"
                                        label="Instrument Class"
                                        {...formik.getFieldProps('instrument_class')}
                                        error={formik.touched.instrument_class && Boolean(formik.errors.instrument_class)}
                                        helperText={formik.touched.instrument_class && formik.errors.instrument_class}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Button component={Link}
                                        to={`/instruments`}
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        sx = {{ mr: 2 }}
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        // type="submit"
                                        onClick={() => {handleSubmit(formik.values)}}
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Edit Instrument
                                    </Button>

                                </Grid>
                            </Grid>
                            </form>
                        }
                    }
                </Formik>
                </Paper>
        </div>
    )
}

export default EditInstrument