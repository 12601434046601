import React, { useEffect, useRef, useState } from 'react';
import './DataTable.css';
import {useParams} from "react-router-dom"
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useRequestResource from '../../hooks/useRequestResource';
import {TableA} from '../../tables/tableA'
import {TableB} from '../../tables/tableB'
import Accordion from 'react-bootstrap/Accordion';
import InstrumentTab from '../utils/instrumenttab';
import { LatestTab } from '../utils/latesttab';
import { Grid, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './_card.scss'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CircularProgress from '@mui/material/CircularProgress';
import { CategoryTab } from '../utils/categoryTable';
import { DivIcon } from 'leaflet';
import logo from "../../testLogo1.png"
import GMap from '../utils/maptab';
import { Row } from 'react-bootstrap';
// import '../../style.css'


const DataTable = () => {
    const {id} = useParams();
    const {processedDataList, getProcessedDataList} = useRequestResource({endpoint: "auth", query: "processedrecords", resourceLabel: "Processed Records"})
    const [tables, setTables] = useState([])
    const [instrumentTab, setInstrumentTab] = useState(<div></div>)
    const [latestTab, setLatestTab] = useState(<div></div>)
    const [categoryTab, setCategoryTab] = useState(<div></div>)
    const startDate = useRef(Date.now())
    const endDate = useRef(Date.now());
    const [start, setStart] = useState(Date.now())
    const [end, setEnd] = useState(Date.now())
    const [instrument, setInstrument] = useState(null)
    const offset = 25
    const [printing, setPrinting] = useState(false)

    const addToDoseAndMaxList = (data, list, categoryName) => {
      const utcDate = new Date(data.timestamp);
      const sgtHours = utcDate.getHours();
      let adjustedIndex = -1;
      // Calculate the index based on the hour value
      if (sgtHours >= 7 && sgtHours <= 23) {
        // For hours 07 to 23
        adjustedIndex = sgtHours - 7;
      } else {
        // For hours 00 to 06
        adjustedIndex = sgtHours + 17;
      }
      // In 24hours, 8am - 7pm is ALWAYS IGNORED as 7am-7pm is covered by 12h data
      if (adjustedIndex >= 1 && adjustedIndex <= 11) {
        return;
      // For Non B categories, 7pm - 7am is just covered by a 12h data
      } else if (categoryName !== "B - Residential" && adjustedIndex >= 13 && adjustedIndex <= 23) {
        return;
      } else {
        list[adjustedIndex] = data;
      }
    }

    const addToLeq12 = (data, list) => {
      const utcDate = new Date(data.timestamp);
      const sgtHours = utcDate.getHours();
      if (sgtHours === 7) {
        list[0] = data;
      } else {
        list[1] = data;
      }

      return;
    }
    useEffect(() => {
      getProcessedDataList({instrument: instrument ? instrument.id : id ?? null, start: new Date(startDate.current).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse().join('-'), end: new Date(endDate.current).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse().join('-')})
    }, [getProcessedDataList, instrument]);

    function getData() {
      getProcessedDataList({instrument: instrument ? instrument.id : id ?? null, start: new Date(startDate.current).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse().join('-'), end: new Date(endDate.current).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse().join('-')})
    }

    useEffect(() => {
      if (processedDataList.results) {
        let tableList = []
        let instrument = processedDataList.instrument
        setInstrumentTab(
            <InstrumentTab currentInstrument={instrument} changeInstrument={setInstrument}></InstrumentTab>
        )
        setLatestTab(
          <LatestTab instrument={instrument} latest={processedDataList.latest} location={processedDataList.location}></LatestTab>
        )
        setCategoryTab(
          <CategoryTab instrument={instrument} latest={processedDataList.latest} location={processedDataList.location} category={processedDataList.category}></CategoryTab>
        )
        for (const [key, value] of Object.entries(processedDataList.results)) {
          let leq5 = []
          let leq1 = []
          const leq12 = new Array(2).fill(null)
          const doseAndMaxList = new Array(24).fill(null);


          for (let elem of value) {
            if (elem.type_of_leq === "5min") {
              leq5.push(elem)
            } else if (elem.type_of_leq === "1h") {
              leq1.push(elem)
              if (elem.is_displayed) {
                addToDoseAndMaxList(elem, doseAndMaxList, processedDataList.location.category)
              }
            } else if (elem.type_of_leq === "12h") {
              addToLeq12(elem, leq12)
              if (elem.is_displayed) {
                addToDoseAndMaxList(elem, doseAndMaxList, processedDataList.location.category)
              }
            }
          }
          processedDataList.location.category === "B - Residential" ? tableList.push(
            <TableB leq1={leq1} leq5={leq5} leq12={leq12} doseAndMaxList={doseAndMaxList} date={key}></TableB>
          ) : tableList.push(
            <TableA leq1={leq1} leq5={leq5} leq12={leq12} doseAndMaxList={doseAndMaxList} date={key}></TableA>
          )
        }
        setTables(tableList)
      }
    }, [processedDataList])

    useEffect(() => {
      const fetchData = () => {
        // Your code to fetch data here
        getData()
      };
  
      // Set up a timer to fetch data every 5 seconds
      const timer = setInterval(fetchData, 5 * 60 * 1000);
  
      // Clean up the timer when the component unmounts
      return () => {
        clearInterval(timer);
      };
    }, []);

  const generatePDF = async () => {
    setPrinting(true);
    const pdf = new jsPDF('p', 'px', 'a4');
    
    const pdfWidth = pdf.internal.pageSize.width;
    const pdfHeight = pdf.internal.pageSize.height;

    const leftMargin = 20;
    const rightMargin = 20;
    const topStart = 50;
    const footerMargin = 40;
    const componentYOffset = 10;

    for (let index = 0; index < tables.length; index++) {
      // Adding of Geomotion Logo
      //left-offset, height-offset, width, height
      pdf.addImage(logo, 'PNG', leftMargin, 0, 120, topStart)

      const instrumentContainer = document.getElementById('instrument-tab-id');
      const latestContainer = document.getElementById("latest-tab-id");
      const tableContainer = document.getElementById(`table-item-${index}`);
      
      if (instrumentContainer && latestContainer && tableContainer) {
        const canvasInstrument = await html2canvas(instrumentContainer);
        const canvasLatest = await html2canvas(latestContainer);
        const canvas = await html2canvas(tableContainer);

        const imgDataInstrument = canvasInstrument.toDataURL('image/png');
        const imgDataLatest = canvasLatest.toDataURL('image/png');
        const imgData = canvas.toDataURL('image/png');

        const imgWidthInstrument = pdfWidth - leftMargin - rightMargin;
        const imgHeightInstrument = (canvasInstrument.height * imgWidthInstrument) / canvasInstrument.width;

        const imgWidthLatest = pdfWidth - leftMargin - rightMargin;
        const imgHeightLatest = (canvasLatest.height * imgWidthLatest) / canvasLatest.width;

        const imgWidth = pdfWidth - leftMargin - rightMargin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        var scaleFactor = 1;

        if ((imgHeightInstrument + imgHeightLatest + imgHeight + componentYOffset * 2) > (pdfHeight - topStart - footerMargin)) {
          scaleFactor = (pdfHeight - topStart - footerMargin) / (imgHeightInstrument + imgHeightLatest + imgHeight + componentYOffset * 2)
        }


        pdf.addImage(imgDataInstrument, 'PNG', leftMargin, topStart, scaleFactor * imgWidthInstrument, scaleFactor * imgHeightInstrument);
        pdf.addImage(imgDataLatest, 'PNG', leftMargin, topStart + scaleFactor * (imgHeightInstrument + componentYOffset), scaleFactor * imgWidthLatest, scaleFactor * imgHeightLatest);
        pdf.addImage(imgData, 'PNG', leftMargin, topStart + scaleFactor * (imgHeightInstrument + imgHeightLatest + componentYOffset * 2), scaleFactor * imgWidth, scaleFactor * imgHeight);
      }
      
      //Footer
      pdf.setFontSize(8)
      pdf.text("P+65 6546 5585 | F+65 6546 5595 admin@geomtion.com.sg 50 Ubi Cresent, #01-09 Ubi Techpark, Singapore 408568\nGeomotion (Singapore) Pte Ltd Co. UEN: 200104293D geomotion.com.sg", leftMargin,  pdfHeight - footerMargin + componentYOffset)
      if (index == tables.length - 1) {
        pdf.save('NoiseMonitoringData.pdf');
      } else {
        pdf.addPage();
      }
    }
    
    setPrinting(false)
  }

  return (
    <div>

      <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start"}}>
        <div>
          <div id="instrument-tab-id">{instrumentTab}</div>

          <div style={{ height: "20px" }} />

          <div id="latest-tab-id">{latestTab}</div>
        </div>

        <div style={{height: 400, width: "80%" }}>
          <GMap instruments={[instrument ?? processedDataList.instrument]} height={"450px"}></GMap>
        </div>
      </div>

      <div style={{ height: "20px" }} />

      <div id="latest-tab-id">
        {categoryTab}
      </div>

      {!printing ? (processedDataList.results) ? 
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className='row' style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
          <div className='col'>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
            <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
              Start Date
            </div>
          <DatePicker
            placeholderText='Start Date'
            selected={startDate.current}
            onChange={(e) => {startDate.current = e; setStart(e)}}
            name="startDate"
            dateFormat="MMMM d, yyyy"
            autoComplete='off'
            popperPlacement="top-start"
          />
          </Grid>
          </div>
          <br/>
          <div className='col'>
          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row'}}>
            <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
              End Date
            </div> 
            <DatePicker
              placeholderText='Complete Date'
              selected={endDate.current}
              onChange={(e) => {endDate.current = e; setEnd(e)}}
              name="completeDate"
              dateFormat="MMMM d, yyyy"
              autoComplete='off'
              popperPlacement="top-start"
            />
          </Grid>
          </div>
          </div>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row'}}>
        <Button
              onClick={getData}
              size="medium"
              variant="contained"
              color="primary"
          >
              Get Data
          </Button>
          <Button style={{marginLeft: "20px"}}
              onClick={generatePDF}
              size="medium"
              variant="contained"
              color="primary"
          >
              Print
          </Button>
        </Grid>
      </Grid> 
      :
      <div style={{ width: "80vw", textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh', fontSize: '24px' }}>
        There are no data to be displayed
      </div> :
      <div style={{ width: "80vw", textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '24px' }}>
      {/* The CircularProgress component with a color and size */}
      <CircularProgress color="primary" size={60} />
    </div>
      }
      <br></br>
      <div>
      <div>
        {tables.map((table, index) => (
          <div className="table-item" id={`table-item-${index}`} key={index}>
            {table}
          </div>
        ))}
      </div>
      <br/>
    </div>
    </div>
  );
};

export default DataTable;


