import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'
// import {Chart as ChartJS, LinearScale, LineController, PointElement, Tooltip, Legend, TimeScale, CategoryScale} from "chart.js"; 
import "chartjs-adapter-date-fns";
import { Line, Bar } from 'react-chartjs-2';
import { Grid, TextField, Button, MenuItem } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useRequestResource from '../../hooks/useRequestResource';
import * as XLSX from 'xlsx'
import InfiniteScroll from 'react-infinite-scroll-component';
import { ScatterChart, Scatter, XAxis,  
  YAxis, CartesianGrid, Tooltip } from 'recharts'; 
// import '../../style.css'

// ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, LineController, CategoryScale); 

const ChartComponent = ({ timestamps, noiseData }) => {
  const data = {
    labels: [...timestamps].reverse(),
    datasets: [
      {
          label: 'Noise Level',
          data: [...noiseData].reverse(),
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
      },
    ],
  };

  return (
    <div>
      <Line data={data} options={{
        scales:{
          y: {
            beginAtZero: true,
          },
          x: {
            beginAtZero: true,
            ticks: {
              display: false
            }
          }
        }
      }}/>
    </div>
  );
};

const BarComponent = ({ timestamps, noiseData }) => {
  const data = {
      labels: [...timestamps],
      datasets: [
        {
            label: 'Noise Scatter plot',
            data: [...noiseData],
            pointRadius: 5, // Adjust the point size as needed
            backgroundColor: 'rgba(75, 192, 192, 1)', // Adjust the point color as needed
        },
      ],
    };

  return (
    <div>
      <Scatter data={data} options={{
          scales: {
          x: [{
            type: 'linear', // Use linear scale for x-axis
            position: 'bottom',
            display: true,
          }],
          y: [{
            display: true,
            beginAtZero: false,
            position: 'left',
          }],
        }
      }}/>
    </div>
  );
};
  
const ScatterComponent = ({ timestamps, noiseData }) => {
  const chartWidth = window.innerWidth * 0.8;

  const formattedData = timestamps.map((timestamp, index) => ({
      noise: noiseData[index],
      timestamp: new Date(timestamp)
  }));
  const dateFormatter = (tick) => {
    // Format the date in local string formats
    return new Date(tick).toLocaleString();
  };

  const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="time">{`${payload[0].name} : ${payload[0].value}`}</p>
          <p className="reading">{`${payload[1].name} : ${payload[1].value}`}</p>
        </div>
      );
    }
  }


  return (
      <ScatterChart width={chartWidth} height={chartWidth * 0.5}>
          <CartesianGrid />
          <XAxis type="date" dataKey="timestamp" name="Time" tickFormatter={dateFormatter} hide={true} />
          <YAxis type="number" dataKey="noise" name="Noise Reading"/>
          <Tooltip content={<CustomToolTip />}></Tooltip>
          <Scatter data={formattedData.reverse()} fill="green" />
      </ScatterChart>
  );
};

const NoiseData = () => {
    const {processedDataList, getProcessedDataList} = useRequestResource({endpoint: "auth", query: "processedrecord/get-leq5", resourceLabel: "noise_records_label"})
    const {resourceList: instrumentList, getResourceList: getInstrumentList, currentListSize} = useRequestResource({endpoint: "auth", query: "instruments", resourceLabel: "noise_instruments_label"})
    const infiniteScrollRef = useRef(null); 
    const instrumentTextField = useRef(null); 
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [instrument, setInstrument] = useState(null)
    // const [id, setId] = useState()
    const start = useRef(Date.now())
    const end = useRef(Date.now());
    const [startDate, setStartDate] = useState(Date.now())
    const [endDate, setEndDate] = useState(Date.now())
    const [timestamps, setTimeStamps] = useState([])
    const [noiseData, setData] = useState([])
    const [graphType, setGraphType] = useState("line_graph");
    const [graph, setGraph] = useState(<ChartComponent timestamps={timestamps} noiseData={noiseData} />)

    const offset = 25;

    useEffect(() => {
      getInstrumentList(0)
    }, [getInstrumentList])

    useEffect(() => {
      if (graphType === "line_graph") {
        setGraph(<ChartComponent timestamps={timestamps} noiseData={noiseData} />)
      } else if (graphType === "scatter_graph") {
        setGraph(<ScatterComponent timestamps={timestamps} noiseData={noiseData} />)
      } else {
        setGraph(<div></div>)
      }
    }, [graphType, timestamps, noiseData]);

    useEffect(() => {
        if (processedDataList.results) {
            setTimeStamps(processedDataList.results.map(item => new Date(item.timestamp)))
            setData(processedDataList.results.map(item => parseFloat(item["leq_value"])))
        }
    }, [processedDataList])

    function getData() {
      getProcessedDataList({instrument: instrument ? instrument.id : null, start: new Date(startDate).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-'), end: new Date(endDate).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-')})
    }

    const handleOnExportView = () => {
        var wb = XLSX.utils.book_new();
        var updatedList = processedDataList.results.map((elem) => ({
          ...elem,
          timestamp: new Date(elem.timestamp).toString(),
          rain_start_time: elem.rain_start_time ? new Date(elem.rain_start_time).toString() : null,
          rain_end_time: elem.rain_end_time ? new Date(elem.rain_end_time).toString() : null
        }));
        var ws = XLSX.utils.json_to_sheet(updatedList);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "NoiseMonitoringData.xlsx");
    }

    const fetchData = () => {
      getInstrumentList(offset)
    }

    useEffect(() => {
      // Add a click event listener to the document
      document.addEventListener('click', handleOutsideClick);
      return () => {
        // Clean up the click event listener on component unmount
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []); // Run the effect only once during component mount


    const handleOutsideClick = (event) => {
      // Check if the clicked element is within the InfiniteScroll container or its children
      if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
          if (instrumentTextField.current && instrumentTextField.current.contains(event.target)) {
              return; // Do nothing if the clicked element is the excluded component
            }
        setInfiniteScroll(false); // Close the InfiniteScroll
      }
    };

    const searchAll = (exp) => {
      setInstrument(exp)
      if (exp && exp.trim() !== "") {
          setInstrument(exp)
          getInstrumentList(0, "", "", "", "", exp)
      } else {
          setInstrument("")
          getInstrumentList(0)
      }
    }

    return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    autoComplete='off'
                    id="instrument"
                    label={"Instrument - Click to Choose"}
                    value={instrument ? `${instrument.instrument_name} ${instrument.instrument_location_name}` : ""}
                    ref={instrumentTextField}
                    onClick={() => setInfiniteScroll(true)}
                >
                </TextField>
            </Grid>
            { showInfiniteScroll ? <Grid item xs={12}>
            <div ref={infiniteScrollRef}>
            <InfiniteScroll
                        style={{ maxHeight: "400px" }}
                        dataLength={instrumentList.results.length}
                        next={fetchData}
                        hasMore={currentListSize.current >= offset}
                        loader={<h4>Loading...</h4>}
                        // height={200}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                            </p>
                        
                        }>
                        
                        {instrumentList.results.map((option, index) => {
                            return (
                            <Grid item xs={8}>
                                <MenuItem key={index} value={option.id} style={{border: "1px solid lightgray", borderRadius: "10px"}} onClick={
                                () => {
                                    setInstrument(option)
                                    setInfiniteScroll(false)
                                }
                            }>
                                {option.instrument_name} {option.instrument_location_name}
                            </MenuItem>
                            </Grid>
                            );}
                        )}

                    </InfiniteScroll>
            </div>
            </Grid> : <div></div>}
            </Grid>
            <br/>
            <br/>
            <Grid container spacing={3} style={{width: "60vw"}}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Start Date
                </div>
              <DatePicker
                placeholderText='Start Date'
                selected={startDate}
                onChange={(e) => {setStartDate(e)}}
                name="startDate"
                dateFormat="MMMM d, yyyy"
                autoComplete='off'
                popperPlacement="top-start"
              />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  End Date
                </div> 
                <DatePicker
                  placeholderText='Complete Date'
                  selected={endDate}
                  onChange={(e) => {setEndDate(e)}}
                  name="completeDate"
                  dateFormat="MMMM d, yyyy"
                  autoComplete='off'
                  popperPlacement="top-start"
                />
              </Grid>
              </div>
            </Grid>
        <Grid item xs={12}>
        <Button
              onClick={getData}
              size="medium"
              variant="contained"
              color="primary"
          >
              Get Data
          </Button>
        </Grid>
      </Grid> 
            <label for="graphType">Select Graph Type:</label>
            <select id="graphType" name="graphType" onChange={(e) => {setGraphType(e.target.value)}}>
              <option value="line_graph">Line Graph</option>
              <option value="scatter_graph">Scatter Graph</option>
            </select>

            <br/>

            <div style={{ alignItems: 'center'}}>
              <div style={{flex: 1, margin: "10px"}}>
                  {graph}
              </div>
              <br/>

            </div>
            <br/>
            <Button 
                onClick={handleOnExportView}
                size="medium"
                variant="contained"
                sx={{ mr: 2 }}> 
                Export View
            </Button>
            <br/>
            <br/>
            <br/>
            <table className="excel-table">
                <thead>
                    <tr>
                      <th>Date and Time</th>
                      <th>Current LEQ 5</th>
                      <th>Threshold</th>
                      <th>Exceeded</th>
                    </tr>
                  </thead>
                  <tbody>
                    {processedDataList.results && processedDataList.results.length > 0 ? processedDataList.results.map((elem) => {
                    return <tr>
                        <td>{new Date(elem.timestamp).toLocaleString('en-GB', {hour12: true})}</td>
                        <td>{elem.leq_value}</td>
                        <td>{elem.threshold}</td>
                        <td>{elem.has_exceedded_threshold ? "True" : "False"}</td>
                    </tr>
                    }) : ""}
                  </tbody>
                </table>
            <br/>
            <br/>
        </div>
    )
};

export default NoiseData;