import CssBaseline from "@mui/material/CssBaseline";
import 'bootstrap/dist/css/bootstrap.min.css';  
import AuthContextProvider from "./contexts/AuthContextProvider";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Box } from "@mui/material";
import RequireAuth from "./components/RequireAuth";
import RequireNotAuth from "./components/RequireNotAuth";
import BaseLayout from "./components/BaseLayout";
import SignIn from "./pages/Auth/SignIn";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import EmailSent from "./pages/Auth/EmailSent";
import DataTable from "./pages/NoiseMonitoring/NoiseMonitoringData";
import { ConnectingAirportsOutlined, Edit } from "@mui/icons-material";
import UserView from "./pages/Profile/Profile";
import { AdminRoutes, NoiseMonitoringRoutes, FarmoRainGaugeRoutes, ESP32RainGaugeRoutes, KerlinkGatewayRoutes, VibrationMeterRoutes } from "./routes/CommonRoutes";
import Summary from "./pages/NoiseMonitoring/summary";
import NoiseData from "./pages/NoiseMonitoring/DataListPage";

import ESP32RainData from "./pages/ESP32RainData/ESP32RainData";
import ESP32RainInstrument from "./pages/ESP32RainInstrument/ESP32RainInstrumentList";
import CreateESP32RainInstrument from "./pages/ESP32RainInstrument/CreateESP32RainInstrument";
import EditESP32RainInstrument from "./pages/ESP32RainInstrument/EditESP32RainInstrument";
import RainData from "./pages/RainGauging/RainData";
import RainInstrument from "./pages/RainInstrument/RainInstrumentList";
import CreateRainInstrument from "./pages/RainInstrument/CreateRainInstrument";
import EditRainInstrument from "./pages/RainInstrument/EditRainInstrument";
import KerlinkReading from "./pages/KerlinkReading/KerlinkReading";
import CreateKerlinkDevice from "./pages/KerlinkDevice/CreateKerlinkDevice";
import EditKerlinkDevice from "./pages/KerlinkDevice/EditKerlinkDevice";
import KerlinkDeviceList from "./pages/KerlinkDevice/KerlinkDeviceList";

export function RestrictedRoute({status}) {

  const is_manager = localStorage.getItem("is_manager")
  const has_noise_monitoring_access = localStorage.getItem("Noise Meter")
  const has_farmo_rain_gauge_access = localStorage.getItem("Farmo Rain Gauge")
  const has_esp32_rain_gauge_access = localStorage.getItem("ESP32 Rain Gauge")
  const has_kerlink_gateway_access = localStorage.getItem("Kerlink Gateway")
  const has_vibration_meter_access = localStorage.getItem("Vibration Meter")

  const is_manager_route = (status === "manager" && (is_manager === "true"))
  const is_noise_monitoring_route = (status === "noise_monitoring" && (is_manager === "true" || has_noise_monitoring_access === "true"))
  const is_farmo_rain_gauge_route = (status === "farmo_rain_gauge" && (is_manager === "true" || has_farmo_rain_gauge_access === "true"))
  const is_esp32_rain_gauge_route = (status === "esp32_rain_gauge" && (is_manager === "true" || has_esp32_rain_gauge_access === "true"))
  const is_kerlink_gateway_route = (status === "kerlink_gateway" && (is_manager === "true" || has_kerlink_gateway_access === "true"))
  const is_vibration_meter_route = (status === "vibration_meter" && (is_manager === "true" || has_vibration_meter_access === "true"))


  if (is_manager_route || is_noise_monitoring_route || is_farmo_rain_gauge_route || is_esp32_rain_gauge_route || is_kerlink_gateway_route || is_vibration_meter_route) {
    return (<Outlet/>)
  }

  return (
      <Navigate to="/unauthorized" />
  )
}


export const Unauthorized = () => <h1>Unauthorized</h1>;


function App() {
    const user = localStorage.getItem("user")
    const isAuthenticated = true;

    const [padding, setPadding] = useState({});

    useEffect(() => {
      // Get the viewport width
      const viewportWidth = window.innerWidth;

      // Calculate padding values based on the screen width
      let paddingTop, paddingRight, paddingBottom, paddingLeft;

      if (viewportWidth >= 1200) {
        // For screens with a width of 1200px or more
        paddingTop = '20px';
        // paddingRight = '100px';
        paddingBottom = '20px';
        // paddingLeft = '20px';
      } else if (viewportWidth >= 768) {
        // For screens with a width of 768px or more
        paddingTop = '15px';
        // paddingRight = '40px';
        paddingBottom = '15px';
        // paddingLeft = '10px';
      } else {
        // For smaller screens
        paddingTop = '0px';
        paddingRight = '0px';
        paddingBottom = '0px';
        paddingLeft = '0px';
      }
      // Update the padding object
      setPadding({
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
      });
    }, []);
    
    return  <div style={padding}>
      <CssBaseline />
        <AuthContextProvider>
          <SnackbarProvider>
            <Router>
            <Box
            sx={{ bgcolor: (theme) => theme.palette.background.default, minHeight: "100vh" }}
            style={{
              backgroundImage: `url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.solidbackgrounds.com%2F3600x3600-white-solid-color-background.html&psig=AOvVaw0X6j2kEMQYPmcX-Bcpt-np&ust=1653631446948000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCLj_3qm__PcCFQAAAAAdAAAAABAO")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}>

              <AdminRoutes/>
              <NoiseMonitoringRoutes/>
              <FarmoRainGaugeRoutes/>
              <ESP32RainGaugeRoutes/>
              <KerlinkGatewayRoutes/>
              <VibrationMeterRoutes/>
              <Routes>
                <Route path="/" element={<SignIn />}></Route>
                <Route path="/email-sent" element={<EmailSent />}></Route>
                <Route path={"/send-reset-password/:username/:token/:expiration"} element={<ResetPassword />} />
                <Route element={<RequireAuth/>}>
                  <Route element={<BaseLayout/>}>
                    <Route path={"/summary"} element={<Summary />} />
                    <Route path={"/profile"} element={<UserView />} />
                  </Route>
                </Route>
                <Route path={"/login/token"} element={<SignIn />} />
                <Route path={"/login/reset"} element={<ForgetPassword />} />
                <Route path={"/unauthorized"} element={<Unauthorized/>} />
              </Routes>
            </Box>
            </Router>
          </SnackbarProvider>
        </AuthContextProvider>
    </div>
}

export default App;
