import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';


const validationSchema = yup.object({
    client_name: yup.string().required("Client name is required").max(255, "Max Length is 255!"),
    consultant_name: yup.string().max(255, "Max Length is 255!"),
    project_title: yup.string().required("Project title is required").max(255, "Max Length is 255!"),
    location: yup.string().required("Location is required").max(255, "Max Length is 255!"),
    access_geologists: yup.array()
})

const CreateAccessGroup = () => {
    const groupInfiniteScrollRef = useRef(null); 
    const userInfiniteScrollRef = useRef(null);
    const serverInfiniteScrollRef = useRef(null);
    const accessGroupTextField = useRef(null); 
    const accessUserTextField = useRef(null); 
    const serverTextField = useRef(null); 
    const [server, setServer] = useState(null)
    const { getResourceList: getAccessUserList, resourceList: accessUserList, currentListSize: currentAccessUserListSize } = useRequestResource({ endpoint: "auth", query: "users", resourceLabel: "User List" });
    const { getResourceList: getServerList, resourceList: serverList, currentListSize: currentServerListSize } = useRequestResource({ endpoint: "auth", query: "servers", resourceLabel: "Server List" });
    const { addResource }  = useRequestResource({ endpoint: "auth", query: "accessgroup", resourceLabel: "Access Group" });
    const navigate = useNavigate();
    const [ showGroupInfiniteScroll, setGroupInfiniteScroll ] = useState(false)
    const [ showUserInfiniteScroll, setUserInfiniteScroll ] = useState(false)
    const [ showServerInfiniteScroll, setServerInfiniteScroll ] = useState(false)
    const [ accessGroups, setAccessGroups ] = useState([])
    const [ accessUsers, setAccessUsers ] = useState([])
    let offset = 25;

    const [initialValues, setInitialValues] = useState({
        label: "",
        access_users: []
    });

    const fetchUserData = () => {
        getAccessUserList(offset)
    }

    useEffect(() => {
        getAccessUserList();
        getServerList();
    }, [getAccessUserList, getServerList])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (groupInfiniteScrollRef.current && !groupInfiniteScrollRef.current.contains(event.target)) {
            if (accessGroupTextField.current && accessGroupTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setGroupInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (userInfiniteScrollRef.current && !userInfiniteScrollRef.current.contains(event.target)) {
            if (accessUserTextField.current && accessUserTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setUserInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (serverInfiniteScrollRef.current && !serverInfiniteScrollRef.current.contains(event.target)) {
            if (serverTextField.current && serverTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setServerInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            label: values.label === "" ? null: values.label ,
            access_users: accessUsers.map(obj => obj.id)
        }

        addResource(formattedValues, () => {
            navigate(`/accessgroups`)
        })
    }

    function addGroup(group) {
        const unique = {}
        const results = []
        for (const geo of accessGroups) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = group.id
        if (!unique[key]) {
            results.push(group)
        }
        setAccessGroups(results)
    }

    function deleteGroup(option) {
        const set = new Set([...accessGroups])
        set.delete(option)
        setAccessGroups([...set])
    }

    const access_groups_list = 
    (
        <Grid item xs={12}>
            {accessGroups.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.label}
                            <IconButton size="small" onClick={() => {deleteGroup(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    function addUser(user) {
        const unique = {}
        const results = []
        for (const geo of accessUsers) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = user.id
        if (!unique[key]) {
            results.push(user)
        }
        setAccessUsers(results)
    }

    function deleteUser(option) {
        const set = new Set([...accessUsers])
        set.delete(option)
        setAccessUsers([...set])
    }

    const access_users_list = 
    (
        <Grid item xs={12}>
            {accessUsers.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.first_name} {option.last_name}
                            <IconButton size="small" onClick={() => {deleteUser(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Access Group Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="label"
                                                label="Label"
                                                {...formik.getFieldProps('label')}
                                                error={formik.touched.label && Boolean(formik.errors.label)}
                                                helperText={formik.touched.label && formik.errors.label}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="access_users"
                                                label="Access Users"
                                                value=""
                                                ref={accessUserTextField}
                                                onClick={() => setUserInfiniteScroll(true)}
                                                error={formik.touched.access_users && Boolean(formik.errors.access_users)}
                                                helperText={formik.touched.access_users && formik.errors.access_users}
                                            >
                                            </TextField>
                                        </Grid>
                                        

                                        { showUserInfiniteScroll ? <Grid item xs={12}>

                                        <div ref={userInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={accessUserList.results.length}
                                                    next={fetchUserData}
                                                    hasMore={currentAccessUserListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {accessUserList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                addUser(option)
                                                            }
                                                        }>
                                                            {option.first_name} {option.last_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                                </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            {access_users_list}
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/accessgroups`}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                sx = {{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                // type="submit"
                                                onClick={() => {handleSubmit(formik.values)}}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Access Group
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}

export default CreateAccessGroup;