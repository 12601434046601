import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'
// import {Chart as ChartJS, LinearScale, LineController, PointElement, Tooltip, Legend, TimeScale, CategoryScale} from "chart.js"; 
import "chartjs-adapter-date-fns";
import { Line } from 'react-chartjs-2';
import { Grid, TextField, Button, MenuItem } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useRequestResource from '../../hooks/useRequestResource';
import * as XLSX from 'xlsx'
import InfiniteScroll from 'react-infinite-scroll-component';
import { ScatterChart, Scatter, XAxis,  
  YAxis, CartesianGrid, Tooltip } from 'recharts'; 
// import '../../style.css'

// ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, LineController, CategoryScale); 

const ChartComponent = ({ timestamps, rainData }) => {

    const data = {
      labels: [...timestamps].reverse(),
      datasets: [
        {
            label: 'Rainfall',
            data: [...rainData].reverse(),
            fill: false,
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
        },
      ],
    };
  
    return (
      <div>
        <Line data={data} options={{
          scales:{
            y: {
              beginAtZero: true,
            },
            x: {
              beginAtZero: true,
              ticks: {
                display: false
              }
            }
          }
        }}/>
      </div>
    );
};

const ScatterComponent = ({ timestamps, rainData }) => {
  const chartWidth = window.innerWidth * 0.9;
  const formattedData = timestamps.map((timestamp, index) => ({
      rain: rainData[index],
      timestamp: new Date(timestamp)
  }));
  const dateFormatter = (tick) => {
    // Format the date in local string formats
    return new Date(tick).toLocaleString();
  };

  const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="time">{`${payload[0].name} : ${payload[0].value}`}</p>
          <p className="reading">{`${payload[1].name} : ${payload[1].value}`}</p>
        </div>
      );
    }
  }


  return (
      <ScatterChart width={chartWidth} height={chartWidth * 0.5}>
          <CartesianGrid />
          <XAxis type="date" dataKey="timestamp" name="Time" tickFormatter={dateFormatter} hide={true} />
          <YAxis type="number" dataKey="rain" name="Rain Pulses"/>
          <Tooltip content={<CustomToolTip />}></Tooltip>
          <Scatter data={formattedData.reverse()} fill="green" />
      </ScatterChart>
  );
};
  

const RainData = () => {
    const {resourceList, getResourceList} = useRequestResource({endpoint: "auth", query: "rain_records", resourceLabel: "rain_records_label"})
    const {resourceList: instrumentList, getResourceList: getInstrumentList, currentListSize} = useRequestResource({endpoint: "auth", query: "rain_instruments", resourceLabel: "Farmo Rain Instrument"})
    const infiniteScrollRef = useRef(null); 
    const instrumentTextField = useRef(null); 
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [instrument, setInstrument] = useState(null)
    const [startDate, setStartDate] = useState(Date.now())
    const [endDate, setEndDate] = useState(Date.now())
    const [timestamps, setTimeStamps] = useState([])
    const [rainData, setData] = useState([])
    const [graphType, setGraphType] = useState("line_graph");
    const [graph, setGraph] = useState(<ChartComponent timestamps={timestamps} rainData={rainData} />)

    const offset = 25;

    useEffect(() => {
      getInstrumentList(0)
    }, [getInstrumentList])

    useEffect(() => {
        getResourceList("", "", new Date(Date.now()).toLocaleDateString('en-US'), new Date(Date.now()).toLocaleDateString('en-US'), new Date().getTimezoneOffset() / 60)
    }, [getResourceList])

    useEffect(() => {
        const fetchData = () => {
          // Your code to fetch data here
          getResourceList("", "", new Date(startDate).toLocaleDateString('en-US'), new Date(endDate).toLocaleDateString('en-US'), new Date().getTimezoneOffset() / 60, instrument)
        };
    
        // Set up a timer to fetch data every 5 seconds
        const timer = setInterval(fetchData, 5 * 60 * 1000);
    
        // Clean up the timer when the component unmounts
        return () => {
          clearInterval(timer);
        };
      }, [getResourceList]);

    useEffect(() => {
      if (graphType === "line_graph") {
        setGraph(<ChartComponent timestamps={timestamps} rainData={rainData} />)
      } else if (graphType === "scatter_graph") {
        setGraph(<ScatterComponent timestamps={timestamps} rainData={rainData} />)
      } else {
        setGraph(<div></div>)
      }
    }, [graphType, timestamps, rainData]);
      
    useEffect(() => {
        if (resourceList.results) {
            setTimeStamps(resourceList.results.map(item => new Date(item.timestamp).toLocaleString()))
            setData(resourceList.results.map(item => parseFloat(item.rain)))
            setInstrument(resourceList.results.length > 0 ? resourceList.results[0].device_name : "")
        }
    }, [resourceList])

    function getData() {
      getResourceList("", "", new Date(startDate).toLocaleDateString('en-US'), new Date(endDate).toLocaleDateString('en-US'), new Date().getTimezoneOffset() / 60, instrument)
    }
    
    const handleOnExportView = () => {
      var wb = XLSX.utils.book_new();
      var updatedList = resourceList.results.map((elem) => ({
        ...elem,
        timestamp: new Date(elem.timestamp).toString()
      }));
      var ws = XLSX.utils.json_to_sheet(updatedList);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "FarmoRainData.xlsx");
  }

    const fetchData = () => {
      getInstrumentList(offset)
    }

    useEffect(() => {
      // Add a click event listener to the document
      document.addEventListener('click', handleOutsideClick);
      return () => {
        // Clean up the click event listener on component unmount
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []); // Run the effect only once during component mount


    const handleOutsideClick = (event) => {
      // Check if the clicked element is within the InfiniteScroll container or its children
      if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
          if (instrumentTextField.current && instrumentTextField.current.contains(event.target)) {
              return; // Do nothing if the clicked element is the excluded component
            }
        setInfiniteScroll(false); // Close the InfiniteScroll
      }
    };

    const searchAll = (exp) => {
      setInstrument(exp)
      if (exp && exp.trim() !== "") {
          setInstrument(exp)
          getInstrumentList(0, "", "", "", "", exp)
      } else {
          setInstrument("")
          getInstrumentList(0)
      }
    }

    return (
        <div>
            <Grid container spacing={3}>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    autoComplete='off'
                    id="instrument"
                    label={instrument ? "" : "Instrument"}
                    value={instrument}
                    onClick={() => {setInfiniteScroll(true)}}
                    onChange={(e) => {searchAll(e.target.value)}}
                    ref={instrumentTextField}
                >
                </TextField>
            </Grid>
            { showInfiniteScroll ? <Grid item xs={12}>
            <div ref={infiniteScrollRef} onClick={(e) => e.stopPropagation()}>
            <InfiniteScroll
                        dataLength={instrumentList.results.length}
                        next={fetchData}
                        hasMore={currentListSize.current >= offset}
                        loader={<h4>Loading...</h4>}
                        height={400}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                            </p>
                        
                        }>
                        
                        {instrumentList.results.map((option, index) => {
                            return (
                            <MenuItem key={index} value={option.id} onClick={
                                () => {
                                    setInstrument(`${option.device_name}`)
                                    setInfiniteScroll(false)
                                }
                            }>
                                {option.device_name} | {option.imei}
                            </MenuItem>
                            );}
                        )}

                    </InfiniteScroll>
            </div>
            </Grid> : <div></div>}
        
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="farmo_device_type"
                        label={"Farmo Device Type"}
                        value={resourceList.results.length > 0 ? resourceList.results[0].farmo_device_type : ""}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="imei"
                        label={"IMEI"}
                        value={resourceList.results.length > 0 ? resourceList.results[0].imei : ""}
                        disabled={true}
                    />
                </Grid>
            </Grid>
            <br/>
            <br/>
            <Grid container spacing={3} style={{width: "60vw"}}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Start Date
                </div>
              <DatePicker
                placeholderText='Start Date'
                selected={startDate}
                onChange={(e) => {setStartDate(e)}}
                name="startDate"
                dateFormat="MMMM d, yyyy"
                autoComplete='off'
                popperPlacement="top-start"
              />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  End Date
                </div> 
                <DatePicker
                  placeholderText='Complete Date'
                  selected={endDate}
                  onChange={(e) => {setEndDate(e)}}
                  name="completeDate"
                  dateFormat="MMMM d, yyyy"
                  autoComplete='off'
                  popperPlacement="top-start"
                />
              </Grid>
              </div>
            </Grid>
        <Grid item xs={12}>
        <Button
              onClick={getData}
              size="medium"
              variant="contained"
              color="primary"
          >
              Get Data
          </Button>
        </Grid>
            </Grid> 
            <br/>
            
            <label for="graphType">Select Graph Type:</label>
            <select id="graphType" name="graphType" onChange={(e) => {setGraphType(e.target.value)}}>
              <option value="line_graph">Line Graph</option>
              <option value="scatter_graph">Scatter Graph</option>
            </select>

            <div style={{flex: 1, margin: "10px"}}>
              {graph}
            </div>

            <br/>
            <br/>
            <br/>

            <Button 
                onClick={handleOnExportView}
                size="medium"
                variant="contained"
                sx={{ mr: 2 }}> 
                Export View
            </Button>
            <br/>
            <br/>
            
            <table className="excel-table">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Pulse Count</th>
                  <th>Rain</th>
                  <th>Frame Count</th>
                  <th>Batv</th>
                  <th>Temperature</th>
                  <th>RSRP</th>
                  <th>RSRQ</th>
                </tr>
              </thead>
              <tbody>
                {resourceList.results.map((elem) => {
                  return (
                    <tr>
                        <td>{new Date(elem.timestamp).toLocaleString()} SGT</td>
                        <td>{elem.pulse_count}</td>
                        <td>{elem.rain}</td>
                        
                        <td>{elem.frame_count}</td>
                        
                        <td>{elem.batv}</td>
                        
                        <td>{elem.temperature}</td>
                        
                        <td>{elem.rsrp}</td>
                        <td>{elem.rsrq}</td>
                        
                  </tr>
                  )
                })}
              </tbody>
            </table>
            <br/>
            <br/>
        </div>
    )
};

export default RainData;


