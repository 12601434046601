import { Route, Routes } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import BaseLayout from "../components/BaseLayout";
import { RestrictedRoute } from "../App";
import DashBoard from "../pages/DashBoard/DashBoard";
import CategoryList from "../pages/Category/ListCategory";
import { EditCategory } from "../pages/Category/EditCategory";
import { CreateCategory } from "../pages/Category/CreateCategory";
import ProjectLists from "../pages/Project/ProjectLists";
import ProjectList from "../pages/Project/ProjectLists";
import CreateProject from "../pages/Project/CreateProject";
import EditProject from "../pages/Project/EditProjectDetails";
import InstrumentList from "../pages/Instrument/InstrumentList";
import CreateInstrument from "../pages/Instrument/CreateInstrument";
import EditInstrument from "../pages/Instrument/EditInstrument";
import ServerList from "../pages/Server/ServerList";
import CreateServer from "../pages/Server/CreateServer";
import EditServer from "../pages/Server/EditServer";
import DataParserList from "../pages/Dataparser/DataParserList";
import CreateDataParser from "../pages/Dataparser/CreateDataParser";
import EditDataParser from "../pages/Dataparser/EditDataParser";
import PublicHolidayList from "../pages/PublicHoliday/PublicHolidayList";
import CreatePublicHoliday from "../pages/PublicHoliday/CreatePublicHoliday";
import EditPublicHoliday from "../pages/PublicHoliday/EditPublicHoliday";
import AdminPanel from "../pages/Admin/admin";
import UserEdit from "../pages/Admin/editUser";
import GroupList from "../pages/AccessGroup/GroupList";
import CreateAccessGroup from "../pages/AccessGroup/CreateGroup";
import EditAccessGroup from "../pages/AccessGroup/EditGroup";
import ImportView from "../pages/Import/ImportList"
import ExportView from "../pages/Export/ExportList"
import ExportXmlView from "../pages/ExportXml/ExportXmlList"
import ActivityLogDetails from "../pages/ActivityLog/ActivityLogDetails";
import ActivityLogView from "../pages/ActivityLog/ActivityLogs";
import DjangoJobExecutionView from "../pages/DjangoJobExecutions/DjangoJobExecutions";
import DjangoJobExecutionDetails from "../pages/DjangoJobExecutions/DjangoJobExecutionDetails";
import Notifications from "../pages/NotificationMessage/NotificationMessages";
import Notification from "../pages/NotificationMessage/NotificationMessageDetails";
import LocationList from "src/pages/Location/LocationList";
import CreateLocation from "src/pages/Location/CreateLocation";
import EditLocation from "src/pages/Location/EditLocation";
import NoiseData from "src/pages/NoiseMonitoring/DataListPage";
import DataTable from "src/pages/NoiseMonitoring/NoiseMonitoringData";
import ESP32RainData from "src/pages/ESP32RainData/ESP32RainData";
import ESP32RainInstrument from "src/pages/ESP32RainInstrument/ESP32RainInstrumentList";
import CreateESP32RainInstrument from "src/pages/ESP32RainInstrument/CreateESP32RainInstrument";
import EditESP32RainInstrument from "src/pages/ESP32RainInstrument/EditESP32RainInstrument";
import RainData from "src/pages/RainGauging/RainData";
import RainInstrument from "src/pages/RainInstrument/RainInstrumentList";
import CreateRainInstrument from "src/pages/RainInstrument/CreateRainInstrument";
import EditRainInstrument from "src/pages/RainInstrument/EditRainInstrument";
import KerlinkReading from "src/pages/KerlinkReading/KerlinkReading";
import CreateKerlinkDevice from "src/pages/KerlinkDevice/CreateKerlinkDevice";
import EditKerlinkDevice from "src/pages/KerlinkDevice/EditKerlinkDevice";
import KerlinkDeviceList from "src/pages/KerlinkDevice/KerlinkDeviceList";

export const AdminRoutes = () => <Routes>
    <Route element={<RequireAuth/>}>
        <Route element={<BaseLayout/>}>
            <Route element = {<RestrictedRoute status={"manager"}/>}>
            <Route path={"/dashboard"} element={<DashBoard />} />
            <Route path={"/categories"} element={<CategoryList />} />
            <Route path={"/:id/editcategory"} element={<EditCategory />} />
            <Route path={"/createcategory"} element={<CreateCategory />} />
            <Route path={"/projects"} element={<ProjectList />} />
            <Route path={"/createproject"} element={<CreateProject />} />
            <Route path={"/:id/editproject"} element={<EditProject/>} />
            <Route path={"/instruments"} element={<InstrumentList />} />
            <Route path={"/createinstrument"} element={<CreateInstrument />} />
            <Route path={"/:id/editinstrument"} element={<EditInstrument />} />
            <Route path={"/servers"} element={<ServerList />} />
            <Route path={"/createserver"} element={<CreateServer />} />
            <Route path={"/:id/editserver"} element={<EditServer />} />
            <Route path={"/dataparsers"} element={<DataParserList />} />
            <Route path={"/createdataparser"} element={<CreateDataParser />} />
            <Route path={"/:id/editdataparser"} element={<EditDataParser />} />
            <Route path={"/publicholidays"} element={<PublicHolidayList />} />
            <Route path={"/createpublicholiday"} element={<CreatePublicHoliday />} />
            <Route path={"/:id/editpublicholiday"} element={<EditPublicHoliday />} />
            <Route path={"/locations"} element={<LocationList />} />
            <Route path={"/createlocation"} element={<CreateLocation />} />
            <Route path={"/:id/editlocation"} element={<EditLocation />} />
            <Route path={"/admin_users"} element={<AdminPanel />} />
            <Route path={"/:id/edituser"} element={<UserEdit />} />
            <Route path={"/accessgroups"} element={<GroupList />} />
            <Route path={"/createaccessgroup"} element={<CreateAccessGroup />} />
            <Route path={"/:id/editaccessgroup"} element={<EditAccessGroup />} />
            <Route path={"/imports"} element={<ImportView />} />
            <Route path={"/exports"} element={<ExportView />} />
            <Route path={"/exportxmls"} element={<ExportXmlView />} />
            <Route path={"/logs"} element={<ActivityLogView />} />
            <Route path={"/:id/log"} element={<ActivityLogDetails />} />
            <Route path={"/django_job_executions"} element={<DjangoJobExecutionView />} />
            <Route path={"/:id/django_job_execution"} element={<DjangoJobExecutionDetails />} />
            <Route path={"/notifications"} element={<Notifications/>}></Route>
            <Route path={"/:id/notification"} element={<Notification/>}></Route>
            </Route>
        </Route>
    </Route>
</Routes>

export const NoiseMonitoringRoutes = () => <Routes>
    <Route element={<RequireAuth/>}>
        <Route element={<BaseLayout/>}>
            <Route element = {<RestrictedRoute status={"noise_monitoring"}/>}>
                <Route path={"/datatable"} element={<DataTable />} />
                <Route path={"/:id/datatable"} element={<DataTable />} />
                <Route path={"/datalist"} element={<NoiseData />} />
            </Route>
        </Route>
    </Route>  
</Routes>

export const FarmoRainGaugeRoutes = () => <Routes>
    <Route element={<RequireAuth/>}>
        <Route element={<BaseLayout/>}>
            <Route element = {<RestrictedRoute status={"farmo_rain_gauge"}/>}>
                <Route path={"/rain"} element={<RainData/>} />
                <Route path={"/raininstruments"} element={<RainInstrument/>} />
                <Route path={"/createraininstrument"} element={<CreateRainInstrument/>} />
                <Route path={"/:id/editraininstrument"} element={<EditRainInstrument/>} />
            </Route>
        </Route>
    </Route>  
</Routes>

export const ESP32RainGaugeRoutes = () => <Routes>
    <Route element={<RequireAuth/>}>
        <Route element={<BaseLayout/>}>
            <Route element = {<RestrictedRoute status={"esp32_rain_gauge"}/>}>
                <Route path={"/esp32rain"} element={<ESP32RainData/>} />
                <Route path={"/esp32raininstruments"} element={<ESP32RainInstrument/>} />
                <Route path={"/createesp32raininstrument"} element={<CreateESP32RainInstrument/>} />
                <Route path={"/:id/editesp32raininstrument"} element={<EditESP32RainInstrument/>} />
            </Route>
        </Route>
    </Route>  
</Routes>

export const KerlinkGatewayRoutes = () => <Routes>
    <Route element={<RequireAuth/>}>
        <Route element={<BaseLayout/>}>
            <Route element = {<RestrictedRoute status={"kerlink_gateway"}/>}>
                <Route path={"/kerlinkreadings"} element={<KerlinkReading/>} />
                <Route path={"/kerlinkdevices" } element={<KerlinkDeviceList/>}/>
                <Route path={"/createkerlinkdevice"} element={<CreateKerlinkDevice/>} />
                <Route path={"/:id/editkerlinkdevice"} element={<EditKerlinkDevice/>} />
            </Route>
        </Route>
    </Route>  
</Routes>

export const VibrationMeterRoutes = () => <Routes>
    <Route element={<RequireAuth/>}>
        <Route element={<BaseLayout/>}>
            <Route element = {<RestrictedRoute status={"vibration_meter"}/>}>
            </Route>
        </Route>
    </Route>  
</Routes>
