import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
const iconPath = require("../../instrument.png")

const GMap = ({instruments, height}) => {

  useEffect(() => {
  }, [instruments])

  const singaporeCenter = { lat: 1.3521, lng: 103.8198 };
  const mapContainerStyle = {
    width: '100%',
    height: `${height}`, // Set the height as per your requirement
  };

  const mapStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      elementType: 'icon',
      stylers: [{ visibility: 'off' }],
    },
  ];

  

  // Define the marker position for Paya Lebar
  // const payaLebarPosition = { lat: 1.3180, lng: 103.8914 };
  // const iconPath = "../../logo.png";
  
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      <div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={singaporeCenter}
          zoom={12} // Set the zoom level to 12
          options={{ styles: mapStyles }}
        >
          {/* Add a Marker component for Paya Lebar */}
          {instruments.map((e) => {
            return <Marker
            title={e.instrument_name + "\n" + e.instrument_model + "\n" + e.instrument_brand}
            icon={{
              url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
              // anchor: new window.google.maps.Point(16, 32),
            }} 
            position={{
              lat: parseFloat(e.instrument_location_latitude),
              lng: parseFloat(e.instrument_location_longitude)
            }}
          >
          </Marker>})}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default GMap;
