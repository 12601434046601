import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuList, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from '../../hooks/useRequestResource';
import '../../style.css'      


export default function NotificationMessageDetails() {
    const { id } = useParams();
    const { resources, getResource } = useRequestResource({ endpoint: "auth", query: "notification", resourceLabel: "Notification" });
    const navigate = useNavigate();
    const [ details, setDetails ] = useState([]);
    const [initialValues, setInitialValues] = useState({
        username: "",
        timestamp: "",
        message_type: "",
        status: "",
        notification_channel: "",
        message: "",
        error_message: ""   
    });

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);
    

    useEffect(() => {

        if (resources) {
            setInitialValues({
                username : resources.username === null ? "" : resources.username,
                timestamp : resources.timestamp === null ? "" : resources.timestamp,
                message_type : resources.message_type === null ? "" : resources.message_type,
                status : resources.status === null ? "" : resources.status,
                notification_channel : resources.notification_channel === null ? "" : resources.notification_channel,
                message : resources.message === null ? "" : resources.message,
                error_message : resources.error_message === null ? "" : resources.error_message
            })
        }
    }, [resources])



    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Notification Log
                </Typography>

                <br />

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {
                        (formik) => {
                            return (

                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="username"
                                                label="User"
                                                {...formik.getFieldProps('username')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="timestamp"
                                                label="Timestamp"
                                                {...formik.getFieldProps('timestamp')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="message_type"
                                                label="Message Type"
                                                {...formik.getFieldProps('message_type')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="status"
                                                label="Status"
                                                {...formik.getFieldProps('status')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="notification_channel"
                                                label="Notification Channel"
                                                {...formik.getFieldProps('notification_channel')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>                                       

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="message"
                                                label="Message"
                                                {...formik.getFieldProps('message')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                                multiline
                                            />
                                        </Grid>   

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="error_message"
                                                label="Error Message"
                                                {...formik.getFieldProps('error_message')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                                multiline
                                            />
                                        </Grid>   

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/notifications`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}