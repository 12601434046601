import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from 'react-datepicker';

import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';


const validationSchema = yup.object({
    client_name: yup.string().required("Client name is required").max(255, "Max Length is 255!"),
    consultant_name: yup.string().max(255, "Max Length is 255!"),
    project_title: yup.string().required("Project title is required").max(255, "Max Length is 255!"),
    location: yup.string().required("Location is required").max(255, "Max Length is 255!"),
    access_geologists: yup.array()
})

const CreatePublicHoliday = () => {

    const { addResource }  = useRequestResource({ endpoint: "auth", query: "publicholiday", resourceLabel: "Public holiday" });
    const navigate = useNavigate();
    const [date, setDate] = useState()

    let offset = 25;

    const [initialValues, setInitialValues] = useState({
        name: "",
        date: ""
    });

    const handleSubmit = values => {
        const formattedValues = {
            name: values.name === "" ? null: values.name ,
            date: date ? date : null
        }

        addResource(formattedValues, () => {
            navigate(`/publicholidays`)
        })
    }


    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Public Holiday Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="name"
                                                label="Name"
                                                {...formik.getFieldProps('name')}
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                                helperText={formik.touched.name && formik.errors.name}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Date'
                                                selected={ date }
                                                onChange={ setDate }
                                                name="Date"
                                                dateFormat="MMMM d, yyyy"
                                                autoComplete='off'
                                                popperPlacement="bottom-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/publicholidays`}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                sx = {{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                // type="submit"
                                                onClick={() => {handleSubmit(formik.values)}}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Public Holiday
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}

export default CreatePublicHoliday;