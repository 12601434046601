import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DatasetIcon from '@mui/icons-material/Dataset';
import DnsIcon from '@mui/icons-material/Dns';
import TableChartIcon from '@mui/icons-material/TableChart';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ApiIcon from '@mui/icons-material/Api';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CategoryIcon from '@mui/icons-material/Category';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CreateIcon from '@mui/icons-material/Create';
import GradientIcon from '@mui/icons-material/Gradient';
import GrassIcon from '@mui/icons-material/Grass';
import GroupsIcon from '@mui/icons-material/Groups';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ListIcon from '@mui/icons-material/List';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PatternOutlinedIcon from '@mui/icons-material/PatternOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ScienceIcon from '@mui/icons-material/Science';
import TableViewIcon from '@mui/icons-material/TableView';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import PatternIcon from '@mui/icons-material/Pattern';
import WaterIcon from '@mui/icons-material/Water';
import BiotechIcon from '@mui/icons-material/Biotech';
import ConstructionIcon from '@mui/icons-material/Construction';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import YardIcon from '@mui/icons-material/Yard';
import HandymanIcon from '@mui/icons-material/Handyman';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoreHole, faSitemap, faFlaskVial } from '@fortawesome/free-solid-svg-icons'
import { TbAlpha, TbBeta, TbOmega } from "react-icons/tb";
import { GiWaterDrop, GiDrill, GiStoneStack,GiStarFormation, GiMountains, GiMiner, GiGasMask, GiTriforce, GiChemicalDrop, GiChemicalTank } from "react-icons/gi";
import { FaSearchLocation, FaAtlassian, FaFlask, FaFilter } from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { BsJournalBookmarkFill, BsPaintBucket } from "react-icons/bs";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import { GlobalStyles, useTheme } from "@mui/material";
import { BuildCircle, InvertColors } from "@mui/icons-material";
import useRequestAuth from '../../hooks/useRequestAuth';

const drawerWidth = 240;

const commonRoutes  = [
    {
        key: "summary",
        to: "/summary",
        name: "Summary",
        icon: <TableChartIcon size={24}/>,
    },
]

const managerRoutes = [
    {
        key: "dashboard",
        to: "/dashboard",
        name: "Dashboard",
        icon: <QueryStatsIcon />,
    },
    {
        key: "admin",
        to: "/admin_users",
        name: "User Management",
        icon: <AdminPanelSettingsIcon size={24}/>
    },
    {
        key: "accessgroups",
        to: "/accessgroups",
        name: "Access Group",
        icon: <GroupsIcon size={24}/>
    },
    {
        key: "logs",
        to: "/logs",
        name: "Logs",
        icon: <AdminPanelSettingsIcon size={24}/>    
    },
    {
        key: "servers",
        to: "/servers",
        name: "Servers",
        icon: <DnsIcon size={24}/>
    },
    {
        key: "dataparsers",
        to: "/dataparsers",
        name: "Data Parsers",
        icon: <DatasetIcon size={24}/>
    }, {
        key: "imports",
        to: "/imports",
        name: "Import Files",
        icon: <SaveAsIcon size={24}/>        
    }, {
        key: "exports",
        to: "/exports",
        name: "Export Files",
        icon: <SummarizeIcon size={24}/>        
    }, {
        key: "exportxmls",
        to: "/exportxmls",
        name: "Export XML",
        icon: <SummarizeIcon size={24}/>        
    }, {
        key: "django_job_executions",
        to: "/django_job_executions",
        name: "Django Job Executions",
        icon: <AssessmentIcon size={24}/>        
    },
    {
        key: "categories",
        to: "/categories",
        name: "Categories",
        icon: <CategoryIcon size={24}/>,
    },
    {
        key: "projects",
        to: "/projects",
        name: "Projects",
        icon: <AiOutlineFundProjectionScreen size={24}/>,
    },
    {
        key: "instruments",
        to: "/instruments",
        name: "Instruments",
        icon: <ConstructionIcon size={24}/>
    },
    {
        key: "locations",
        to: "/locations",
        name: "Locations",
        icon: <LocationOnIcon size={24}/>
    },
    {
        key: "publicholidays",
        to: "/publicholidays",
        name: "Public Holidays",
        icon: <InsertInvitationIcon size={24}/>
    },
    {
        key: "notifications",
        to: "/notifications",
        name: "Notifications",
        icon: <AnnouncementIcon size={24}/>
    },
]

const noiseMonitoringRoutes = [
    {
        key: "datatable",
        to: "/datatable",
        name: "Noise",
        icon: <TableChartIcon size={24}/>,
    },
    // {
    //     key: "datalist",
    //     to: "/datalist",
    //     name: "Data List",
    //     icon: <TableChartIcon size={24}/>,
    // }
]

const farmoRainGaugeRoutes = [
    {
        key: "rain",
        to: "/rain",
        name: "Farmo Rain Data",
        icon: <ThunderstormIcon size={24}/>,
    },
    {
        key: "raininstruments",
        to: "/raininstruments",
        name: "Farmo Rain Gauge",
        icon: <HandymanIcon size={24}/>,
    }
]

const esp32RainGaugeRoutes = [
    {
        key: "esp32rain",
        to: "/esp32rain",
        name: "ESP32 Rain Data",
        icon: <WaterIcon size={24}/>,
    },
    {
        key: "esp32raininstruments",
        to: "/esp32raininstruments",
        name: "ESP32 Rain Gauge",
        icon: <FaFlask size={24}/>,
    }
]

const kerlinkGatewayRoutes = [
    {
        key: "kerlinkreadings",
        to: "/kerlinkreadings",
        name: "Kerlink Gateway Reading",
        icon: <TbAlpha size={24}/>,
    },
    {
        key: "kerlinkdevices",
        to: "/kerlinkdevices",
        name: "Kerlink Device",
        icon: <EngineeringIcon size={24}/>,
    }
]

const vibrationMeterRoutes = [

]

const SidebarGlobalStyles = () => {
    const theme = useTheme();
    return (
        <GlobalStyles
            styles={{
                ".sidebar-nav-item": {
                    color: "unset",
                    textDecoration: "none",
                },
                ".sidebar-nav-item-active": {
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                    "& .MuiSvgIcon-root": {
                        color: theme.palette.primary.main,
                    },
                    "& .MuiTypography-root": {
                        fontWeight: 500,
                        color: theme.palette.primary.main,
                    },
                },
            }}
        />
    );
};
const SidebarGlobalStylesMemo = React.memo(SidebarGlobalStyles);


export function SideMenu(props) {
    const { mobileOpen, setMobileOpen } = props;
    

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    var listItems = [...commonRoutes];
    const is_manager = localStorage.getItem("is_manager")
    const has_noise_monitoring_access = localStorage.getItem("Noise Meter")
    const has_farmo_rain_gauge_access = localStorage.getItem("Farmo Rain Gauge")
    const has_esp32_rain_gauge_access = localStorage.getItem("ESP32 Rain Gauge")
    const has_kerlink_gateway_access = localStorage.getItem("Kerlink Gateway")
    const has_vibration_meter_access = localStorage.getItem("Vibration Meter")

    const is_manager_route = (is_manager === "true")
    const is_noise_monitoring_route = (is_manager === "true" || has_noise_monitoring_access === "true")
    const is_farmo_rain_gauge_route = (is_manager === "true" || has_farmo_rain_gauge_access === "true")
    const is_esp32_rain_gauge_route = (is_manager === "true" || has_esp32_rain_gauge_access === "true")
    const is_kerlink_gateway_route = (is_manager === "true" || has_kerlink_gateway_access === "true")
    const is_vibration_meter_route = (is_manager === "true" || has_vibration_meter_access === "true")

    listItems = is_manager_route ? [...managerRoutes, ...listItems] : listItems
    listItems = is_noise_monitoring_route ? [...noiseMonitoringRoutes, ...listItems] : listItems
    listItems = is_farmo_rain_gauge_route ? [...farmoRainGaugeRoutes, ...listItems] : listItems
    listItems = is_esp32_rain_gauge_route ? [...esp32RainGaugeRoutes, ...listItems] : listItems
    listItems = is_kerlink_gateway_route ? [...kerlinkGatewayRoutes, ...listItems] : listItems
    listItems = is_vibration_meter_route ? [...vibrationMeterRoutes, ...listItems] : listItems


    listItems = listItems.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      })

    const drawer = (
        <Box>
            <Toolbar />
            <Divider />
            <List>
                {[...listItems].map((li) => {
                    return (
                        <NavLink
                            end={li.to === "/" ? true : false}
                            className={(props) => {
                                return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                            }}
                            to={li.to}
                            key={li.key}
                        >
                            <ListItem button>
                                <ListItemIcon>{li.icon}</ListItemIcon>
                                <ListItemText primary={li.name} />
                            </ListItem>
                        </NavLink>
                    );
                })}
            </List>
        </Box>
    );

    return (
        <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 }}}
        >
            <SidebarGlobalStylesMemo />

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                style={{background: "grey"}}
                sx={{
                    display: { xs: "block", sm: "block", md: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                    backgroundColor: "grey"
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "none", md: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                    backgroundColor: "grey"
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

SideMenu.propTypes = {
    mobileOpen: PropTypes.bool,
    setMobileOpen: PropTypes.func.isRequired,
};

export default SideMenu;