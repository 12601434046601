import React, { useEffect, useState, useRef } from 'react'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Formik } from "formik";
import useRequestResource from '../../hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

const validationSchema = yup.object({
    username: yup.string().required("Username is required").max(150, "Max Length is 150!"),
    password: yup.string().required("Password is required").max(255, "Max Length is 255!"),
    email: yup.string().required("Email is required").max(255, "Max Length is 255!"),
    phone_number: yup.string().max(255, "Max Length is 255!"),
    first_name: yup.string().required("First Name is required").max(150, "Max Length is 150!"),
    last_name: yup.string().required("Last Name is required").max(150, "Max Length is 150!"),
    telegram_handler: yup.string().max(150, "Max Length is 150!")
})

const validationPasswordSchema = yup.object({
    new_password: yup.string().required("New password is required").max(150, "Max Length is 150!"),
    new_password_confirmation: yup.string().required("New password confirmation is required").max(150, "Max Length is 150!")
})


export default function UserEdit() {
    const { id } = useParams()
    const navigate = useNavigate()
    const devicePermissionsInfiniteScrollRef = useRef(null);
    const devicePermissionTextField = useRef(null); 
    const [ showDevicePermissionsInfiniteScroll, setDevicePermissionsInfiniteScroll ] = useState(false)
    const { getResource, resources, updateResource } = useRequestResource({ endpoint: "auth", query: "user", resourceLabel: "User" })
    const { getResourceList: getDevicePermissionList, resourceList: devicePermissionList, currentListSize: currentDevicePermissionListSize } = useRequestResource({ endpoint: "auth", query: "device_permissions", resourceLabel: "Device Permission List" });
    const { updateResource: updatePassword } = useRequestResource({ endpoint: "auth", query: "user/reset-password", resourceLabel: "Password" })
    const [is_active, setIsActive] = useState(false)
    const [is_manager, setIsManager] = useState(false)
    const [able_to_reset_password, setCanResetPassword] = useState(false)
    const [has_sms_enabled, setSmsEnable] = useState(false)
    const [has_email_enabled, setEmailEnable] = useState(false)
    const [has_telegram_enabled, setTelegramEnable] = useState(false)
    const [has_mobile_enabled, setMobileEnable] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [devicePermissions, setDevicePermissions] = useState([])
    let offset = 25;

    function handleSubmit(values) {

        const formattedValues = {
            username : values.username,
            password : values.password,
            email : values.email,
            phone_number: values.phone_number,
            first_name : values.first_name,
            last_name : values.last_name,
            telegram_handler : values.telegram_handler,
            is_manager: is_manager,
            able_to_reset_password: able_to_reset_password,
            is_superuser: values.is_superuser,
            is_active: is_active,
            is_staff: values.is_staff,
            has_sms_enabled : has_sms_enabled,
            has_email_enabled : has_email_enabled,
            has_telegram_enabled : has_telegram_enabled,
            has_push_enabled : has_mobile_enabled,
            device_permissions: devicePermissions.map(obj => obj.id)
        }
        updateResource(id, formattedValues, () => {
            window.location.reload()
        })
    }

    function handlePasswordChange(values) {
        if (values.new_password !== values.new_password_confirmation) {
            setPasswordError(true)
        } else {
            const formattedValues = {
                username: values.username,
                new_password: values.new_password
            }
            updatePassword(id, formattedValues, () => {
                window.location.reload()
            })
        }
    }

    const [initialValues, setInitialValues] = useState({
        username: "",
        password: "",
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        telegram_handler: "",
        is_superuser: false,
        is_staff: false
    });


    const [initialPasswordValues, setInitialPasswordValues] = useState({
        username: "",
        new_password: "",
        new_password_confirmation: ""
    });

    const fetchDevicePermission = () => {
        getDevicePermissionList(offset)
    }

    useEffect(() => {
        if (id) {
            getResource(id);
        }
        getDevicePermissionList();
    }, [id, getResource, getDevicePermissionList]);

    useEffect(() => {
        if (resources) {
            const data = resources
            setInitialValues({
                username: data.username === null ? "" : data.username,
                password: data.password === null ? "" : data.password,
                email: data.email === null ? "" : data.email,
                phone_number: data.phone_number === null ? "" : data.phone_number,
                first_name: data.first_name === null ? "" : data.first_name,
                last_name: data.last_name === null ? "" : data.last_name,
                telegram_handler: data.telegram_handler === null ? "" : data.telegram_handler,
                is_superuser: data.is_superuser === null ? false : data.is_superuser,
                is_staff: data.is_staff === null? false : data.is_staff
            })
            setInitialPasswordValues({
                username: data.username === null ? "" : data.username
            })
            setDevicePermissions(resources.device_permissions)
            setIsActive(data.is_active)
            setIsManager(data.is_manager)
            setCanResetPassword(data.able_to_reset_password)
            setSmsEnable(data.has_sms_enabled)
            setEmailEnable(data.has_email_enabled)
            setTelegramEnable(data.has_telegram_enabled)
            setMobileEnable(data.has_push_enabled)
        }
    }, [resources])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


    const handleOutsideClick = (event) => {
        if (devicePermissionsInfiniteScrollRef.current && !devicePermissionsInfiniteScrollRef.current.contains(event.target)) {
            if (devicePermissionTextField.current && devicePermissionTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
              setDevicePermissionsInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    function handleCheck(value, type) {
        switch (type) {
            case "Active": setIsActive(prev => !prev); break;
            case "Manager": setIsManager(prev => !prev); break;
            case "ResetPassword": setCanResetPassword(prev => !prev); break;
            case "Sms": setSmsEnable(prev => !prev); break;
            case "Telegram": setTelegramEnable(prev => !prev); break;
            case "Email": setEmailEnable(prev => !prev); break;
            case "Mobile": setMobileEnable(prev => !prev); break;
            default : 
        }
    }

    function addPermission(permission) {
        const unique = {}
        const results = []
        for (const p of devicePermissions) {
            const key = p.id
            unique[key] = true;
            results.push(p)

        }
        const key = permission.id
        if (!unique[key]) {
            results.push(permission)
        }
        setDevicePermissions(results)
    }

    function deletePermission(option) {
        const set = new Set([...devicePermissions])
        set.delete(option)
        setDevicePermissions([...set])
    }

    
    const device_permission_list = 
    (
        <Grid item xs={12}>
            {devicePermissions.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.device_type}
                            <IconButton size="small" onClick={() => {deletePermission(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <Card style={{textAlign: "start"}}>
            <Card>
                <Card.Header>
                    Update User Information
                </Card.Header>
                <Card.Body>
                    <Formik onSubmit={handleSubmit}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                    >
                        {
                            (formik) => {
                                return (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid xs={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="username"
                                                    label="Username"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('username')}
                                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                                    helperText={formik.touched.username && formik.errors.username}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="email"
                                                    label="Email"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('email')}
                                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                                    helperText={formik.touched.email && formik.errors.email}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="phone_number"
                                                    label="Phone Number"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('phone_number')}
                                                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                fullWidth
                                                id="telegram_handler"
                                                label="Telegram Handler"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                {...formik.getFieldProps('telegram_handler')}
                                                error={formik.touched.telegram_handler && Boolean(formik.errors.telegram_handler)}
                                                helperText={formik.touched.telegram_handler && formik.errors.telegram_handler}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="first_name"
                                                    label="First Name"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('first_name')}
                                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="last_name"
                                                    label="Last Name"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('last_name')}
                                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <div> Permissions </div>
                                            <div key={`inline-checkbox`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-3">
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Active')}
                                                    inline
                                                    checked={is_active}
                                                    label='Active'
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Active`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Manager')}
                                                    inline
                                                    checked={is_manager}
                                                    label='Manager'
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Manager`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'ResetPassword')}
                                                    inline
                                                    checked={able_to_reset_password}
                                                    label='Able to Reset Password'
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-CanResetPassword`}
                                                />
                                            </div>
                                            <div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="device_permissions"
                                                        label="Device Permissions"
                                                        value=""
                                                        ref={devicePermissionTextField}
                                                        onClick={() => setDevicePermissionsInfiniteScroll(true)}
                                                        error={formik.touched.device_permissions && Boolean(formik.errors.device_permissions)}
                                                        helperText={formik.touched.device_permissions && formik.errors.device_permissions}
                                                    >
                                                    </TextField>
                                                </Grid>
                                        
                                                { showDevicePermissionsInfiniteScroll ? <Grid item xs={12}>
                                                    <div ref={devicePermissionsInfiniteScrollRef}>
                                                        <InfiniteScroll
                                                            dataLength={devicePermissionList.results.length}
                                                            next={fetchDevicePermission}
                                                            hasMore={currentDevicePermissionListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}                                      
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>                                                      
                                                            }>

                                                                {devicePermissionList.results.map((option, index) => {
                                                                    return (
                                                                    <MenuItem key={index} value={option.id} onClick={
                                                                        () => {
                                                                            addPermission(option)
                                                                        }
                                                                    }>
                                                                        {option.device_type}
                                                                    </MenuItem>
                                                                    );}
                                                                )}

                                                        </InfiniteScroll>
                                                    </div>
                                                </Grid> : <div></div>}

                                                <Grid item xs={12}>
                                                    {device_permission_list}
                                                </Grid>
                                            </div>
                                            <div style={{ margin: '20px' }}></div>
                                            <div> Notifications </div>
                                            <div key={`inline-checkbox`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-3">
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Sms')}
                                                    inline
                                                    checked={has_sms_enabled}
                                                    label='SMS'
                                                    name="Notifications"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Sms`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Email')}
                                                    inline
                                                    checked={has_email_enabled}
                                                    label='Email'
                                                    name="Notifications"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Email`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Telegram')}
                                                    inline
                                                    checked={has_telegram_enabled}
                                                    label='Telegram'
                                                    name="Notifications"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Telegram`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Mobile')}
                                                    inline
                                                    checked={has_mobile_enabled}
                                                    label='Mobile'
                                                    name="Notifications"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Mobile`}
                                                />
                                            </div>
                                            <div style={{ margin: '10px' }}></div>
                                            <Grid item xs={12}>
                                                <Button
                                                    // onClick={ handleSubmit }
                                                    type='submit'
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Save User Information
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </Card.Body>
            </Card>
            <div style={{ margin: '20px' }}></div>
            <Card>
                <Card.Header>
                    Change Password
                </Card.Header>
                <Card.Body>
                    <Formik onSubmit={handlePasswordChange}
                        initialValues={initialPasswordValues}
                        enableReinitialize
                        validationSchema={validationPasswordSchema}
                    >
                        {
                            (formik) => {
                                return (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid xs={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="new_password"
                                                    label="New Password"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                                                    helperText={formik.touched.new_password && formik.errors.new_password}
                                                    {...formik.getFieldProps('new_password')}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="new_password_confirmation"
                                                    label="Confirm Password"
                                                    helperText="Confirm Password must match the Password Above"
                                                    error={passwordError}
                                                    onChange={(e) => {
                                                        if (e.target.value !== formik.values.new_password) {
                                                            setPasswordError(true)
                                                        } else {
                                                            setPasswordError(false)
                                                        }
                                                        formik.handleChange(e);
                                                    }}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Change password
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </form>
                                )
                            }
                        }

                    </Formik>
                </Card.Body>
            </Card>
        </Card>
    )
}
