import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';


const validationSchema = yup.object({
    client_name: yup.string().required("Client name is required").max(255, "Max Length is 255!"),
    consultant_name: yup.string().max(255, "Max Length is 255!"),
    project_title: yup.string().required("Project title is required").max(255, "Max Length is 255!"),
    location: yup.string().required("Location is required").max(255, "Max Length is 255!"),
    access_geologists: yup.array()
})

const EditServer = () => {
    const {id} = useParams()
    const { updateResource, getResource, resources }  = useRequestResource({ endpoint: "auth", query: "server", resourceLabel: "Server" });
    const navigate = useNavigate();

    let offset = 25;

    useEffect(() => {
        getResource(id)
    }, [getResource, id])

    useEffect(() => {
        if (resources) {
            setInitialValues({
                username: resources.username ? resources.username : null,
                hostname: resources.hostname ? resources.hostname : null,
                password: resources.password ? resources.password : null,
                pemfile: resources.pemfile ? resources.pemfile : null
            })
        }
    }, [resources])

    const [initialValues, setInitialValues] = useState({
        username: "",
        hostname: "",
        password: "",
        pemfile: "",
    });

    const handleSubmit = values => {
        const formattedValues = {
            username: values.username === "" ? null: values.username ,
            hostname: values.hostname === "" ? null: values.hostname,
            password: values.password === "" ? null: values.password,
            pemfile: values.pemfile === "" ? null: values.pemfile,
        }

        updateResource(id, formattedValues, () => {
            navigate(`/servers`)
        })
    }


    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Server Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="username"
                                                label="Username"
                                                {...formik.getFieldProps('username')}
                                                error={formik.touched.username && Boolean(formik.errors.username)}
                                                helperText={formik.touched.username && formik.errors.username}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="hostname"
                                                label="Hostname"
                                                {...formik.getFieldProps('hostname')}
                                                error={formik.touched.hostname && Boolean(formik.errors.hostname)}
                                                helperText={formik.touched.hostname && formik.errors.hostname}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="password"
                                                label="Password"
                                                {...formik.getFieldProps('password')}
                                                error={formik.touched.password && Boolean(formik.errors.password)}
                                                helperText={formik.touched.password && formik.errors.password}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="pemfile"
                                                label="Pem File"
                                                {...formik.getFieldProps('pemfile')}
                                                error={formik.touched.pemfile && Boolean(formik.errors.pemfile)}
                                                helperText={formik.touched.pemfile && formik.errors.pemfile}
                                            />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/servers`}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                sx = {{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                // type="submit"
                                                onClick={() => {handleSubmit(formik.values)}}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Edit Server
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}

export default EditServer;