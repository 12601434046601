import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';


const validationSchema = yup.object({
    project_name: yup.string().required("Project name is required").max(255, "Max Length is 255!"),
    client_name: yup.string().required("Client name is required").max(200, "Max Length is 200!"),
    location: yup.string().required("Location is required").max(255, "Max Length is 255!"),
    access_groups: yup.array(),
    access_users: yup.array()
})

const CreateProject = () => {
    const groupInfiniteScrollRef = useRef(null); 
    const userInfiniteScrollRef = useRef(null);
    const serverInfiniteScrollRef = useRef(null);
    const accessGroupTextField = useRef(null); 
    const accessUserTextField = useRef(null); 
    const serverTextField = useRef(null); 
    const [server, setServer] = useState(null)
    const { getResourceList: getAccessGroupList, resourceList: accessGroupList, currentListSize: currentAccessGroupListSize } = useRequestResource({ endpoint: "auth", query: "accessgroups", resourceLabel: "Access Group List" });
    const { getResourceList: getAccessUserList, resourceList: accessUserList, currentListSize: currentAccessUserListSize } = useRequestResource({ endpoint: "auth", query: "users", resourceLabel: "User List" });
    const { getResourceList: getServerList, resourceList: serverList, currentListSize: currentServerListSize } = useRequestResource({ endpoint: "auth", query: "servers", resourceLabel: "Server List" });
    const { addResource }  = useRequestResource({ endpoint: "auth", query: "project", resourceLabel: "Project" });
    const navigate = useNavigate();
    const [ showGroupInfiniteScroll, setGroupInfiniteScroll ] = useState(false)
    const [ showUserInfiniteScroll, setUserInfiniteScroll ] = useState(false)
    const [ showServerInfiniteScroll, setServerInfiniteScroll ] = useState(false)
    const [ accessGroups, setAccessGroups ] = useState([])
    const [ accessUsers, setAccessUsers ] = useState([])
    let offset = 25;

    const [initialValues, setInitialValues] = useState({
        project_name: "",
        client_name: "",
        project_reference_number: "",
        server: "",
        location: "",
        access_groups: [],
        access_users: []
    });

    const fetchGroupData = () => {
        getAccessGroupList(offset)
    }

    const fetchServerData = () => {
        getServerList(offset)
    }

    const fetchUserData = () => {
        getAccessUserList(offset)
    }

    useEffect(() => {
        getAccessGroupList();
        getAccessUserList();
        getServerList()
    }, [getAccessGroupList, getAccessUserList, getServerList])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (groupInfiniteScrollRef.current && !groupInfiniteScrollRef.current.contains(event.target)) {
            if (accessGroupTextField.current && accessGroupTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setGroupInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (userInfiniteScrollRef.current && !userInfiniteScrollRef.current.contains(event.target)) {
            if (accessUserTextField.current && accessUserTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setUserInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (serverInfiniteScrollRef.current && !serverInfiniteScrollRef.current.contains(event.target)) {
            if (serverTextField.current && serverTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setServerInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            project_name: values.project_name === "" ? null: values.project_name ,
            client_name: values.client_name === "" ? null: values.client_name,
            project_reference_number: values.project_reference_number === "" ? null: values.project_reference_number,
            server: values.server === "" ? null: values.server,
            location: values.location === "" ? null: values.location,
            access_groups: accessGroups.map(obj => obj.id),
            access_users: accessUsers.map(obj => obj.id)
        }

        addResource(formattedValues, () => {
            navigate(`/projects`)
        })
    }

    function addGroup(group) {
        const unique = {}
        const results = []
        for (const geo of accessGroups) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = group.id
        if (!unique[key]) {
            results.push(group)
        }
        setAccessGroups(results)
    }

    function deleteGroup(option) {
        const set = new Set([...accessGroups])
        set.delete(option)
        setAccessGroups([...set])
    }

    const access_groups_list = 
    (
        <Grid item xs={12}>
            {accessGroups.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.label}
                            <IconButton size="small" onClick={() => {deleteGroup(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    function addUser(user) {
        const unique = {}
        const results = []
        for (const geo of accessUsers) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = user.id
        if (!unique[key]) {
            results.push(user)
        }
        setAccessUsers(results)
    }

    function deleteUser(option) {
        const set = new Set([...accessUsers])
        set.delete(option)
        setAccessUsers([...set])
    }

    const access_users_list = 
    (
        <Grid item xs={12}>
            {accessUsers.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.first_name} {option.last_name}
                            <IconButton size="small" onClick={() => {deleteUser(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Project Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="project_name"
                                                label="Project Name"
                                                {...formik.getFieldProps('project_name')}
                                                error={formik.touched.project_name && Boolean(formik.errors.project_name)}
                                                helperText={formik.touched.project_name && formik.errors.project_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="client_name"
                                                label="Client Name"
                                                {...formik.getFieldProps('client_name')}
                                                error={formik.touched.client_name && Boolean(formik.errors.client_name)}
                                                helperText={formik.touched.client_name && formik.errors.client_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="project_reference_number"
                                                label="Reference Number"
                                                {...formik.getFieldProps('project_reference_number')}
                                                error={formik.touched.project_reference_number && Boolean(formik.errors.project_reference_number)}
                                                helperText={formik.touched.project_reference_number && formik.errors.project_reference_number}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="server"
                                                label={"Server"}
                                                {...formik.getFieldProps('server')}
                                                value={server}
                                                onChange={(e) => {formik.setFieldValue("server", "")}}
                                                ref={serverTextField}
                                                onClick={() => setServerInfiniteScroll(true)}
                                                error={(formik.touched.server) && Boolean(formik.errors.server)}
                                                helperText={(formik.touched.server) && formik.errors.server}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showServerInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={serverInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={serverList.results.length}
                                                    next={fetchServerData}
                                                    hasMore={currentServerListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {serverList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("server", option.id)
                                                                formik.setFieldTouched("server", false)
                                                                formik.setFieldError("server", "")
                                                                setServer(`${option.hostname}`)
                                                                setServerInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.hostname}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="location"
                                                label="Location"
                                                {...formik.getFieldProps('location')}
                                                error={formik.touched.location && Boolean(formik.errors.location)}
                                                helperText={formik.touched.location && formik.errors.location}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="access_groups"
                                                label="Access Groups"
                                                value=""
                                                ref={accessGroupTextField}
                                                onClick={() => setGroupInfiniteScroll(true)}
                                                error={formik.touched.access_groups && Boolean(formik.errors.access_groups)}
                                                helperText={formik.touched.access_groups && formik.errors.access_groups}
                                            >
                                            </TextField>
                                        </Grid>
                                        

                                        { showGroupInfiniteScroll ? <Grid item xs={12}>

                                        <div ref={groupInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={accessGroupList.results.length}
                                                    next={fetchGroupData}
                                                    hasMore={currentAccessGroupListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {accessGroupList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                addGroup(option)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                                </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            {access_groups_list}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="access_users"
                                                label="Access Users"
                                                value=""
                                                ref={accessUserTextField}
                                                onClick={() => setUserInfiniteScroll(true)}
                                                error={formik.touched.access_users && Boolean(formik.errors.access_users)}
                                                helperText={formik.touched.access_users && formik.errors.access_users}
                                            >
                                            </TextField>
                                        </Grid>
                                        

                                        { showUserInfiniteScroll ? <Grid item xs={12}>

                                        <div ref={userInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={accessUserList.results.length}
                                                    next={fetchUserData}
                                                    hasMore={currentAccessUserListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {accessUserList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                addUser(option)
                                                            }
                                                        }>
                                                            {option.first_name} {option.last_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                                </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            {access_users_list}
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/projects`}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                sx = {{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                // type="submit"
                                                onClick={() => {handleSubmit(formik.values)}}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Project
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}

export default CreateProject;