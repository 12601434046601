import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import useRequestAuth from '../../hooks/useRequestAuth';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { render } from '@testing-library/react';
import useRequestResource from '../../hooks/useRequestResource';
// import { Card } from 'reactstrap';



// Yup is a JavaScript schema builder for value parsing and validation
const validationSchema = yup.object({
    new_password: yup.string().required("New password is required").max(150, "Max Length is 150!"),
    new_password_confirmation: yup.string().required("New password confirmation is required").max(150, "Max Length is 150!")
})




const theme = createTheme();

export default function ResetPassword() {
    // Link the useRequestAuth function and values
    const { username, token, expiration } = useParams()
    const { updatePassword } = useRequestResource({ endpoint: "user", query: "change_password", resourceLabel: "Password" })
    const [passwordError, setPasswordError] = useState(false)

    function handlePasswordChange(values) {
        if (values.new_password !== values.new_password_confirmation) {
            setPasswordError(true)
        } else {
            const formattedValues = {
                username: values.username,
                new_password: values.new_password,
                token: token,
                expiration: expiration
            }
            updatePassword(1, formattedValues, () => {
                window.location = "/"
            })
        }
    }

    const [initialPasswordValues, setInitialPasswordValues] = useState({
        username: username,
        new_password: "",
        new_password_confirmation: "",
        token: token,
        expiration: expiration
    });

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <Formik onSubmit={handlePasswordChange}
                        initialValues={initialPasswordValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                    >
                        {
                            (formik) => {
                                return (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid xs={3} style={{alignContent: 'center'}}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="new_password"
                                                    label="New Password"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                                                    helperText={formik.touched.new_password && formik.errors.new_password}
                                                    {...formik.getFieldProps('new_password')}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="new_password_confirmation"
                                                    label="Confirm Password"
                                                    helperText="Confirm Password must match the Password Above"
                                                    error={passwordError}
                                                    onChange={(e) => {
                                                        if (e.target.value !== formik.values.new_password) {
                                                            setPasswordError(true)
                                                        } else {
                                                            setPasswordError(false)
                                                        }
                                                        formik.handleChange(e);
                                                    }}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Change password
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </form>
                                )
                            }
                        }

                    </Formik>
            </Box>
        </Container>
    );
}