import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuList, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from '../../hooks/useRequestResource';
import '../../style.css'      


export default function DjangoJobExecutionDetails() {
    const { id } = useParams();
    const { resources, getResource } = useRequestResource({ endpoint: "auth", query: "django_job_execution", resourceLabel: "Job Execution" });
    const navigate = useNavigate();
    const [ details, setDetails ] = useState([]);
    const [initialValues, setInitialValues] = useState({
        job: "",
        status: "",
        run_time: "",
        duration: "",
        finished: "",
        exception: ""
    });

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);
    

    useEffect(() => {

        if (resources) {
            setInitialValues({
                job: resources.job === null ? "" : resources.job,
                status : resources.status === null ? "" : resources.status,
                run_time : resources.run_time === null ? "" : resources.run_time,
                duration : resources.duration === null ? "" : resources.duration,
                finished : resources.finished === null ? "" : resources.finished,
                exception : resources.exception === null ? "" : resources.exception
            })
        }
    }, [resources])



    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Job Execution Log
                </Typography>

                <br />

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {
                        (formik) => {
                            return (

                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="job"
                                                label="Job"
                                                {...formik.getFieldProps('job')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>
  
                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="status"
                                                label="Status"
                                                {...formik.getFieldProps('status')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="run_time"
                                                label="Run Time"
                                                {...formik.getFieldProps('run_time')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="duration"
                                                label="Duration"
                                                {...formik.getFieldProps('duration')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="finished"
                                                label="Finished"
                                                {...formik.getFieldProps('finished')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>                                       

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="exception"
                                                label="Exception"
                                                {...formik.getFieldProps('exception')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>   

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/django_job_executions`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}