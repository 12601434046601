import React, { useEffect, useState } from 'react'
import { Grid, TextField, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from 'react-datepicker';
import useRequestResource from '../../hooks/useRequestResource';
import {Search, SearchIconWrapper, StyledInputBase} from '../../components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSortDown, FaSortUp} from "react-icons/fa";

export default function DjangoJobExecution() {
    const { getResourceList, resourceList, searchResources, searchList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth", query: "django_job_executions", resourceLabel: "Job Execution List" });
    const { deleteResourcesBefore } = useRequestResource({ endpoint: "auth", query: "django_job_executions", resourceLabel: "Job Execution"})
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const offset = 25
    const [exp, setExp] = useState("")
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const [sorting_query, setSortingQuery] = useState(null)

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    function onRowClick(e) {
        navigate(`/${e.id}/django_job_execution`)
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }
    
    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    // On component mount or change in sorting_query
    useEffect(() => {
        if (exp !== "") {
            searchResources(exp, sorting_query)
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])


    const fetchData = () => {
        if (exp == "") {
            getResourceList(offset, sorting_query)
        } else {
            searchResources(exp, sorting_query, offset)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchResources(exp, sorting_query);
        }
    };

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    const handleDelete = () => {
        setOpen(false);
        onDeleteByDateSubmit(deleteByDate)
    }
    const handleConfirmDelete = () => {
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>

            <div style={{ height: "20px" }} />

            <TableContainer component={Paper}>
                <h3 style={headerStyle}>
                    Django Job Execution
                </h3>

                <Grid item xs={12} style={{ display: 'flex' }}>

                    <div style={{ display: 'flex'}}>
                        <DatePicker
                                placeholderText='Delete Before'
                                selected={ deleteByDate }
                                onChange={ handleDeleteByDateChange }
                                name="startDate"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={20}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                autoComplete='off'
                            >    
                        </DatePicker>
                        <Button 
                                onClick={ handleConfirmDelete }
                                size="small"
                                variant="contained"
                            >
                                Delete
                        </Button>
                    </div>
                </Grid>
        
                <div style={{ height: "20px" }} />

                <Grid item xs={12} >
                    <Search fullWidth>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            fullWidth
                            id="log"
                            label="Log"
                            value={exp}
                            onChange={(e) => setExp(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Search>
                </Grid>

                <div style={{ height: "20px" }} />
                
                <Table sx={{ minWidth: 360 }} size="small">
                    <TableHead width="100%">
                        <TableRow>
                            
                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("id")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>ID</div><div>{sorting_query === "id" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-id")}></FaSortUp> : 
                                    sorting_query === "-id" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("id")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="25%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("job")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Job</div><div>{sorting_query === "job" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-job")}></FaSortUp> : 
                                    sorting_query === "-job" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("job")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("status")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Status</div><div>{sorting_query === "status" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-status")}></FaSortUp> : 
                                    sorting_query === "-status" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("status")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("run_time")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Run Time</div><div>{sorting_query === "run_time" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-run_time")}></FaSortUp> : 
                                    sorting_query === "-run_time" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("run_time")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>


                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("duration")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Duration</div><div>{sorting_query === "duration" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-duration")}></FaSortUp> : 
                                    sorting_query === "-duration" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("duration")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {
                            getList().map((r) => {
                                return (
                                    <TableRow
                                        // style={r.status === "C" ? completeBorderStyle : incompleteBorderStyle}
                                        key={r.id} onClick={() => onRowClick(r)}
                                    >
                                        <TableCell align="center" width="15%">
                                            {r.id}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                            {r.job}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                            {r.status}
                                        </TableCell>
                                        <TableCell align="center" width="30%">
                                            {new Date(r.run_time).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="center" width="40%">
                                            {r.duration}
                                        </TableCell>

                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                <Button onClick={fetchData}>
                            LOAD MORE!
                </Button>
            </div>}
        </div >
    )
}
