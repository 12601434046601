import React, { useEffect, useState, useRef} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Dialog, DialogTitle, DialogActions, IconButton, Box, Button, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"; 
import useRequestResource from '../../hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import InfiniteScroll from 'react-infinite-scroll-component';
import * as yup from 'yup';
import DeleteIcon from "@mui/icons-material/Delete"
import DatePicker from 'react-datepicker';
import {Search, SearchIconWrapper, StyledInputBase} from '../../components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }


export default function Import() {
    const { resourceList: importList, getResourceList: getImportList, currentListSize: currentImportListSize, searchResources: searchImport, searchList: searchImportList} = useRequestResource({ endpoint: "auth", query: "import_files", resourceLabel: "Import List" });
    const { addResource, getResource, resources, deleteResourcesBefore, deleteResource } = useRequestResource({endpoint: "auth", query: "import_file", resourceLabel: "Import"})
   

    const offset = 25;
    const [exp, setExp] = useState("")
    const [files, setFiles] = useState([])

    const [sorting_query, setSortingQuery] = useState(null)

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);


    useEffect(() => {
        getImportList();
    }, [getImportList]);


    const fetchData = () => {
        getImportList(offset, sorting_query)
    }


    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getImportList(0, sorting_query)
        }
    }, [sorting_query, getImportList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    const handleSubmit = () => {

        let formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        formData.append("json_data", null)

        addResource(formData, () => {
            window.location.reload();
        })
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    const searchData = () => {
        searchImport(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchImportList.results
        } else {
            return importList.results
        }
    }

    function onFilesChange(e) {
        setFiles([...e.target.files])
    }

    return (
        <div>
           <h3 style={headerStyle}>
                    Import
                </h3>

                <Dialog open={open} onClose={handleDeleteClose}>
                    <DialogTitle>
                        Are you sure you want to delete this??
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDelete}>
                            YES
                        </Button>
                        <Button onClick={handleDeleteClose}>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">Import Files</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Formik>
                                    {
                                        (formik) => {
                                            return (
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <input type="file" multiple accept="file/*" onChange={onFilesChange}/>
                                                    </Grid>                                                
                                            
                                        
                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={handleSubmit}
                                                            type='submit'
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Import
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    }
                                </Formik>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header style={{ display: 'flex' }}>
                            <CustomToggle eventKey="1">Import List</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex'}}>
                                        <DatePicker
                                                placeholderText='Delete Before'
                                                selected={ deleteByDate }
                                                onChange={ handleDeleteByDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                            >    
                                        </DatePicker>
                                        <Button 
                                                onClick={ onDeleteByDateSubmit }
                                                size="small"
                                                variant="contained"
                                            >
                                                Delete
                                        </Button>
                                    </div>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="import"
                                            label="Import"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Table>
                                    <TableHead width="100%">
                                        <TableRow>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("request_timestamp")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Date of Request</div><div>{sorting_query === "request_timestamp" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-request_timestamp")}></FaSortUp> : 
                                                    sorting_query === "-request_timestamp" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("request_timestamp")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("completed_timestamp")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Date of Completion</div><div>{sorting_query === "completed_timestamp" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-completed_timestamp")}></FaSortUp> : 
                                                    sorting_query === "-completed_timestamp" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("completed_timestamp")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center">
                                                    Status
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("upload")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Upload</div><div>{sorting_query === "upload" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-upload")}></FaSortUp> : 
                                                    sorting_query === "-upload" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("upload")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="right">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        getList().map((r) => {
                                            return (
                                                <TableRow key={r.id}>
                                                    <TableCell align="center">
                                                        {r.request_timestamp !== null ? new Date(r.request_timestamp).toLocaleString() : ""}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {r.completed_timestamp !== null ? new Date(r.completed_timestamp).toLocaleString() : ""}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {r.status}    
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <a href={`${process.env.REACT_APP_API_URL}${r.upload}`}>
                                                            {`${process.env.REACT_APP_API_URL}${r.upload}`}
                                                        </a> 
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }} >

                                                            <IconButton size="small" onClick={() => {
                                                                handleConfirmDelete(r.id)
                                                            }}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                                </Table>
                                {exp !== "" || currentImportListSize.current < offset ? <div></div> : <div className='centered-container'>
                                    <Button onClick={fetchData}>
                                                LOAD MORE!
                                    </Button>
                                </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </div>
    )
}


