import React, { useEffect, useState, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from '@mui/icons-material/Clear';
import useRequestResource from '../../hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import PreviewIcon from '@mui/icons-material/Preview';
import * as yup from 'yup';
import { Formik } from "formik";
import {Search, SearchIconWrapper, StyledInputBase} from '../../components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";
import InfiniteScroll from 'react-infinite-scroll-component';

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

const validationSchema = yup.object({
    username: yup.string().required("Username is required").max(150, "Max Length is 150!"),
    password: yup.string().required("Password is required").max(255, "Max Length is 255!"),
    email: yup.string().required("Email is required").max(255, "Max Length is 255!"),
    phone_number: yup.string().max(255, "Max Length is 255!"),
    first_name: yup.string().required("First Name is required").max(150, "Max Length is 150!"),
    last_name: yup.string().required("Last Name is required").max(150, "Max Length is 150!"),
    telegram_handler: yup.string().max(150, "Max Length is 150!")
})

export default function AdminPanel() {
    const { getResourceList, resourceList, searchResources, searchList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth", query: "users", resourceLabel: "User List" });
    const { addResource, getResource, resources} = useRequestResource({endpoint: "auth", query: "user", resourceLabel: "User"})
    const { getResourceList: getDevicePermissionList, resourceList: devicePermissionList, currentListSize: currentDevicePermissionListSize } = useRequestResource({ endpoint: "auth", query: "device_permissions", resourceLabel: "Device Permission List" });
    const offset = 25;
    const [exp, setExp] = useState("")
    const [initialValues, setInitialValues] = useState({
        username : "",
        password : "",
        email : "",
        phone_number: "",
        first_name : "",
        last_name : "",
        telegram_handler: "",
    });

    const [is_active, setIsActive] = useState(false)
    const [is_manager, setIsManager] = useState(false)
    const [able_to_reset_password, setCanResetPassword] = useState(false)
    const [has_sms_enabled, setSmsEnable] = useState(false)
    const [has_email_enabled, setEmailEnable] = useState(false)
    const [has_telegram_enabled, setTelegramEnable] = useState(false)
    const [has_mobile_enabled, setMobileEnable] = useState(false)
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const [passwordError, setPasswordError] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [sorting_query, setSortingQuery] = useState(null)
    const devicePermissionsInfiniteScrollRef = useRef(null);
    const devicePermissionTextField = useRef(null); 
    const [showDevicePermissionsInfiniteScroll, setDevicePermissionsInfiniteScroll] = useState(false)
    const [devicePermissions, setDevicePermissions] = useState([])

    useEffect(() => {
        getResourceList();
        getDevicePermissionList();
    }, [getResourceList, getDevicePermissionList]);

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    function handleSubmit(values) {

        if (values.password !== confirmPassword) {
            setPasswordError(true)
        } else {
            const formattedValues = {
                username : values.username,
                password : values.password,
                email : values.email,
                phone_number: values.phone_number,
                first_name : values.first_name,
                last_name : values.last_name,
                telegram_handler : values.telegram_handler,
                is_manager: is_manager,
                able_to_reset_password: able_to_reset_password,
                is_superuser: false,
                is_active: is_active,
                is_staff: false,
                has_sms_enabled : has_sms_enabled,
                has_email_enabled : has_email_enabled,
                has_telegram_enabled : has_telegram_enabled,
                has_push_enabled: has_mobile_enabled,
                device_permissions: devicePermissions.map(obj => obj.id)
            }
            addResource(formattedValues, () => {
                window.location.reload();
            })
        }
    }

    function handleCheck(value, type) {
        switch(type) {
            case "Active": setIsActive(prev => !prev); break;
            case "Manager": setIsManager(prev => !prev); break;
            case "Able to Reset Password": setCanResetPassword(prev => !prev); break;
            case "Sms": setSmsEnable(prev => !prev); break;
            case "Telegram": setTelegramEnable(prev => !prev); break;
            case "Email": setEmailEnable(prev => !prev); break;
            case "Mobile": setMobileEnable(prev => !prev); break;
            default : 
        }
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    const fetchDevicePermission = () => {
        getDevicePermissionList(offset)
    }

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount

      const handleOutsideClick = (event) => {
        if (devicePermissionsInfiniteScrollRef.current && !devicePermissionsInfiniteScrollRef.current.contains(event.target)) {
            if (devicePermissionTextField.current && devicePermissionTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
              setDevicePermissionsInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

      function addPermission(permission) {
        const unique = {}
        const results = []
        for (const p of devicePermissions) {
            const key = p.id
            unique[key] = true;
            results.push(p)

        }
        const key = permission.id
        if (!unique[key]) {
            results.push(permission)
        }
        setDevicePermissions(results)
    }

    function deletePermission(option) {
        const set = new Set([...devicePermissions])
        set.delete(option)
        setDevicePermissions([...set])
    }

    
    const device_permission_list = 
    (
        <Grid item xs={12}>
            {devicePermissions.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.device_type}
                            <IconButton size="small" onClick={() => {deletePermission(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <div style={{textAlign: "start"}}>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
            <h3 style={headerStyle}>
                Users
            </h3>
            <Accordion defaultActiveKey="1">
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">Create new User</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid xs={3}>
                                                    <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="username"
                                                        label="Username"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        {...formik.getFieldProps('username')}
                                                        error={formik.touched.username && Boolean(formik.errors.username)}
                                                        helperText={formik.touched.username && formik.errors.username}
                                                    />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="email"
                                                            label="Email"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('email')}
                                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                                            helperText={formik.touched.email && formik.errors.email}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="phone_number"
                                                            label="Phone Number"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('phone_number')}
                                                            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="telegram_handler"
                                                            label="Telegram Handler"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('telegram_handler')}
                                                            error={formik.touched.telegram_handler && Boolean(formik.errors.telegram_handler)}
                                                            helperText={formik.touched.telegram_handler && formik.errors.telegram_handler}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="password"
                                                            label="Password"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('password')}
                                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                                            helperText={formik.touched.password && formik.errors.password}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="confirm-password"
                                                            label="Confirm Password"
                                                            helperText="Confirm Password must match the Password Above"
                                                            error={passwordError}
                                                            onChange={(e) => {
                                                                if (e.target.value !== formik.values.password) {
                                                                    setPasswordError(true)
                                                                } else {
                                                                    setPasswordError(false)
                                                                }
                                                                setConfirmPassword(e.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="first_name"
                                                            label="First Name"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('first_name')}
                                                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                            helperText={formik.touched.first_name && formik.errors.first_name} 
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="last_name"
                                                            label="Last Name"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('last_name')}
                                                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                            helperText={formik.touched.last_name && formik.errors.last_name}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <div> Permissions </div>
                                                    <div key={`inline-checkbox`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-3">
                                                        {['Active', 'Manager', 'Able to Reset Password'].map((type) => (
                                                            <Form.Check
                                                                style={{ margin: '20px' }}
                                                                onChange={(value) => handleCheck(value, type)}
                                                                inline
                                                                label={`${type}`}
                                                                name="Permissions"
                                                                type='checkbox'
                                                                id={`inline-checkbox-${type}`}
                                                            />
                                                        ))}
                                                        </div>
                                                        <div>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                autoComplete='off'
                                                                id="device_permissions"
                                                                label="Device Permissions"
                                                                value=""
                                                                ref={devicePermissionTextField}
                                                                onClick={() => setDevicePermissionsInfiniteScroll(true)}
                                                                error={formik.touched.device_permissions && Boolean(formik.errors.device_permissions)}
                                                                helperText={formik.touched.device_permissions && formik.errors.device_permissions}
                                                            >
                                                            </TextField>
                                                        </Grid>

                                            
                                                        { showDevicePermissionsInfiniteScroll ? <Grid item xs={12}>
                                                            <div ref={devicePermissionsInfiniteScrollRef}>
                                                                <InfiniteScroll
                                                                    dataLength={devicePermissionList.results.length}
                                                                    next={fetchDevicePermission}
                                                                    hasMore={currentDevicePermissionListSize.current >= offset}
                                                                    loader={<h4>Loading...</h4>}
                                                                    height={400}                                      
                                                                    endMessage={
                                                                        <p style={{ textAlign: "center" }}>
                                                                        <b>Yay! You have seen it all</b>
                                                                        </p>                                                      
                                                                    }>

                                                                        {devicePermissionList.results.map((option, index) => {
                                                                            return (
                                                                            <MenuItem key={index} value={option.id} onClick={
                                                                                () => {
                                                                                    addPermission(option)
                                                                                }
                                                                            }>
                                                                                {option.device_type}
                                                                            </MenuItem>
                                                                            );}
                                                                        )}

                                                                </InfiniteScroll>
                                                            </div>
                                                        </Grid> : <div></div>}

                                                        <Grid item xs={12}>
                                                            {device_permission_list}
                                                        </Grid>
                                                    </div>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <div> Notifications </div>
                                                    <div key={`inline-checkbox`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-3">
                                                    <Form.Check
                                                        style={{ margin: '20px' }}
                                                        onChange={(value) => handleCheck(value, 'Sms')}
                                                        inline
                                                        checked={has_sms_enabled}
                                                        label='SMS'
                                                        name="Notifications"
                                                        type='checkbox'
                                                        id={`inline-checkbox-Sms`}
                                                    /> 
                                                    <Form.Check
                                                        style={{ margin: '20px' }}
                                                        onChange={(value) => handleCheck(value, 'Email')}
                                                        inline
                                                        checked={has_email_enabled}
                                                        label='Email'
                                                        name="Notifications"
                                                        type='checkbox'
                                                        id={`inline-checkbox-Email`}
                                                    />
                                                    <Form.Check
                                                        style={{ margin: '20px' }}
                                                        onChange={(value) => handleCheck(value, 'Telegram')}
                                                        inline
                                                        checked={has_telegram_enabled}
                                                        label='Telegram'
                                                        name="Notifications"
                                                        type='checkbox'
                                                        id={`inline-checkbox-Telegram`}
                                                    />
                                                    <Form.Check
                                                        style={{ margin: '20px' }}
                                                        onChange={(value) => handleCheck(value, 'Mobile')}
                                                        inline
                                                        checked={has_mobile_enabled}
                                                        label='Mobile'
                                                        name="Notifications"
                                                        type='checkbox'
                                                        id={`inline-checkbox-Mobile`}
                                                    />
                                                    </div>
                                                    <div style={{ margin: '10px' }}></div>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            // onClick={ handleSubmit }
                                                            type='submit'
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Add User
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="1">User List</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Grid item xs={12} >
                                <Search fullWidth>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                        fullWidth
                                        id="user"
                                        label="User"
                                        value={exp}
                                        onChange={(e) => setExp(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </Search>
                            </Grid>
                            <Table>
                                <TableHead width="100%">
                                    <TableRow>
                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("username")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Username</div><div>{sorting_query === "username" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-username")}></FaSortUp> : 
                                                sorting_query === "-username" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("username")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("email")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Email</div><div>{sorting_query === "email" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-email")}></FaSortUp> : 
                                                sorting_query === "-email" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("email")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("phone_number")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Phone Number</div><div>{sorting_query === "phone_number" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-phone_number")}></FaSortUp> : 
                                                sorting_query === "-phone_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("phone_number")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("telegram_handler")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Telegram</div><div>{sorting_query === "telegram_handler" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-telegram_handler")}></FaSortUp> : 
                                                sorting_query === "-telegram_handler" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("telegram_handler")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("first_name")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>First Name</div><div>{sorting_query === "first_name" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-first_name")}></FaSortUp> : 
                                                sorting_query === "-first_name" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("first_name")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("last_name")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Last Name</div><div>{sorting_query === "last_name" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-last_name")}></FaSortUp> : 
                                                sorting_query === "-last_name" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("last_name")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("is_active")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Active</div><div>{sorting_query === "is_active" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-is_active")}></FaSortUp> : 
                                                sorting_query === "-is_active" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("is_active")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("is_manager")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Manager Status</div><div>{sorting_query === "is_manager" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-is_manager")}></FaSortUp> : 
                                                sorting_query === "-is_manager" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("is_manager")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center">
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    getList().map((r) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="center">
                                                    {r.username}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.email}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.phone_number}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.telegram_handler}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.first_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.last_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Form>
                                                    <div key={`default-checkbox`} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            disabled
                                                            checked={r.is_active}
                                                            type='checkbox'
                                                            id={`default-checkbox`}
                                                        />
                                                        </div>
                                                    </Form>
                                                </TableCell>

                                                <TableCell align="center">
                                                <Form>
                                                    <div key={`default-checkbox`} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            disabled
                                                            checked={r.is_manager}
                                                            type='checkbox'
                                                            id={`default-checkbox`}
                                                        />
                                                        </div>
                                                    </Form>
                                                </TableCell>
                                    
                            
                                                <TableCell>
                                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Link 
                                                        to={`/${r.id}/editUser`} 
                                                        key="User-Details"
                                                    >
                                                        <IconButton size="small">
                                                            <PreviewIcon />
                                                        </IconButton>
                                                    </Link>
                                                    <IconButton size="small" onClick={() => {
                                                    handleConfirmDelete(r.id)
                                                }}>
                                                    <DeleteIcon />
                                                    </IconButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            </Table>
                            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}


