import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid} from "@mui/material";


export const TableB = ({leq1, leq5, leq12, doseAndMaxList, date}) => {

    let leq1count = 0
    let leq5count = 0
    const generateTime = (index) => {
        if (index >= 0 && index <= 16) {
          return { time: `${index + 7}`, rowSpan: 17, date: '-' };
        } else {
          const hour = index - 17;
          return { time: `${hour}`, rowSpan: 7, date: '-' };
        }
    };

    let currentDate = new Date(date)
    currentDate.setDate(currentDate.getDate() + 1)
    let tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1)

    // Format the tomorrow variable back to the desired date string format "YYYY-MM-DD"
    let formattedTomorrow = tomorrow.toLocaleDateString("en-GB")
    let formattedToday = currentDate.toLocaleDateString("en-GB")

    const baseFontSize = 13; // Define your base font size (in pixels)
    const minHeight = 650;   // The minimum window height to base the calculation on

    // Get the current window height
    const windowHeight = window.innerHeight;

    // Calculate the adjusted font size based on the window height
    const adjustedFontSize = baseFontSize * (windowHeight / minHeight);
    
    //Generate output for the noise dosage column
    const getDoseAndMax5ContentForTableB = (index, doseAndMaxList, leq1, isNoiseDose) => {
      // First data and non "B - Residential" noise dose and LEQ 5 max is always 12h
      if (index >= 1 && index <= 11) {
        return ""
      }

      let displayValue = "-";
      if (doseAndMaxList[index]) {
        const data = doseAndMaxList[index];
        const value = isNoiseDose ? data.noise_dose_percentage : data.leq5_max;
        const roundingDP = isNoiseDose ? 2 : 1;
        const currentTime = new Date();

        //Index 0 denote a 12hr value.
        if (isEOD(currentTime, data.timestamp)) {
          displayValue = "EOD";
        } else {
          displayValue = value === null 
          ? "-" 
          : (value === "-1.00" && !isNoiseDose) || (isNoiseDose && value === "101.00")
          ? "OL"
          : parseFloat(value).toFixed(roundingDP);
        } 

        //Handle cases where there is data mismatch
        if (index >= 12 && data.type_of_leq !== "1h" || index === 0 && data.type_of_leq !== "12h") {
          displayValue = "-";
        }
      }

      if (index === 0 || doseAndMaxList.length === 2) {
        return <td rowSpan={12}><br/><br/>{displayValue}</td>;
      } else {
        return <td>{displayValue}</td>;
      }
    };


    const adjustTime = (datetime) => {
      const currentDateTime = datetime;
      
      const hours = currentDateTime.getHours();
    
      // Handle time rounding and setting to 7AM
      if (hours >= 7 && hours < 19) {
        const startOf7AM = new Date(currentDateTime);
        startOf7AM.setHours(7, 0, 0, 0);
        return startOf7AM;
      } else {
        //Handle time rounding between 12am-7am and 7pm-12am
        const currentDateTimeCopy = new Date(currentDateTime);
        return new Date(currentDateTimeCopy.setHours(currentDateTimeCopy.getHours(), 0, 0, 0));
      }
    };

    const isEOD = (referenceTime, dataTime) => {
      const timeToNearestPeriod = adjustTime(referenceTime);
      return timeToNearestPeriod > new Date(dataTime);
    };

    return (
        <div style={{marginBottom: "10px",  minWidth: "100%", maxWidth: "100%"}}>

          <Grid container spacing={3} style={{ minWidth: "100%", maxWidth: "100%"}}> 
          <Grid item xs={8} style={{ minWidth: "100%", maxWidth: "100%"}}>
          <Accordion defaultExpanded={true} style={{backgroundColor: "#64748B",  minWidth: "100%", maxWidth: "100%"}}>
            <AccordionSummary style={{minWidth: "100%", maxWidth: "100%"}}
              expandIcon={< ExpandMoreIcon/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              >
              <Typography color="white">{currentDate.toLocaleDateString("en-GB")}</Typography>
            </AccordionSummary>
        <AccordionDetails style={{ minWidth: "100%", maxWidth: "100%"}}> 
          <Typography style={{ minWidth: "100%", maxWidth: "100%"}}>
          <table className="excel-table" style={{backgroundColor: "white", fontSize: `${adjustedFontSize}px`, minWidth: "100%", maxWidth: "100%"}}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time (hr/5min)</th>
                  <th>00</th>
                  <th>05</th>
                  <th>10</th>
                  <th>15</th>
                  <th>20</th>
                  <th>25</th>
                  <th>30</th>
                  <th>35</th>
                  <th>40</th>
                  <th>45</th>
                  <th>50</th>
                  <th>55</th>
                  <th>Leq1hr/Accum</th>
                  <th>Leq12hr/Accum</th>
                  <th>Current Dose %</th>
                  <th>Max. Allow / Leq5min</th>
                </tr>
              </thead>
              <tbody>
                {[...Array(24)].map((_, index) => {
                    const { time, rowSpan } = generateTime(index);
                  return (
                    <tr>
                        { index === 0 ? <td rowSpan={rowSpan}>{formattedToday}</td> : index === 17 ? <td rowSpan={rowSpan}>{formattedTomorrow}</td> : ""}
                        <td>{time}:00</td>
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 0 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 5 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 10 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 15 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 20 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 25 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 30 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 35 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 40 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 45 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 50 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq5[leq5count] && new Date(leq5[leq5count].timestamp).getHours() == time && new Date(leq5[leq5count].timestamp).getMinutes() == 55 ? <td style={{ backgroundColor: leq5[leq5count].has_exceeded_threshold ? 'red' : '' }}>
                        { leq5[leq5count++].leq_value}
                        </td> : <td>-</td>}
                        {leq1[leq1count] && new Date(leq1[leq1count].timestamp).getHours() == time ? <td>
                        { leq1[leq1count++].leq_value}
                        </td> : <td>-</td>}
                        {(index === 0 ) && time < 19 ? <td style={{ backgroundColor: leq12[0] && leq12[0].has_exceeded_threshold ? 'red' : '' }} rowSpan={12}><br/><br/>{leq12[0] ? leq12[0].leq_value : "-"}</td> : (index === 12) && time >= 19 ? <td style={{ backgroundColor: leq12[1] && leq12[1].has_exceeded_threshold ? 'red' : '' }} rowSpan={12}><br/><br/>{leq12[1] ? leq12[1].leq_value : "-"}</td> : ""}
                        {getDoseAndMax5ContentForTableB(index, doseAndMaxList, leq1, true)}
                        {getDoseAndMax5ContentForTableB(index, doseAndMaxList, leq1, false)}
                  </tr>
                  )
                })}
              </tbody>
            </table>
          </Typography>
        </AccordionDetails>
          </Accordion>
          </Grid>
          </Grid>
        </div>
    )
}