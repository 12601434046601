import React, { useEffect, useState } from 'react'
import { Grid, TextField, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import useRequestResource from '../../hooks/useRequestResource';
import {Search, SearchIconWrapper, StyledInputBase} from '../../components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSortDown, FaSortUp} from "react-icons/fa";
import DeleteIcon from "@mui/icons-material/Delete";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PreviewIcon from '@mui/icons-material/Preview';

export default function NotificationMessages() {
    const { getResourceList, resourceList, searchResources, searchList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth", query: "notifications", resourceLabel: "Notifications" });
    const { deleteResourcesBefore } = useRequestResource({ endpoint: "auth", query: "notifications", resourceLabel: "Notifications"})
    const { resendMultipleNotifications } = useRequestResource({ endpoint: "auth", query: "notifications/resend_selected", resourceLabel: "Notifications"})
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [idToResend, setIdToResend] = useState([]);
    const offset = 25
    const [exp, setExp] = useState("")
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const [sorting_query, setSortingQuery] = useState(null)

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    function onRowClick(e) {
        navigate(`/${e.id}/notification`)
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }
    
    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    function resendNotifications() {
        resendMultipleNotifications({"notifications": idToResend}, () => {
            window.location.reload()
        }) 
    }

    // On component mount or change in sorting_query
    useEffect(() => {
        if (exp !== "") {
            searchResources(exp, sorting_query)
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])


    const fetchData = () => {
        if (exp == "") {
            getResourceList(offset, sorting_query)
        } else {
            searchResources(exp, sorting_query, offset)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchResources(exp, sorting_query);
        }
    };

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    const handleDelete = () => {
        setOpen(false);
        onDeleteByDateSubmit(deleteByDate)
    }
    const handleConfirmDelete = () => {
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>

            <div style={{ height: "20px" }} />

            <TableContainer component={Paper}>
                <h3 style={headerStyle}>
                    Notifications
                </h3>

                {idToResend.length > 0 ? 
                <Grid item xs={12} >
                    <div style={{ height: "10px" }} />
                    <Button 
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={resendNotifications}
                        >
                            <ForwardToInboxIcon/>
                            Resend Notifications
                    </Button>
                    <div style={{ height: "10px" }} />
                </Grid>
                 : <div></div>} 

                <Grid item xs={12} style={{ display: 'flex' }}>

                    <div style={{ display: 'flex'}}>
                        <DatePicker
                                placeholderText='Delete Before'
                                selected={ deleteByDate }
                                onChange={ handleDeleteByDateChange }
                                name="startDate"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={20}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                autoComplete='off'
                            >    
                        </DatePicker>
                        <Button 
                                onClick={ handleConfirmDelete }
                                size="small"
                                variant="contained"
                            >
                                Delete
                        </Button>
                    </div>
                </Grid>
        
                <div style={{ height: "20px" }} />

                <Grid item xs={12} >
                    <Search fullWidth>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            fullWidth
                            id="log"
                            label="Log"
                            value={exp}
                            onChange={(e) => setExp(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Search>
                </Grid>

                <div style={{ height: "20px" }} />
                
                <Table sx={{ minWidth: 360 }} size="small">
                    <TableHead width="100%">
                        <TableRow>
                            <TableCell align="center" width="5%">
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("user__username")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>User</div><div>{sorting_query === "user__username" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-user__username")}></FaSortUp> : 
                                    sorting_query === "-user__username" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("user__username")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("timestamp")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Time</div><div>{sorting_query === "timestamp" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-timestamp")}></FaSortUp> : 
                                    sorting_query === "-timestamp" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("timestamp")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="35%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("message_type")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Type</div><div>{sorting_query === "message_type" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-message_type")}></FaSortUp> : 
                                    sorting_query === "-message_type" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("message_type")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="10%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("status")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Status</div><div>{sorting_query === "status" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-status")}></FaSortUp> : 
                                    sorting_query === "-status" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("status")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("notification_channel")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Notification Channel</div><div>{sorting_query === "notification_channel" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-notification_channel")}></FaSortUp> : 
                                    sorting_query === "-notification_channel" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("notification_channel")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {
                            getList().map((r) => {
                                return (
                                    <TableRow>
                                        <TableCell align="center" width="5%">
                                            <Form.Check type="checkbox" onChange={(e) => {
                                                if (e.target.checked === true) {
                                                    setIdToResend((prev) => [...prev, r.id])
                                                } else {
                                                    setIdToResend((prev) => {
                                                        let index = prev.indexOf(r.id)
                                                        if (index != null) {
                                                            prev.splice(index, 1)
                                                        }
                                                        return prev
                                                    })
                                                }
                                            }}/>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            {r.username}
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            {new Date(r.timestamp).toLocaleString()}
                                        </TableCell>

                                        <TableCell align="center" width="35%">
                                            {r.message_type}
                                        </TableCell>

                                        <TableCell align="center" width="10%">
                                            {r.status}
                                        </TableCell>
                                        
                                        <TableCell align="center" width="15%">
                                            {r.notification_channel}
                                        </TableCell>
                                        
                                        <TableCell align="center" width="10%">
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                
                                                <IconButton size="small" onClick={() => {
                                                    handleConfirmDelete(r.id)
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                
                                                <IconButton size="small" onClick={() => {
                                                    navigate(`/${r.id}/notification`)
                                                }}>
                                                    <PreviewIcon />
                                                </IconButton>                                                
                                            </Box>
                                        </TableCell>

                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                <Button onClick={fetchData}>
                            LOAD MORE!
                </Button>
            </div>}
        </div >
    )
}
