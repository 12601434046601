import React, { useEffect, useState, useRef } from 'react'
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import "../../App.css"
import useRequestResource from '../../hooks/useRequestResource';
import InfiniteScroll from 'react-infinite-scroll-component';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const InstrumentTab = ({currentInstrument, changeInstrument}) => {

    const {resourceList, getResourceList, currentListSize} = useRequestResource({endpoint: "auth", query: "instruments", resourceLabel: "Instruments"})
    const[instrument, setInstrument] = useState(currentInstrument)
    const infiniteScrollRef = useRef(null);
    const instrumentTextField = useRef(null);
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const offset = 25
    useEffect(() => {
        getResourceList()
    }, [getResourceList])

    const fetchData = () => {
        getResourceList(offset)
      }
  
      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (instrumentTextField.current && instrumentTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };
      useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); 

    return (
            <Grid container spacing={3}>
                <Grid item xs={8}>
                            <table className="excel-table">
                            <thead>
                                <tr>
                                <th>Client Name</th>
                                <th>Project Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{instrument.client_name}</td>
                                    <td>{instrument.project_name}</td>
                                </tr>
                            </tbody>
                            </table>
                            </Grid>
                {/* <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        id="client_name"
                                        label={"Customer Name"}
                                        value={instrument.client_name}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        id="project_name"
                                        label={"Project Name"}
                                        value={instrument.project_name}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid> */}
                                <Grid item xs={8}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="instrument"
                                                label={"Instrument - Click to Choose"}
                                                value={`${instrument.instrument_name} ${instrument.instrument_location_name}`}
                                                ref={instrumentTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    style={{ maxHeight: "400px" }}
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    // height={200}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <Grid item xs={8}>
                                                            <MenuItem key={index} value={option.id} style={{border: "1px solid lightgray", borderRadius: "10px"}} onClick={
                                                            () => {
                                                                changeInstrument(option)
                                                                setInstrument(option)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.instrument_name} {option.instrument_location_name}
                                                        </MenuItem>
                                                        </Grid>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                <Grid item xs={8}>
                                    <Accordion style={{backgroundColor: "#64748B"}}>
                                        <AccordionSummary
                                            expandIcon={< ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            >
                                            <Typography color="white">Instrument Details</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: "white"}}>
                                        <Grid item xs={8}>
                                            <table className="excel-table">
                                            <thead>
                                                <tr>
                                                <th>Instrument Serial Number</th>
                                                <th>Instrument Model</th>
                                                <th>Instrument Class</th>
                                                <th>Instrument Brand</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{instrument.instrument_serial_number}</td>
                                                    <td>{instrument.instrument_model}</td>
                                                    <td>{instrument.instrument_class}</td>
                                                    <td>{instrument.instrument_brand}</td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
    )
}

export default InstrumentTab