import { useCallback, useState, useContext } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { SettingsRemote } from "@mui/icons-material";
import formatHttpApiError from "../helpers/formatHttpApiError";

import { AuthContext } from "../contexts/AuthContextProvider";

/**
 * useCallback Hook returns a memoized callback function. The 
 * useCallback and useMemo Hooks are similar. The main difference 
 * is that useMemo returns a memoized value and useCallback returns 
 * a memoized function
 */

export default function useRequestAuth() {
    const [loading, setLoading] = useState(false);
    const [logoutPending, setLogoutPending] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState(null);
    const [is_manager, setIsManager] = useState(false)

    // Takes the function from the AuthContext
    const { setIsAuthenticated } = useContext(AuthContext);

    // Take the error and then format them and pass it to notification
    const handleRequestError = useCallback((err) => {
        const formattedError = formatHttpApiError(err);
        setError(formattedError);
        enqueueSnackbar(formattedError);
        setLoading(false);
    }, [enqueueSnackbar, setLoading, setError])

    const loginUrl = "/auth/jwt/create/";

    /**
     * http://localhost:8000/api/token/
     * {
     *  "username": "user123",
     *  "password": "newpassword123"
     * }
     */
    const login = async (username, password, success, fail) => {
        setLoading(true);

        const response = await fetch(
            loginUrl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "username": username,
                    "password": password,
                })
            }
        );

        const text = await response.text();
        setLoading(false);
        const tokens = JSON.parse(text)
        if (response.status === 200) {
            const userDetails = await fetch(
                "/auth/user/getUser/",
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `JWT ${tokens.access}`
                    }
                },
            )
            const user = JSON.parse(await userDetails.text())
            localStorage.setItem("user_id", user.id)
            localStorage.setItem("username", user.username)
            localStorage.setItem("is_manager", user.is_manager)
            localStorage.setItem("accessToken", tokens.access)
            const device_permissions = ["Noise Meter", "Farmo Rain Gauge", "ESP32 Rain Gauge", "Vibration Meter", "Kerlink Gateway"]
            for (const device_permission of device_permissions) {
                localStorage.setItem(device_permission, false)
            }
            for (const user_device_permission of user.device_permissions) {
                localStorage.setItem(user_device_permission.device_type, true)
            }
            success(tokens, username);
            setIsAuthenticated(true);
        } else {
            Object.entries(JSON.parse(text)).forEach(([key, value]) => {
                fail(`${key}: ${value}`);
            });
        }
    };

    /**
     * Remove the access token from browser
     */
    const logout = async (e) => {
        setLogoutPending(true);
        localStorage.setItem("accessToken", null);
        localStorage.setItem("username", null);
        localStorage.setItem("user_id", null);
        localStorage.setItem("is_manager", false);
        const device_permissions = ["Noise Meter", "Farmo Rain Gauge", "ESP32 Rain Gauge", "Vibration Meter", "Kerlink Gateway"]
        for (const device_permission of device_permissions) {
            localStorage.setItem(device_permission, false)
        }
        setLogoutPending(false);
        setIsAuthenticated(false);
    };

    const requestResetPassword = async (e, success, fail) => {
        const response = await fetch(
            "/user/reset-password/",
            {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: e})
        }
        );
        const text = await response.text();
        if (response.status === 200) {
            success();
        } else {
            fail(text);
        }
    }

    // return all the functions and values required
    return {
        login,
        logout,
        logoutPending,
        loading,
        error,
        is_manager,
        requestResetPassword
    }
}