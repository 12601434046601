import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'
// import {Chart as ChartJS, LinearScale, LineController, PointElement, Tooltip, Legend, TimeScale, CategoryScale} from "chart.js"; 
import "chartjs-adapter-date-fns";
import { Line, Bar } from 'react-chartjs-2';
import { Grid, TextField, Button, MenuItem } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useRequestResource from '../../hooks/useRequestResource';
import * as XLSX from 'xlsx'
import InfiniteScroll from 'react-infinite-scroll-component';
import { ScatterChart, Scatter, XAxis,  
  YAxis, CartesianGrid, Tooltip } from 'recharts'; 
// import '../../style.css'

// ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, LineController, CategoryScale); 

const ChartComponent = ({ timestamps, rainData }) => {
    const data = {
      labels: [...timestamps].reverse(),
      datasets: [
        {
            label: 'Rain Pulse',
            data: [...rainData].reverse(),
            fill: false,
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
        },
      ],
    };
  
    return (
      <div>
        <Line data={data} options={{
          scales:{
            y: {
              beginAtZero: true,
            },
            x: {
              beginAtZero: true,
              ticks: {
                display: false
              }
            }
          }
        }}/>
      </div>
    );
};

const BarComponent = ({ timestamps, rainData }) => {

    const data = {
        labels: [...timestamps].reverse(),
        datasets: [
            {
                label: 'Daily Rain Pulse',
                data: [...rainData].reverse(),
                fill: false,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
            },
        ],
    };
  
    return (
      <div>
        <Bar data={data} options={{
          scales:{
            y: {
              beginAtZero: true,
            },
            x: {
              beginAtZero: true,
              ticks: {
                display: false
              }
            }
          }
        }}/>
      </div>
    );
};
  
const ScatterComponent = ({ timestamps, rainData }) => {
  const chartWidth = window.innerWidth * 0.4;

  const formattedData = timestamps.map((timestamp, index) => ({
      rain: rainData[index],
      timestamp: new Date(timestamp)
  }));
  const dateFormatter = (tick) => {
    // Format the date in local string formats
    return new Date(tick).toLocaleString();
  };

  const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="time">{`${payload[0].name} : ${payload[0].value}`}</p>
          <p className="reading">{`${payload[1].name} : ${payload[1].value}`}</p>
        </div>
      );
    }
  }


  return (
      <ScatterChart width={chartWidth} height={chartWidth * 0.5}>
          <CartesianGrid />
          <XAxis type="date" dataKey="timestamp" name="Time" tickFormatter={dateFormatter} hide={true} />
          <YAxis type="number" dataKey="rain" name="Rain Pulses"/>
          <Tooltip content={<CustomToolTip />}></Tooltip>
          <Scatter data={formattedData.reverse()} fill="green" />
      </ScatterChart>
  );
};


const ESP32RainData = () => {
    const {resourceList, getResourceList} = useRequestResource({endpoint: "auth", query: "esp32_rain_records", resourceLabel: "rain_records_label"})
    const {resourceList: instrumentList, getResourceList: getInstrumentList, currentListSize} = useRequestResource({endpoint: "auth", query: "esp32_rain_instruments", resourceLabel: "ESP32 Rain Instrument"})
    const infiniteScrollRef = useRef(null); 
    const instrumentTextField = useRef(null); 
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [instrument, setInstrument] = useState(null)
    const [id, setId] = useState()
    const [startDate, setStartDate] = useState(Date.now())
    const [endDate, setEndDate] = useState(Date.now())
    const [timestamps, setTimeStamps] = useState([])
    const [rainData, setData] = useState([])
    const [pulseData, setPulseData] = useState([])
    const [plotVariable, setVariable] = useState("current_15_minutes_pulses");
    const [graphType, setGraphType] = useState("line_graph");
    const [graph, setGraph] = useState(<ChartComponent timestamps={timestamps} rainData={rainData} />)

    const offset = 25;

    useEffect(() => {
      getInstrumentList(0)
    }, [getInstrumentList])

    useEffect(() => {
      getResourceList("", "", new Date(Date.now()).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}), 
      new Date(Date.now()).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}), new Date().getTimezoneOffset() / 60, "")
    }, [getResourceList])

    useEffect(() => {
        const fetchData = () => {
          // Your code to fetch data here
          getResourceList("", "", new Date(startDate).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}), 
          new Date(endDate).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}), new Date().getTimezoneOffset() / 60, id)
        };
    
        // Set up a timer to fetch data every 5 seconds
        const timer = setInterval(fetchData, 5 * 60 * 1000);
    
        // Clean up the timer when the component unmounts
        return () => {
          clearInterval(timer);
        };
      }, [getResourceList]);

    useEffect(() => {
      if (graphType === "line_graph") {
        setGraph(<ChartComponent timestamps={timestamps} rainData={rainData} />)
      } else if (graphType === "scatter_graph") {
        setGraph(<ScatterComponent timestamps={timestamps} rainData={rainData} />)
      } else {
        setGraph(<div></div>)
      }
    }, [graphType, timestamps, rainData]);

    useEffect(() => {
        if (resourceList.results) {
            setTimeStamps(resourceList.results.map(item => new Date(item.timestamp).toLocaleString()))
            setData(resourceList.results.map(item => item[plotVariable]))
            setPulseData(resourceList.results.map(item => item['current_24_hours_pulses']))
        }
    }, [plotVariable, resourceList])

    function getData() {
      getResourceList("", "", new Date(startDate).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}),
        new Date(endDate).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}), new Date().getTimezoneOffset() / 60, id)
    }

    const handleOnExportView = () => {
      var wb = XLSX.utils.book_new();
      var updatedList = resourceList.results.map((elem) => ({
        ...elem,
        timestamp: new Date(elem.timestamp).toString(),
        rain_start_time: elem.rain_start_time ? new Date(elem.rain_start_time).toString() : null,
        rain_end_time: elem.rain_end_time ? new Date(elem.rain_end_time).toString() : null
      }));
      var ws = XLSX.utils.json_to_sheet(updatedList);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "ESP32RainData.xlsx");
  }

  const fetchData = () => {
    getInstrumentList(offset)
  }

  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener('click', handleOutsideClick);
    return () => {
      // Clean up the click event listener on component unmount
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []); // Run the effect only once during component mount


  const handleOutsideClick = (event) => {
    // Check if the clicked element is within the InfiniteScroll container or its children
    if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
        if (instrumentTextField.current && instrumentTextField.current.contains(event.target)) {
            return; // Do nothing if the clicked element is the excluded component
          }
      setInfiniteScroll(false); // Close the InfiniteScroll
    }
  };

  const searchAll = (exp) => {
    setInstrument(exp)
    if (exp && exp.trim() !== "") {
        setInstrument(exp)
        getInstrumentList(0, "", "", "", "", exp)
    } else {
        setInstrument("")
        getInstrumentList(0)
    }
  }
    

    return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    autoComplete='off'
                    id="instrument"
                    label={instrument ? "" : "Instrument"}
                    value={instrument}
                    onClick={() => {setInfiniteScroll(true)}}
                    onChange={(e) => {searchAll(e.target.value)}}
                    ref={instrumentTextField}
                >
                </TextField>
            </Grid>
            { showInfiniteScroll ? <Grid item xs={12}>
            <div ref={infiniteScrollRef} onClick={(e) => e.stopPropagation()}>
            <InfiniteScroll
                        dataLength={instrumentList.results.length}
                        next={fetchData}
                        hasMore={currentListSize.current >= offset}
                        loader={<h4>Loading...</h4>}
                        height={400}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                            </p>
                        
                        }>
                        
                        {instrumentList.results.map((option, index) => {
                            return (
                            <MenuItem key={index} value={option.id} onClick={
                                () => {
                                    setId(option.id)
                                    setInstrument(`${option.instrument_name} | ${option.imei}`)
                                    setInfiniteScroll(false)
                                }
                            }>
                                {option.instrument_name} | {option.imei}
                            </MenuItem>
                            );}
                        )}

                    </InfiniteScroll>
            </div>
            </Grid> : <div></div>}
            </Grid>
            <br/>
            <br/>
            <Grid container spacing={3} style={{width: "60vw"}}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Start Date
                </div>
              <DatePicker
                placeholderText='Start Date'
                selected={startDate}
                onChange={(e) => {setStartDate(e)}}
                name="startDate"
                dateFormat="MMMM d, yyyy"
                autoComplete='off'
                popperPlacement="top-start"
              />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  End Date
                </div> 
                <DatePicker
                  placeholderText='Complete Date'
                  selected={endDate}
                  onChange={(e) => {setEndDate(e)}}
                  name="completeDate"
                  dateFormat="MMMM d, yyyy"
                  autoComplete='off'
                  popperPlacement="top-start"
                />
              </Grid>
              </div>
            </Grid>
        <Grid item xs={12}>
        <Button
              onClick={getData}
              size="medium"
              variant="contained"
              color="primary"
          >
              Get Data
          </Button>
        </Grid>
      </Grid> 
            <label for="graphType">Select Graph Type:</label>
            <select id="graphType" name="graphType" onChange={(e) => {setGraphType(e.target.value)}}>
              <option value="line_graph">Line Graph</option>
              <option value="scatter_graph">Scatter Graph</option>
            </select>

            <br/>

            <label for="plottingVariable">Select Plotting Variable:</label>
            <select id="plottingVariable" name="plottingVariable" onChange={(e) => {setVariable(e.target.value)}}>
              <option value="current_15_minutes_pulses">Current 15 minutes pulses</option>
              <option value="current_1_hour_pulses">Current 1 hour pulses</option>
              <option value="current_24_hours_pulses">Current 24 hours pulse</option>
              <option value="average_rainfall_rate_15minutes">Average Rainfall rate 15 minutes</option>
              <option value="average_rainfall_rate_1hour">Average Rainfall rate 1 hour</option>
              <option value="average_rainfall_rate_session">Average Rainfall rate session</option>
            </select>

            <br/>

            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flex: 1, margin: "10px"}}>
                  {graph}
              </div>
              <br/>
              <div style={{flex: 1, margin: "10px"}}>
                  <BarComponent timestamps={timestamps} rainData={pulseData} />
              </div>
            </div>
            <Button 
                onClick={handleOnExportView}
                size="medium"
                variant="contained"
                sx={{ mr: 2 }}> 
                Export View
            </Button>
            <br/>
            <br/>
            <br/>
            <table className="excel-table">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Current 15 min Pulses</th>
                  <th>Current 1 hr Pulses</th>
                  <th>Current 24 hours Pulses</th>
                  <th>VBatt</th>
                </tr>
              </thead>
              <tbody>
                {resourceList.results.map((elem) => {
                  return (
                    <tr>
                        <td>{new Date(elem.timestamp).toLocaleString()} SGT</td>
                        <td>{elem.current_15_minutes_pulses}</td>
                        <td>{elem.current_1_hour_pulses}</td>
                        
                        <td>{elem.current_24_hours_pulses}</td>
                        
                        <td>{elem.vbatt}</td>
                        
                  </tr>
                  )
                })}
              </tbody>
            </table>
            <br/>
            <br/>
        </div>
    )
};

export default ESP32RainData;


