import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import React, { useEffect, useState, useRef } from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCircle, faMapMarkerAlt, faTag, faClock, faCalendar, faChartLine } from '@fortawesome/free-solid-svg-icons';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const CategoryTab = ({latest, instrument, location, category}) => {

    return (
        <div style={{marginBottom: "20px",  minWidth: "100%", maxWidth: "100%"}}>
        <Grid container spacing={3} style={{ minWidth: "100%", maxWidth: "100%"}}> 
        <Grid item xs={8} style={{ minWidth: "100%", maxWidth: "100%"}}>
            <Accordion defaultExpanded={false} style={{backgroundColor: "#64748B",  minWidth: "100%", maxWidth: "100%"}}>
            <AccordionSummary style={{minWidth: "100%", maxWidth: "100%"}}
                expandIcon={< ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography color="white">Category: {location.category} {category.label}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{backgroundColor: "white", minWidth: "100%", maxWidth: "100%"}}>
                <table className="excel-table" style={{minWidth: "100%", maxWidth: "100%"}}>
                <thead>
                    <tr>
                        <th>LEQ</th>
                        <th>Day</th>
                        <th>7am-7pm</th>
                        <th>7pm-10pm</th>
                        <th>10pm-7am</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={2}>Leq_5min</td>
                        <td>Mon - Sat</td>
                        <td>{category.leq5min_7am_to_7pm}</td>
                        <td>{category.leq5min_7pm_to_10pm}</td>
                        <td>{category.leq5min_10pm_to_7am}</td>
                    </tr>
                    <tr>
                        <td>Sun & PH</td>
                        <td>{category.leq5min_7am_to_7pm_sunday}</td>
                        <td>{category.leq5min_7pm_to_10pm_sunday}</td>
                        <td>{category.leq5min_10pm_to_7am_sunday}</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>Leq_1hr</td>
                        <td>Mon - Sat</td>
                        <td>N.A.</td>
                        <td>{category.leq1hour_7pm_to_10pm === category.leq1hour_10pm_to_7am ? 'N.A.' : category.leq1hour_7pm_to_10pm}</td>
                        <td>{category.leq1hour_7pm_to_10pm === category.leq1hour_10pm_to_7am ? 'N.A.' : category.leq1hour_10pm_to_7am}</td>
                    </tr>
                    <tr>
                        <td>Sun & PH</td>
                        <td>N.A.</td>
                        <td>{category.leq1hour_7pm_to_10pm_sunday === category.leq1hour_10pm_to_7am_sunday ? 'N.A.' : category.leq1hour_7pm_to_10pm_sunday}</td>
                        <td>{category.leq1hour_7pm_to_10pm_sunday === category.leq1hour_10pm_to_7am_sunday ? 'N.A.' : category.leq1hour_10pm_to_7am_sunday}</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>Leq_12hr</td>
                        <td>Mon - Sat</td>
                        <td>{category.leq12hour_7am_to_7pm}</td>
                        <td>N.A.</td>
                        <td>N.A.</td>
                    </tr>
                    <tr>
                        <td>Sun & PH</td>
                        <td>{category.leq12hour_7am_to_7pm_sunday}</td>
                        <td>N.A.</td>
                        <td>N.A.</td>
                    </tr>
                </tbody>
                </table>
            </AccordionDetails>
            </Accordion>
            </Grid>
            </Grid>
        </div>
    )
}