import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import "../../App.css"
import Form from 'react-bootstrap/Form';
import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';



const validationSchema = yup.object({
    device_name: yup.string().required("Device name is required").max(255, "Max Length is 255!"),
    farmo_device_type: yup.string().max(255, "Max Length is 255!"),
    imei: yup.string().max(20, "Max Length is 20!"),
})

const EditRainInstrument = () => {
    const {id} = useParams();
    const groupInfiniteScrollRef = useRef(null); 
    const userInfiniteScrollRef = useRef(null);
    const accessGroupTextField = useRef(null); 
    const accessUserTextField = useRef(null); 
    const { getResourceList: getAccessGroupList, resourceList: accessGroupList, currentListSize: currentAccessGroupListSize } = useRequestResource({ endpoint: "auth", query: "accessgroups", resourceLabel: "Access Group List" });
    const { getResourceList: getAccessUserList, resourceList: accessUserList, currentListSize: currentAccessUserListSize } = useRequestResource({ endpoint: "auth", query: "users", resourceLabel: "User List" });
    const { getResource, updateResource, resources }  = useRequestResource({ endpoint: "auth", query: "rain_instrument", resourceLabel: "Rain Instrument" });
    const navigate = useNavigate();
    const [ showGroupInfiniteScroll, setGroupInfiniteScroll ] = useState(false)
    const [ showUserInfiniteScroll, setUserInfiniteScroll ] = useState(false)
    const [ accessGroups, setAccessGroups ] = useState([])
    const [ accessUsers, setAccessUsers ] = useState([])
    let offset = 25;

    const statusOptions = [
        { value: 'ON', label: 'ON' },
        { value: 'OFF', label: 'OFF' }
    ]

    const [initialValues, setInitialValues] = useState({
        device_name: "",
        imei: "",
        farmo_device_type: "",
        access_groups: [],
        access_users: [],
        status: "ON",
        latitude: "",
        longitude: ""
    });

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [getResource, id])

    useEffect(() => {
        if (resources) {
            setInitialValues({
                device_name: resources.device_name ? resources.device_name : null,
                farmo_device_type: resources.farmo_device_type ? resources.farmo_device_type : null,
                imei: resources.imei ? resources.imei : null,
                status: resources.status ? resources.status : null,
                latitude: resources.latitude ? resources.latitude : null,
                longitude: resources.longitude ? resources.longitude : null,
                access_groups: accessGroups.map(obj => obj.id),
                access_users: accessUsers.map(obj => obj.id)
            })
            if (resources.access_groups) {
                setAccessGroups(resources.access_groups)
            }
            if (resources.access_users) {
                setAccessUsers(resources.access_users)
            }
        }
    }, [resources])

    const fetchGroupData = () => {
        getAccessGroupList(offset)
    }

    const fetchUserData = () => {
        getAccessUserList(offset)
    }

    useEffect(() => {
        getAccessGroupList();
        getAccessUserList();
    }, [getAccessGroupList, getAccessUserList])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (groupInfiniteScrollRef.current && !groupInfiniteScrollRef.current.contains(event.target)) {
            if (accessGroupTextField.current && accessGroupTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setGroupInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (userInfiniteScrollRef.current && !userInfiniteScrollRef.current.contains(event.target)) {
            if (accessUserTextField.current && accessUserTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setUserInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            device_name: values.device_name ? values.device_name : null,
            farmo_device_type: values.farmo_device_type ? values.farmo_device_type : null,
            imei: values.imei ? values.imei : null,
            status: values.status ? values.status : null,
            latitude: values.latitude ? values.latitude : null,
            longitude: values.longitude ? values.longitude : null,
            access_groups: accessGroups.map(obj => obj.id),
            access_users: accessUsers.map(obj => obj.id)
        }
        updateResource(id, formattedValues, () => {
            navigate(`/raininstruments`)
        })
    }

    function addGroup(group) {
        const unique = {}
        const results = []
        for (const geo of accessGroups) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = group.id
        if (!unique[key]) {
            results.push(group)
        }
        setAccessGroups(results)
    }

    function deleteGroup(option) {
        const set = new Set([...accessGroups])
        set.delete(option)
        setAccessGroups([...set])
    }

    const access_groups_list = 
    (
        <Grid item xs={12}>
            {accessGroups.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.label}
                            <IconButton size="small" onClick={() => {deleteGroup(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    function addUser(user) {
        const unique = {}
        const results = []
        for (const geo of accessUsers) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = user.id
        if (!unique[key]) {
            results.push(user)
        }
        setAccessUsers(results)
    }

    function deleteUser(option) {
        const set = new Set([...accessUsers])
        set.delete(option)
        setAccessUsers([...set])
    }

    const access_users_list = 
    (
        <Grid item xs={12}>
            {accessUsers.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.first_name} {option.last_name}
                            <IconButton size="small" onClick={() => {deleteUser(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <div style={{alignItems: "start"}}>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Project Information
                </Typography>

                <br />
            <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return <form onSubmit={formik.handleSubmit} style={{textAlign: "start"}}>
                                <Grid container spacing={3} style={{alignItems: "start"}}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="device_name"
                                            label="Instrument Name"
                                            {...formik.getFieldProps('device_name')}
                                            error={formik.touched.device_name && Boolean(formik.errors.device_name)}
                                            helperText={formik.touched.device_name && formik.errors.device_name}
                                        >
                                        </TextField>
                                    </Grid>
                                
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="farmo_device_type"
                                            label="Farmo Device Type"
                                            {...formik.getFieldProps('farmo_device_type')}
                                            error={formik.touched.farmo_device_type && Boolean(formik.errors.farmo_device_type)}
                                            helperText={formik.touched.farmo_device_type && formik.errors.farmo_device_type}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="imei"
                                            label="IMEI"
                                            {...formik.getFieldProps('imei')}
                                            error={formik.touched.imei && Boolean(formik.errors.imei)}
                                            helperText={formik.touched.imei && formik.errors.imei}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="status"
                                            label="Status"
                                            {...formik.getFieldProps('status')}
                                            select
                                            value={formik.values.status}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                            }}
                                            error={formik.touched.status && Boolean(formik.errors.status)}
                                            helperText={formik.touched.status && formik.errors.status}
                                        >
                                            {statusOptions.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="latitude"
                                            label="Latitude"
                                            {...formik.getFieldProps('latitude')}
                                            error={formik.touched.latitude&& Boolean(formik.errors.latitude)}
                                            helperText={formik.touched.latitude && formik.errors.latitude}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="longitude"
                                            label="Longitude"
                                            {...formik.getFieldProps('longitude')}
                                            error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                                            helperText={formik.touched.longitude && formik.errors.longitude}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            id="access_groups"
                                            label="Access Groups"
                                            value=""
                                            ref={accessGroupTextField}
                                            onClick={() => setGroupInfiniteScroll(true)}
                                            error={formik.touched.access_groups && Boolean(formik.errors.access_groups)}
                                            helperText={formik.touched.access_groups && formik.errors.access_groups}
                                        >
                                        </TextField>
                                    </Grid>
                                        

                                    { showGroupInfiniteScroll ? <Grid item xs={12}>

                                    <div ref={groupInfiniteScrollRef}>
                                    <InfiniteScroll
                                                dataLength={accessGroupList.results.length}
                                                next={fetchGroupData}
                                                hasMore={currentAccessGroupListSize.current >= offset}
                                                loader={<h4>Loading...</h4>}
                                                height={400}
                                                
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                    <b>Yay! You have seen it all</b>
                                                    </p>
                                                
                                                }>
                                                
                                                {accessGroupList.results.map((option, index) => {
                                                    return (
                                                    <MenuItem key={index} value={option.id} onClick={
                                                        () => {
                                                            addGroup(option)
                                                        }
                                                    }>
                                                        {option.label}
                                                    </MenuItem>
                                                    );}
                                                )}

                                            </InfiniteScroll>
                                            </div>
                                    </Grid> : <div></div>}

                                    <Grid item xs={12}>
                                        {access_groups_list}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            autoComplete='off'
                                            id="access_users"
                                            label="Access Users"
                                            value=""
                                            ref={accessUserTextField}
                                            onClick={() => setUserInfiniteScroll(true)}
                                            error={formik.touched.access_users && Boolean(formik.errors.access_users)}
                                            helperText={formik.touched.access_users && formik.errors.access_users}
                                        >
                                        </TextField>
                                    </Grid>
                                        

                                    { showUserInfiniteScroll ? <Grid item xs={12}>

                                    <div ref={userInfiniteScrollRef}>
                                    <InfiniteScroll
                                                dataLength={accessUserList.results.length}
                                                next={fetchUserData}
                                                hasMore={currentAccessUserListSize.current >= offset}
                                                loader={<h4>Loading...</h4>}
                                                height={400}
                                                
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                    <b>Yay! You have seen it all</b>
                                                    </p>
                                                
                                                }>
                                                
                                                {accessUserList.results.map((option, index) => {
                                                    return (
                                                    <MenuItem key={index} value={option.id} onClick={
                                                        () => {
                                                            addUser(option)
                                                        }
                                                    }>
                                                        {option.first_name} {option.last_name}
                                                    </MenuItem>
                                                    );}
                                                )}

                                            </InfiniteScroll>
                                            </div>
                                    </Grid> : <div></div>}

                                    <Grid item xs={12}>
                                        {access_users_list}
                                    </Grid>


                                <Grid item xs={12} >
                                    <Button component={Link}
                                        to={`/raininstruments`}
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        sx = {{ mr: 2 }}
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        // type="submit"
                                        onClick={() => {handleSubmit(formik.values)}}
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Create New Rain Instrument
                                    </Button>

                                </Grid>
                            </Grid>
                            </form>
                        }
                    }
                </Formik>
                </Paper>
        </div>
    )
}

export default EditRainInstrument