import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useState } from "react";
import { Formik } from "formik";
import useRequestResource from "../../hooks/useRequestResource";
import * as yup from 'yup';

const validationSchema = yup.object({
    leq5min_7am_to_7pm: yup.string()
    .required('LEQ5 min 7am to 7pm is required')
    .test('number', 'LEQ5 min 7am to 7pm must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ5 min 7am to 7pm must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ5 min 7am to 7pm must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ5 min 7am to 7pm must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq12hour_7am_to_7pm: yup.string()
    .required('LEQ 12hour 7am to 7pm is required')
    .test('number', 'LEQ 12hour 7am to 7pm must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 12hour 7am to 7pm must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 12hour 7am to 7pm must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 12hour 7am to 7pm must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq5min_7pm_to_10pm: yup.string()
    .required('LEQ 5min 7pm to 10pm is required')
    .test('number', 'LEQ 5min 7pm to 10pm must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 5min 7pm to 10pm must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 5min 7pm to 10pm must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 5min 7pm to 10pm must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq1hour_7pm_to_10pm: yup.string()
    .test('number', 'LEQ 1hour 7pm to 10pm must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 1hour 7pm to 10pm must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 1hour 7pm to 10pm must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 1hour 7pm to 10pm must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq5min_10pm_to_7am: yup.string()
    .required('LEQ 5min 10pm to 7am is required')
    .test('number', 'LEQ 5min 10pm to 7am must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 5min 10pm to 7am must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 5min 10pm to 7am must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 5min 10pm to 7am must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq1hour_10pm_to_7am: yup.string()
    .test('number', 'LEQ 1hour 10pm to 7am must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 1hour 10pm to 7am must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 1hour 10pm to 7am must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 1hour 10pm to 7am must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq5min_7am_to_7pm_sunday: yup.string()
    .required('LEQ 5min 7am to 7pm sunday is required')
    .test('number', 'LEQ 5min 7am to 7pm sunday must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 5min 7am to 7pm sunday must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 5min 7am to 7pm sunday must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 5min 7am to 7pm sunday must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq12hour_7am_to_7pm_sunday: yup.string()
    .required('LEQ 12hour 7am to 7pm sunday is required')
    .test('number', 'LEQ 12hour 7am to 7pm sunday must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 12hour 7am to 7pm sunday must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 12hour 7am to 7pm sunday must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 12hour 7am to 7pm sunday must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq5min_7pm_to_10pm_sunday: yup.string()
    .test('number', 'LEQ 5min 7pm to 10pm sunday must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 5min 7pm to 10pm sunday must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 5min 7pm to 10pm sunday must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 5min 7pm to 10pm sunday must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq1hour_7pm_to_10pm_sunday: yup.string()
    .required('LEQ 1hour 7pm to 10pm sunday is required')
    .test('number', 'LEQ 1hour 7pm to 10pm sunday must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 1hour 7pm to 10pm sunday must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 1hour 7pm to 10pm sunday must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 1hour 7pm to 10pm sunday must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq5min_10pm_to_7am_sunday: yup.string()
    .test('number', 'LEQ 5min 10pm to 7am sunday must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 5min 10pm to 7am sunday must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 5min 10pm to 7am sunday must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 5min 10pm to 7am sunday must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
    leq1hour_10pm_to_7am_sunday: yup.string()
    .required('LEQ 1hour 10pm to 7am sunday is required')
    .test('number', 'LEQ 1hour 10pm to 7am sunday must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'LEQ 1hour 10pm to 7am sunday must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'LEQ 1hour 10pm to 7am sunday must be greater than or equal to 0.0', (value) => {
        if (value) {
            return parseFloat(value) >= 0.0;
        }
        return true
    })
    .test('max-value', 'LEQ 1hour 10pm to 7am sunday must be less than or equal to 9999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.9;
        }
        return true
    }),
})

export const CreateCategory = () => {

    const {addResource} = useRequestResource({endpoint: "auth", query: "category", resourceLabel: "Category"})

    const [initialValues, setInitialValues] = useState({
        category_name: "",
        label:"",
        leq5min_7am_to_7pm: "",
        leq12hour_7am_to_7pm: "",
        leq5min_7pm_to_10pm: "",
        leq1hour_7pm_to_10pm: "",
        leq5min_10pm_to_7am: "",
        leq1hour_10pm_to_7am: "",
        leq5min_7am_to_7pm_sunday: "",
        leq12hour_7am_to_7pm_sunday: "",
        leq5min_7pm_to_10pm_sunday: "",
        leq1hour_7pm_to_10pm_sunday: "",
        leq5min_10pm_to_7am_sunday: "",
        leq1hour_10pm_to_7am_sunday: "",
    });

    const handleSubmit = values => {
        const formattedValues = {
            category_name: values.category_name === "" ? null : values.category_name,
            label: values.label === "" ? null : values.label,
            leq5min_7am_to_7pm: values.leq5min_7am_to_7pm === "" ? null : values.leq5min_7am_to_7pm,
            leq12hour_7am_to_7pm: values.leq12hour_7am_to_7pm === "" ? null : values.leq12hour_7am_to_7pm,
            leq5min_7pm_to_10pm: values.leq5min_7pm_to_10pm === "" ? null : values.leq5min_7pm_to_10pm,
            leq1hour_7pm_to_10pm: values.leq1hour_7pm_to_10pm === "" ? null : values.leq1hour_7pm_to_10pm,
            leq5min_10pm_to_7am: values.leq5min_10pm_to_7am === "" ? null : values.leq5min_10pm_to_7am,
            leq1hour_10pm_to_7am: values.leq1hour_10pm_to_7am === "" ? null : values.leq1hour_10pm_to_7am,
            leq5min_7am_to_7pm_sunday: values.leq5min_7am_to_7pm_sunday === "" ? null : values.leq5min_7am_to_7pm_sunday,
            leq12hour_7am_to_7pm_sunday: values.leq12hour_7am_to_7pm_sunday === "" ? null : values.leq12hour_7am_to_7pm_sunday,
            leq5min_7pm_to_10pm_sunday: values.leq5min_7pm_to_10pm_sunday === "" ? null : values.leq5min_7pm_to_10pm_sunday,
            leq1hour_7pm_to_10pm_sunday: values.leq1hour_7pm_to_10pm_sunday === "" ? null : values.leq1hour_7pm_to_10pm_sunday,
            leq5min_10pm_to_7am_sunday: values.leq5min_10pm_to_7am_sunday === "" ? null : values.leq5min_10pm_to_7am_sunday,
            leq1hour_10pm_to_7am_sunday: values.leq1hour_10pm_to_7am_sunday === "" ? null : values.leq1hour_10pm_to_7am_sunday,
        }
        
        addResource(formattedValues, () => {
            window.history.back()
        })
    }

    return (
        <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="category_name"
                                                            label="Category Name"
                                                            {...formik.getFieldProps('category_name')}
                                                            error={(formik.touched.category_name) && Boolean(formik.errors.category_name)}
                                                            helperText={(formik.touched.category_name) && formik.errors.category_name}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="label"
                                                            label="Label"
                                                            {...formik.getFieldProps('label')}
                                                            error={(formik.touched.label) && Boolean(formik.errors.label)}
                                                            helperText={(formik.touched.label) && formik.errors.label}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq5min_7am_to_7pm"
                                                            label="LEQ 5min 7am to 7pm"
                                                            {...formik.getFieldProps('leq5min_7am_to_7pm')}
                                                            error={formik.touched.leq5min_7am_to_7pm && Boolean(formik.errors.leq5min_7am_to_7pm)}
                                                            helperText={formik.touched.leq5min_7am_to_7pm && formik.errors.leq5min_7am_to_7pm}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq12hour_7am_to_7pm"
                                                            label="LEQ 12hour 7am to 7pm"
                                                            {...formik.getFieldProps('leq12hour_7am_to_7pm')}
                                                            error={formik.touched.leq12hour_7am_to_7pm && Boolean(formik.errors.leq12hour_7am_to_7pm)}
                                                            helperText={formik.touched.leq12hour_7am_to_7pm && formik.errors.leq12hour_7am_to_7pm}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq5min_7pm_to_10pm"
                                                            label="LEQ 5min 7pm to 10pm"
                                                            {...formik.getFieldProps('leq5min_7pm_to_10pm')}
                                                            error={formik.touched.leq5min_7pm_to_10pm && Boolean(formik.errors.leq5min_7pm_to_10pm)}
                                                            helperText={formik.touched.leq5min_7pm_to_10pm && formik.errors.leq5min_7pm_to_10pm}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq1hour_7pm_to_10pm"
                                                            label="LEQ 1hour 7pm to 10pm"
                                                            {...formik.getFieldProps('leq1hour_7pm_to_10pm')}
                                                            error={formik.touched.leq1hour_7pm_to_10pm && Boolean(formik.errors.leq1hour_7pm_to_10pm)}
                                                            helperText={formik.touched.leq1hour_7pm_to_10pm && formik.errors.leq1hour_7pm_to_10pm}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq5min_10pm_to_7am"
                                                            label="LEQ 5min 10pm to 7am"
                                                            {...formik.getFieldProps('leq5min_10pm_to_7am')}
                                                            error={formik.touched.leq5min_10pm_to_7am && Boolean(formik.errors.leq5min_10pm_to_7am)}
                                                            helperText={formik.touched.leq5min_10pm_to_7am && formik.errors.leq5min_10pm_to_7am}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq1hour_10pm_to_7am"
                                                            label="LEQ 1hour 10pm to 7am"
                                                            {...formik.getFieldProps('leq1hour_10pm_to_7am')}
                                                            error={formik.touched.leq1hour_10pm_to_7am && Boolean(formik.errors.leq1hour_10pm_to_7am)}
                                                            helperText={formik.touched.leq1hour_10pm_to_7am && formik.errors.leq1hour_10pm_to_7am}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq5min_7am_to_7pm_sunday"
                                                            label="LEQ 5min 7am to 7pm Sunday"
                                                            {...formik.getFieldProps('leq5min_7am_to_7pm_sunday')}
                                                            error={formik.touched.leq5min_7am_to_7pm_sunday && Boolean(formik.errors.leq5min_7am_to_7pm_sunday)}
                                                            helperText={formik.touched.leq5min_7am_to_7pm_sunday && formik.errors.leq5min_7am_to_7pm_sunday}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq12hour_7am_to_7pm_sunday"
                                                            label="LEQ 12hour 7am to 7pm Sunday"
                                                            {...formik.getFieldProps('leq12hour_7am_to_7pm_sunday')}
                                                            error={formik.touched.leq12hour_7am_to_7pm_sunday && Boolean(formik.errors.leq12hour_7am_to_7pm_sunday)}
                                                            helperText={formik.touched.leq12hour_7am_to_7pm_sunday && formik.errors.leq12hour_7am_to_7pm_sunday}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq5min_7pm_to_10pm_sunday"
                                                            label="LEQ 5min 7pm to 10pm Sunday"
                                                            {...formik.getFieldProps('leq5min_7pm_to_10pm_sunday')}
                                                            error={formik.touched.leq5min_7pm_to_10pm_sunday && Boolean(formik.errors.leq5min_7pm_to_10pm_sunday)}
                                                            helperText={formik.touched.leq5min_7pm_to_10pm_sunday && formik.errors.leq5min_7pm_to_10pm_sunday}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq1hour_7pm_to_10pm_sunday"
                                                            label="LEQ 1hour 7pm to 10pm Sunday"
                                                            {...formik.getFieldProps('leq1hour_7pm_to_10pm_sunday')}
                                                            error={formik.touched.leq1hour_7pm_to_10pm_sunday && Boolean(formik.errors.leq1hour_7pm_to_10pm_sunday)}
                                                            helperText={formik.touched.leq1hour_7pm_to_10pm_sunday && formik.errors.leq1hour_7pm_to_10pm_sunday}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq5min_10pm_to_7am_sunday"
                                                            label="LEQ 5min 10pm to 7am Sunday"
                                                            {...formik.getFieldProps('leq5min_10pm_to_7am_sunday')}
                                                            error={formik.touched.leq5min_10pm_to_7am_sunday && Boolean(formik.errors.leq5min_10pm_to_7am_sunday)}
                                                            helperText={formik.touched.leq5min_10pm_to_7am_sunday && formik.errors.leq5min_10pm_to_7am_sunday}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="leq1hour_10pm_to_7am_sunday"
                                                            label="LEQ 1hour 10pm to 7am Sunday"
                                                            {...formik.getFieldProps('leq1hour_10pm_to_7am_sunday')}
                                                            error={formik.touched.leq1hour_10pm_to_7am_sunday && Boolean(formik.errors.leq1hour_10pm_to_7am_sunday)}
                                                            helperText={formik.touched.leq1hour_10pm_to_7am_sunday && formik.errors.leq1hour_10pm_to_7am_sunday}
                                                        />
                                                    </Grid>

                                                    

                                                    <Grid item xs={12} >

                                                        <Button
                                                            type="submit"
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Create Category
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
    )
}