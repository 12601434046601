import React, { useEffect, useState, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';


const validationSchema = yup.object({
    name: yup.string().required("Name is required").max(50, "Max length is 50!"),
    folder_path: yup.string().max(255, "Max length is 255!"),
    filename_pattern: yup.string().required("Folder Path is required").max(255, "Max length is 255!"),
    header_row_number: yup.string()
    .test('number', 'Header Row Number must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    }),
    line_header_end: yup.string()
    .test('number', 'Line Header End must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
})


const EditDataParser = () => {
    const {id} = useParams()
    const { getResource, updateResource, resources }  = useRequestResource({ endpoint: "auth", query: "dataparser", resourceLabel: "Data Parser" });
    const navigate = useNavigate();

    let offset = 25;

    const [initialValues, setInitialValues] = useState({
        name: "",
        folder_path: "",
        filename_pattern: "",
        header_row_number: "",
        line_header_end: "",
        header_information: "",
        extracted_timestamp_column_name: "",
        extracted_noise_data_column_name: "",
        extracted_timestamp_datetime_format: "",
    });

    const [is_4g_format, setIs4gFormat] = useState(false)

    useEffect(() => {
        if (id) {
            getResource(id)
        }
    }, [getResource, id])

    useEffect(() => {
        if (resources) {
            setInitialValues({
                name: resources.name ? resources.name : null,
                folder_path: resources.folder_path ? resources.folder_path : null,
                filename_pattern: resources.filename_pattern ? resources.filename_pattern : null,
                header_row_number: resources.header_row_number ? resources.header_row_number : null,
                line_header_end: resources.line_header_end? resources.line_header_end : null,                
                header_information: resources.header_information ? resources.header_information : null,
                extracted_timestamp_column_name: resources.extracted_timestamp_column_name ? resources.extracted_timestamp_column_name : null,
                extracted_noise_data_column_name: resources.extracted_noise_data_column_name ? resources.extracted_noise_data_column_name : null,
                extracted_timestamp_datetime_format: resources.extracted_timestamp_datetime_format ? resources.extracted_timestamp_datetime_format : null,                
            })
            setIs4gFormat(resources.is_4g_format)

        }
    }, [resources])

    const handleSubmit = values => {
        const formattedValues = {
            name: values.name === "" ? null : values.name,
            folder_path: values.folder_path == "" ? null : values.folder_path,
            filename_pattern: values.filename_pattern == "" ? null : values.filename_pattern,
            header_row_number: values.header_row_number === "" ? null : values.header_row_number,
            line_header_end: values.line_header_end === "" ? null: values.line_header_end,
            header_information: values.header_information === "" ? null: values.header_information,
            extracted_timestamp_column_name: values.extracted_timestamp_column_name === "" ? null: values.extracted_timestamp_column_name,
            extracted_noise_data_column_name: values.extracted_noise_data_column_name === "" ? null: values.extracted_noise_data_column_name,
            extracted_timestamp_datetime_format: values.extracted_timestamp_datetime_format === "" ? null: values.extracted_timestamp_datetime_format,
            is_4g_format: is_4g_format,
        }
        updateResource(id, formattedValues, () => {
            window.history.back()
        })
    }
    const timestampDatetimeFormatOptions = [
        {value: '%d-%m-%Y %H:%M:%S', label: '%d-%m-%Y %H:%M:%S'},
        {value: '%Y-%m-%d %H:%M:%S', label: '%Y-%m-%d %H:%M:%S'},
    ]
    
    function handleCheck(value, type) {
        switch (type) {
            case "4g_format": setIs4gFormat(prev => !prev); break;
            default : 
        }
    }


    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Data Parser Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="name"
                                                label="Name"
                                                {...formik.getFieldProps('name')}
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                                helperText={formik.touched.name && formik.errors.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="folder_path"
                                                label="Folder Path"
                                                {...formik.getFieldProps('folder_path')}
                                                error={formik.touched.folder_path && Boolean(formik.errors.folder_path)}
                                                helperText={formik.touched.folder_path && formik.errors.folder_path}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="filename_pattern"
                                                label="Filename Pattern"
                                                {...formik.getFieldProps('filename_pattern')}
                                                error={formik.touched.filename_pattern && Boolean(formik.errors.filename_pattern)}
                                                helperText={formik.touched.filename_pattern && formik.errors.filename_pattern}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="header_row_number"
                                                label="Header Row Number"
                                                inputMode='numeric'
                                                {...formik.getFieldProps('header_row_number')}
                                                error={formik.touched.header_row_number && Boolean(formik.errors.header_row_number)}
                                                helperText={formik.touched.header_row_number && formik.errors.header_row_number}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="line_header_end"
                                                label="Line Header End"
                                                inputMode='numeric'
                                                {...formik.getFieldProps('line_header_end')}
                                                error={formik.touched.line_header_end && Boolean(formik.errors.line_header_end)}
                                                helperText={formik.touched.line_header_end && formik.errors.line_header_end}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="header_information"
                                                label="Header Information"
                                                {...formik.getFieldProps('header_information')}
                                                error={formik.touched.header_information && Boolean(formik.errors.header_information)}
                                                helperText={formik.touched.header_information && formik.errors.header_information}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="extracted_timestamp_column_name"
                                                label="Timestamp Column Name"
                                                {...formik.getFieldProps('extracted_timestamp_column_name')}
                                                error={formik.touched.extracted_timestamp_column_name && Boolean(formik.errors.extracted_timestamp_column_name)}
                                                helperText={formik.touched.extracted_timestamp_column_name && formik.errors.extracted_timestamp_column_name}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="extracted_timestamp_datetime_format"
                                                label="Timestamp Datetime Format"
                                                {...formik.getFieldProps('extracted_timestamp_datetime_format')}
                                                select
                                                value={formik.values.extracted_timestamp_datetime_format}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.status && Boolean(formik.errors.extracted_timestamp_datetime_format)}
                                                helperText={formik.touched.status && formik.errors.extracted_timestamp_datetime_format}
                                            >
                                                {timestampDatetimeFormatOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="extracted_noise_data_column_name"
                                                label="Noise Data Column Name"
                                                {...formik.getFieldProps('extracted_noise_data_column_name')}
                                                error={formik.touched.extracted_noise_data_column_name && Boolean(formik.errors.extracted_noise_data_column_name)}
                                                helperText={formik.touched.extracted_noise_data_column_name && formik.errors.extracted_noise_data_column_name}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div key={`inline-checkbox`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mb-3">
                                            <Form.Check
                                                        style={{ margin: '20px' }}
                                                        onChange={(value) => handleCheck(value, '4g_format')}
                                                        inline
                                                        checked={is_4g_format}
                                                        label='4G Format'
                                                        name="Data Parser Format"
                                                        type='checkbox'
                                                        id={`inline-checkbox-4g_format`}
                                                    />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/dataparsers`}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                sx = {{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                // type="submit"
                                                onClick={() => {handleSubmit(formik.values)}}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}

export default EditDataParser;