import React, { useEffect, useState, useRef} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ClearIcon from '@mui/icons-material/Clear';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Dialog, DialogTitle, DialogActions, IconButton, Box, Button, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"; 
import useRequestResource from '../../hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import InfiniteScroll from 'react-infinite-scroll-component';
import * as yup from 'yup';
import DeleteIcon from "@mui/icons-material/Delete"
import DatePicker from 'react-datepicker';
import {Search, SearchIconWrapper, StyledInputBase} from '../../components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const validationSchema = yup.object({
    request_type: yup.string().required("Request Type is required").max(255, "Max Length is 255!"),
    upload_format: yup.string().required("Format is required").max(255, "Max Length is 255!"),
})

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }


export default function Export() {
    const { resourceList: exportList, getResourceList: getExportList, currentListSize: currentExportListSize, searchResources: searchExport, searchList: searchExportList} = useRequestResource({ endpoint: "auth", query: "export_files", resourceLabel: "Export List" });
    const { addResource, getResource, resources, deleteResourcesBefore, deleteResource } = useRequestResource({endpoint: "auth", query: "export_file", resourceLabel: "Export"})
    const { resourceList: instrumentList, getResourceList: getInstrumentList, currentListSize: currentInstrumentListSize, searchResources: searchInstrument, searchList: searchInstrumentList} = useRequestResource({ endpoint: "auth", query: "instruments", resourceLabel: "Instrument List" });

    const offset = 25;
    const [exp, setExp] = useState("")

    const [sorting_query, setSortingQuery] = useState(null)

    const navigate = useNavigate();
    const instrumentTextField = useRef(null); 
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const [instruments, setInstruments] = useState([])
    const instrumentInfiniteScrollRef = useRef(null);
    const [ showInstrumentInfiniteScroll, setInstrumentInfiniteScroll ] = useState(false)

    const requestOptions = [
        { value: 'Template', label: 'Template' },
        { value: 'Data', label: 'Data' },
    ]

    const uploadFormatOptions = [
        { value: 'csv', label: 'csv' },
        { value: 'xlsx', label: 'xlsx' },
    ]

    const [initialValues, setInitialValues] = useState({
        request_type: "",
        upload_format: ""
    });

    useEffect(() => {
        getExportList();
        getInstrumentList();
    }, [getExportList, getInstrumentList]);

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount

      const handleOutsideClick = (event) => {
        if (instrumentInfiniteScrollRef.current && !instrumentInfiniteScrollRef.current.contains(event.target)) {
            if (instrumentTextField.current && instrumentTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInstrumentInfiniteScroll(false); // Close the InfiniteScroll
        }
      };



    const fetchData = () => {
        getExportList(offset, sorting_query)
    }

    const fetchInstrumentData = () => {
        getInstrumentList(offset)
    }


    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getExportList(0, sorting_query)
        }
    }, [sorting_query, getExportList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    const handleSubmit = values => {
        const formattedValues = {
            request_type: values.request_type ? values.request_type : null,
            upload_format: values.upload_format ? values.upload_format : null,
            instruments: instruments.map(obj => obj.id)
        }
        addResource(formattedValues, () => {
            window.location.reload();
        })
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    const searchData = () => {
        searchExport(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchExportList.results
        } else {
            console.log(exportList.results)
            return exportList.results
        }
    }

    function addInstrument(newInstrument) {
        const unique = {}
        const results = []
        for (const instrument of instruments) {
            const key = instrument.id
            unique[key] = true;
            results.push(instrument)

        }
        const key = newInstrument.id
        if (!unique[key]) {
            results.push(newInstrument)
        }
        setInstruments(results)
    }

    function deleteInstrument(option) {
        const set = new Set([...instruments])
        set.delete(option)
        setInstruments([...set])
    }

    const instrument_list = 
    (
        <Grid item xs={12}>
            {instruments.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.instrument_name}
                            <IconButton size="small" onClick={() => {deleteInstrument(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <div>
           <h3 style={headerStyle}>
                    Export
                </h3>

                <Dialog open={open} onClose={handleDeleteClose}>
                    <DialogTitle>
                        Are you sure you want to delete this??
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDelete}>
                            YES
                        </Button>
                        <Button onClick={handleDeleteClose}>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">Export Files</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Formik initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}>
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                id="request_type"
                                                                label="Request type"
                                                                {...formik.getFieldProps('request_type')}
                                                                select
                                                                value={formik.values.request_type}
                                                                onChange={(e) => {
                                                                    formik.handleChange(e);
                                                                }}
                                                                error={formik.touched.request_type && Boolean(formik.errors.request_type)}
                                                                helperText={formik.touched.request_type && formik.errors.request_type}
                                                            >
                                                                {requestOptions.map((option, index) => (
                                                                    <MenuItem key={index} value={option.value}>
                                                                    {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                id="upload_format"
                                                                label="Format"
                                                                {...formik.getFieldProps('upload_format')}
                                                                select
                                                                value={formik.values.upload_format}
                                                                onChange={(e) => {
                                                                    formik.handleChange(e);
                                                                }}
                                                                error={formik.touched.upload_format && Boolean(formik.errors.upload_format)}
                                                                helperText={formik.touched.upload_format && formik.errors.upload_format}
                                                            >
                                                                {uploadFormatOptions.map((option, index) => (
                                                                    <MenuItem key={index} value={option.value}>
                                                                    {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                autoComplete='off'
                                                                id="instruments"
                                                                label="Instruments"
                                                                value=""
                                                                ref={instrumentTextField}
                                                                onClick={() => setInstrumentInfiniteScroll(true)}
                                                                error={formik.touched.instruments && Boolean(formik.errors.instruments)}
                                                                helperText={formik.touched.instruments && formik.errors.instruments}
                                                            >
                                                            </TextField>
                                                        </Grid>
                                                        

                                                        { showInstrumentInfiniteScroll ? <Grid item xs={12}>

                                                        <div ref={instrumentInfiniteScrollRef}>
                                                        <InfiniteScroll
                                                                    dataLength={instrumentList.results.length}
                                                                    next={fetchInstrumentData}
                                                                    hasMore={currentInstrumentListSize.current >= offset}
                                                                    loader={<h4>Loading...</h4>}
                                                                    height={400}
                                                                    
                                                                    endMessage={
                                                                        <p style={{ textAlign: "center" }}>
                                                                        <b>Yay! You have seen it all</b>
                                                                        </p>
                                                                    
                                                                    }>
                                                                    
                                                                    {instrumentList.results.map((option, index) => {
                                                                        return (
                                                                        <MenuItem key={index} value={option.id} onClick={
                                                                            () => {
                                                                                addInstrument(option)
                                                                            }
                                                                        }>
                                                                            {option.instrument_name} 
                                                                        </MenuItem>
                                                                        );}
                                                                    )}

                                                                </InfiniteScroll>
                                                                </div>
                                                        </Grid> : <div></div>}

                                                        <Grid item xs={12}>
                                                            {instrument_list}
                                                        </Grid>                                               
                                                
                                            
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => {handleSubmit(formik.values)}}
                                                                type='submit'
                                                                size="medium"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Export
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header style={{ display: 'flex' }}>
                            <CustomToggle eventKey="1">Export List</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex'}}>
                                        <DatePicker
                                                placeholderText='Delete Before'
                                                selected={ deleteByDate }
                                                onChange={ handleDeleteByDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                            >    
                                        </DatePicker>
                                        <Button 
                                                onClick={ onDeleteByDateSubmit }
                                                size="small"
                                                variant="contained"
                                            >
                                                Delete
                                        </Button>
                                    </div>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="export"
                                            label="Export"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Table>
                                    <TableHead width="100%">
                                        <TableRow>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("request_timestamp")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Date of Request</div><div>{sorting_query === "request_timestamp" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-request_timestamp")}></FaSortUp> : 
                                                    sorting_query === "-request_timestamp" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("request_timestamp")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("upload_format")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Format</div><div>{sorting_query === "upload_format" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-upload_format")}></FaSortUp> : 
                                                    sorting_query === "-upload_format" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("upload_format")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("request_type")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Request Type</div><div>{sorting_query === "request_type" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-request_type")}></FaSortUp> : 
                                                    sorting_query === "-request_type" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("request_type")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("upload")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Upload</div><div>{sorting_query === "upload" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-upload")}></FaSortUp> : 
                                                    sorting_query === "-upload" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("upload")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="right">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        getList().map((r) => {
                                            return (
                                                <TableRow key={r.id}>
                                                    <TableCell align="center">
                                                        {r.request_timestamp !== null ? new Date(r.request_timestamp).toLocaleString() : ""}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {r.request_type}    
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {r.upload_format}    
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {r.upload !== null ?
                                                            <a href={`${process.env.REACT_APP_API_URL}${r.upload}`}>
                                                                {`${process.env.REACT_APP_API_URL}${r.upload}`}
                                                            </a> : ""
                                                        }

                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }} >

                                                            <IconButton size="small" onClick={() => {
                                                                handleConfirmDelete(r.id)
                                                            }}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                                </Table>
                                {exp !== "" || currentExportListSize.current < offset ? <div></div> : <div className='centered-container'>
                                    <Button onClick={fetchData}>
                                                LOAD MORE!
                                    </Button>
                                </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </div>
    )
}


