import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import React, { useEffect, useState, useRef } from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCircle, faMapMarkerAlt, faTag, faClock, faCalendar, faChartLine } from '@fortawesome/free-solid-svg-icons';

 export const LatestTab = ({latest, instrument, location}) => {

    return (
        <Grid container spacing={3}>
            <Grid item xs={8}>
            <table className="excel-table">
            <thead>
                <tr>
                  <th>S/No</th>
                  <th>Next Calibration Date</th>
                  <th>Last Calibration Date</th>
                  <th>Location</th>
                  <th>Category</th>
                  <th>Date and Time</th>
                  <th>Current LEQ 5</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td>{instrument.instrument_serial_number}</td>
                    <td>{instrument.next_calibration_date.toString().split("T")[0]}</td>
                    <td>{instrument.last_calibration_date.toString().split("T")[0]}</td>
                    <td>{location.location_name}</td>
                    <td>{location.category}</td>
                    <td>{new Date(latest.timestamp).toLocaleString('en-GB', {hour12: true})}</td>
                    <td>{latest.leq_value}</td>
                    <td>{latest.status}</td>
                </tr>
              </tbody>
            </table>
            </Grid>
            
        </Grid>
    )
}