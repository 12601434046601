import React, { useEffect, useMemo, useState, createContext } from 'react';
import PropTypes from "prop-types";
import axios from "axios";

import { isNull } from 'lodash';

// Certain types of props (e.g. locale preference, UI theme) that are 
// required by many components within an application
// Context provides a way to share values like these between components 
// without having to explicitly pass a prop through every level of the 
// tree
export const AuthContext = createContext({
    isAuthenticated: true,
    setIsAuthenticated: () => { },
    user: null,
    setUser: () => { }
})


export default function AuthContextProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [user, setUser] = useState(null);

    // Load the authentication token in local storage
    const loadAuthUser = () => {
        const authToken = localStorage.getItem("accessToken");

        // Return if the authToken is valid
        if (authToken !== null) {
            setIsAuthenticated(true);
            return;
        } 

        setIsAuthenticated(true);
    }

    // useMemo will only recompute the memoized value when one of the dependencies 
    // has changed. This optimization helps to avoid expensive calculations on every 
    // render.
    const providerValue = useMemo(() => {
        return {
            isAuthenticated,
            setIsAuthenticated,
            user,
            setUser
        }
    }, [isAuthenticated, setIsAuthenticated, user, setUser])

    // Only loadAuthUser when user has changed and isAuthenticated is not false
    useEffect(() => {
        if (!user && (isAuthenticated === null || isAuthenticated === true)) {
            loadAuthUser();
        }
    }, [user, isAuthenticated])


    // Provide the AuthContext for descendants to use, all the variables in value 
    // can be access by the children
    return (
        <AuthContext.Provider value={providerValue}>
            {children}
        </AuthContext.Provider>
    )
}
