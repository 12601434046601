import React, { useEffect, useState, useRef} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Dialog, DialogTitle, DialogActions, IconButton, Box, Button, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"; 
import useRequestResource from '../../hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from 'yup';
import DeleteIcon from "@mui/icons-material/Delete"
import DatePicker from 'react-datepicker';
import {Search, SearchIconWrapper, StyledInputBase} from '../../components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const validationSchema = yup.object({
    xml_type: yup.string().required("XML Type is required").max(255, "Max Length is 255!"),
})

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }


export default function Export() {
    const { resourceList: exportList, getResourceList: getExportList, currentListSize: currentExportListSize, searchResources: searchExport, searchList: searchExportList} = useRequestResource({ endpoint: "auth", query: "export_xml_files", resourceLabel: "Export XML List" });
    const { addResource, getResource, resources, deleteResourcesBefore, deleteResource } = useRequestResource({endpoint: "auth", query: "export_xml_file", resourceLabel: "Export XML"})

    const offset = 25;
    const [exp, setExp] = useState("")

    const [sorting_query, setSortingQuery] = useState(null)

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);

    const [queriedStartDate, handleQueriedStartDateChange] = useState(null)
    const [queriedEndDate, handleQueriedEndDateChange] = useState(null)

    const [initialValues, setInitialValues] = useState({
        queried_start_date: "",
        queried_end_date: "",
        xml_type: ""
    });

    const xmlTypeOptions = [
        { value: 'True', label: 'Inactive Devices' },
        { value: 'False', label: 'Records' },
    ]

    const fetchData = () => {
        getExportList(offset, sorting_query)
    }

    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getExportList(0, sorting_query)
        }
    }, [sorting_query, getExportList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    const handleSubmit = values => {
        console.log(values, queriedStartDate, queriedEndDate)
        const formattedValues = {
            queried_start_date: queriedStartDate ? queriedStartDate : null,
            queried_end_date: queriedEndDate ? queriedEndDate : null,
            xml_type: values.xml_type ? values.xml_type : null
        }
        addResource(formattedValues, () => {
            window.location.reload();
        })
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    const searchData = () => {
        searchExport(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchExportList.results
        } else {
            console.log(exportList.results)
            return exportList.results
        }
    }
    
    return (
        <div>
           <h3 style={headerStyle}>
                    Export
                </h3>

                <Dialog open={open} onClose={handleDeleteClose}>
                    <DialogTitle>
                        Are you sure you want to delete this??
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDelete}>
                            YES
                        </Button>
                        <Button onClick={handleDeleteClose}>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">Export Xml Files</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Formik initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}>
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12}>
                                                            <DatePicker
                                                                placeholderText='Start Date'
                                                                selected={ queriedStartDate }
                                                                onChange={ handleQueriedStartDateChange }
                                                                name="queriedStartDate"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={20}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                autoComplete='off'
                                                                popperPlacement="top-start"
                                                            >    
                                                            </DatePicker>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <DatePicker
                                                                placeholderText='End Date'
                                                                selected={ queriedEndDate }
                                                                onChange={ handleQueriedEndDateChange }
                                                                name="queriedEndDate"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={20}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                autoComplete='off'
                                                                popperPlacement="top-start"
                                                            >    
                                                            </DatePicker>
                                                        </Grid>
                                                        
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                id="xml_type"
                                                                label="Type of Data Within XML"
                                                                {...formik.getFieldProps('xml_type')}
                                                                select
                                                                value={formik.values.xml_type}
                                                                onChange={(e) => {
                                                                    formik.handleChange(e);
                                                                }}
                                                                error={formik.touched.xml_type && Boolean(formik.errors.xml_type)}
                                                                helperText={formik.touched.xml_type && formik.errors.xml_type}
                                                            >
                                                                {xmlTypeOptions.map((option, index) => (
                                                                    <MenuItem key={index} value={option.value}>
                                                                    {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => {handleSubmit(formik.values)}}
                                                                type='submit'
                                                                size="medium"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Export XML
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header style={{ display: 'flex' }}>
                            <CustomToggle eventKey="1">Export XML List</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex'}}>
                                        <DatePicker
                                                placeholderText='Delete Before'
                                                selected={ deleteByDate }
                                                onChange={ handleDeleteByDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                            >    
                                        </DatePicker>
                                        <Button 
                                                onClick={ onDeleteByDateSubmit }
                                                size="small"
                                                variant="contained"
                                            >
                                                Delete
                                        </Button>
                                    </div>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="export"
                                            label="Export"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Table>
                                    <TableHead width="100%">
                                        <TableRow>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("request_timestamp")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Date of Request</div><div>{sorting_query === "request_timestamp" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-request_timestamp")}></FaSortUp> : 
                                                    sorting_query === "-request_timestamp" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("request_timestamp")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("queried_start_date")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Start Date</div><div>{sorting_query === "queried_start_date" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-queried_start_date")}></FaSortUp> : 
                                                    sorting_query === "-queried_start_date" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("queried_start_date")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("queried_end_date")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>End Date</div><div>{sorting_query === "queried_end_date" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-queried_end_date")}></FaSortUp> : 
                                                    sorting_query === "-queried_end_date" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("queried_end_date")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("upload")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Upload</div><div>{sorting_query === "upload" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-upload")}></FaSortUp> : 
                                                    sorting_query === "-upload" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("upload")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="right">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        getList().map((r) => {
                                            return (
                                                
                                                <TableRow key={r.id}>
                                                    <TableCell align="center">
                                                        {r.request_timestamp !== null ? new Date(r.request_timestamp).toLocaleString() : ""}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {r.queried_start_date !== null ? new Date(r.queried_start_date).toLocaleString() : ""}    
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {r.queried_end_date !== null ? new Date(r.queried_end_date).toLocaleString() : ""}    
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {r.upload !== null ?
                                                            <a href={`${process.env.REACT_APP_API_URL}${r.upload}`}>
                                                                {`${process.env.REACT_APP_API_URL}${r.upload}`}
                                                            </a> : ""
                                                        }

                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }} >

                                                            <IconButton size="small" onClick={() => {
                                                                handleConfirmDelete(r.id)
                                                            }}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                                </Table>
                                {exp !== "" || currentExportListSize.current < offset ? <div></div> : <div className='centered-container'>
                                    <Button onClick={fetchData}>
                                                LOAD MORE!
                                    </Button>
                                </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </div>
    )
}


