import React, { useState } from 'react';

const DropdownSelect = ({ options, title, onChange }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div>
      <label htmlFor="dropdown-select">{title}:</label>
      <select
        id="dropdown-select"
        value={selectedOption}
        onChange={handleChange}
      >
        <option value="">Select an option</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownSelect;
