import React, { useEffect } from 'react'
import Map from '../utils/maptab';
import useRequestResource from '../../hooks/useRequestResource';

const headerStyle = {
  color: "white",
  backgroundColor: "Green",
  padding: "10px",
  fontFamily: "Arial"
};

const DashBoard = () => {
  const {resourceList, getResourceList, currentListSize} = useRequestResource({endpoint: "auth", query: "instruments", resourceLabel: "Instruments"})
  useEffect(() => {
    getResourceList()
  }, [getResourceList])

  return (
    <div >
      <Map instruments={resourceList.results} height={"800px"}></Map>
    </div>
  )
}

export default DashBoard