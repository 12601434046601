import { useCallback, useState, useContext, useRef } from "react";
import { Outlet, Navigate, useNavigate} from "react-router-dom"
import axios from "axios";
import { useSnackbar } from "notistack";

import FormatHttpApiError from "../helpers/formatHttpApiError";
import { LoadingOverlayResourceContext } from "../components/LoadingOverlayResource";

export default function useRequestResource({ endpoint, query, resourceLabel }) {
    const navigate = useNavigate();
    const [resourceList, setResourceList] = useState({
        results: []
    });

    const [processedDataList, setProcessedDataList] = useState({
        results: null,
        instrument: {},
        user: {},
        latest: {},
        location: {},
        category: {},
        start: "",
        end: ""
    });
    const [searchList, setSearchList] = useState({
        results: []
    });
    
    const currentListSize = useRef(0);
    const pageNumber = useRef(0);
    const [resources, setResource] = useState(null);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const loadingOverlay = useContext(LoadingOverlayResourceContext);
    const { setLoading } = loadingOverlay;
    const displayMaintenanceWindow = event => window.alert("Geomotion Singapore website is currently under maintenance. Do come back later!");

    const handleRequestResourceError = useCallback((err) => {
        const formattedError = FormatHttpApiError(err);
        setError(formattedError);
        setLoading(false);
        enqueueSnackbar(formattedError);
        if (err.request.status === 503) {
            displayMaintenanceWindow();
        } else if (err.request.status === 401) {
            navigate("/login/token")
        } else {
            enqueueSnackbar(formattedError);
        }
    }, [enqueueSnackbar, setError, setLoading])

    const getResourceList = useCallback((offset, sorting_query, startDate, endDate, timezone, instrument) => {
        setLoading(true);
        if (offset) {
            pageNumber.current = pageNumber.current + offset
        } else {
            pageNumber.current = 0
        }//resets offset to 0
        axios.get(`/${endpoint}/${query}/`, { headers: {
            'Authorization': `JWT ${localStorage.getItem("accessToken")}`
        }, params: {offset: pageNumber.current, sorting_query: sorting_query, start: startDate, end: endDate, timezone: timezone, exp: instrument}})
            .then((res) => {
                setLoading(false);
                // set the current list size to the length of data retrieved
                // currentListSize is probably the wrong name, should be the size of the most recent data retrieved only
                // currentListSize <= offset = 25
                currentListSize.current = res.data.results.length
                if (pageNumber.current === 0) {
                    setResourceList(prevState => ({
                        results: [...res.data.results],
                      }));
                } else {
                    setResourceList(prevState => ({
                        results: [...prevState.results, ...res.data.results],
                      }));
                }
            }).catch(handleRequestResourceError)
    }, [endpoint, handleRequestResourceError, setLoading])

    const searchResources = useCallback((exp, sorting_query, offset) => {
        setLoading(true);
        if (offset) {
            pageNumber.current = pageNumber.current + offset
        } else {
            pageNumber.current = 0
        }//resets offset to 0
        axios.request({ method: "GET",
        url: `/${endpoint}/${query}/`, 
                headers: {'Authorization': `JWT ${localStorage.getItem("accessToken")}`}, 
                params: {sorting_query: sorting_query, exp: exp, offset: pageNumber.current} 
            }).then((res) => {
                setLoading(false);
                // set the current list size to the length of data retrieved
                currentListSize.current = res.data.results.length
                if (pageNumber.current === 0) {
                    setSearchList(prevState => ({
                        results: [...res.data.results],
                      }));
                } else {
                    setSearchList(prevState => ({
                        results: [...prevState.results, ...res.data.results],
                      }));
                }
            }).catch(handleRequestResourceError)
    }, [endpoint, handleRequestResourceError, setLoading])

    const getProcessedDataList = useCallback(({offset, instrument, start, end}) => {
        setLoading(true);
        axios.get(`/${endpoint}/${query}/`, { headers: {
            'Authorization': `JWT ${localStorage.getItem("accessToken")}`
        }, params: {offset: pageNumber.current, instrument: instrument, start: start, end: end}})
            .then((res) => {
                setLoading(false);
                setProcessedDataList(prevState => ({
                    results: res.data.results,
                    instrument: res.data.instrument,
                    user: res.data.user,
                    latest: res.data.latest,
                    location: res.data.location,
                    category: res.data.category,
                    start: res.data.start,
                    end: res.data.end
                  }));
            }).catch(handleRequestResourceError)
    }, [endpoint, handleRequestResourceError, setLoading])

    const addResource = useCallback(
        (values, successCallback) => {
            setLoading(true);
            axios.post(`/${endpoint}/${query}/`, 
                        values, 
                        { headers: {'Authorization': `JWT ${localStorage.getItem("accessToken")}`
            }}).then((response) => {
                setLoading(false);
                enqueueSnackbar(`${resourceLabel} added`)
                if (successCallback) {
                    successCallback();
                }
            }).catch(handleRequestResourceError)
        }, [endpoint, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading])

    const getResource = useCallback((id) => {
        setLoading(true);
        axios.patch(`/${endpoint}/${query}/`, 
                    {'id': id }, 
                    { headers: {'Authorization': `JWT ${localStorage.getItem("accessToken")}`} 
                }).then((res) => {
                setLoading(false);
                const data = res;
                setResource(data.data);
            }).catch(handleRequestResourceError)
    }, [endpoint, handleRequestResourceError, setLoading])

    const updateResource = useCallback((id, values, successCallback) => {
        setLoading(true);
        if (values instanceof FormData) {
            var json_data = JSON.parse(values.get('json_data'))
            json_data['id'] = id
            values.set("json_data", JSON.stringify(json_data))
        } else {
            values = {'id': id, ...values};
        }

        

        axios.put(`/${endpoint}/${query}/`, 
                    values,  
                    { headers: {'Authorization': `JWT ${localStorage.getItem("accessToken")}`} 
                }).then((res) => {
                /**
                 * Replacing the task to be updated inside the list with the 
                 * data obtained from the API, so the list will be displayed 
                 * with the updated task
                 */
                const updated = res.data;
                const newResourceList = {
                    results: resourceList.results.map((r) => {
                        if (r.id === id) {
                            return updated;
                        }
                        return r;
                    }),
                    count: resourceList.count
                }
                setResourceList(newResourceList);
                setLoading(false);
                enqueueSnackbar(`${resourceLabel} updated`)
                if (successCallback) {
                    successCallback();
                }
            }).catch(handleRequestResourceError)
    }, [endpoint, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading, resourceList])

    const updatePassword = useCallback((id, values, successCallback) => {
        setLoading(true);
        if (values instanceof FormData) {
            var json_data = JSON.parse(values.get('json_data'))
            json_data['id'] = id
            values.set("json_data", JSON.stringify(json_data))
        } else {
            values = {'id': id, ...values};
        }

        

        axios.put(`/${endpoint}/${query}/`, 
                    values,).then((res) => {
                /**
                 * Replacing the task to be updated inside the list with the 
                 * data obtained from the API, so the list will be displayed 
                 * with the updated task
                 */
                const updated = res.data;
                const newResourceList = {
                    results: resourceList.results.map((r) => {
                        if (r.id === id) {
                            return updated;
                        }
                        return r;
                    }),
                    count: resourceList.count
                }
                setResourceList(newResourceList);
                setLoading(false);
                enqueueSnackbar(`${resourceLabel} updated`)
                if (successCallback) {
                    successCallback();
                }
            }).catch(handleRequestResourceError)
    }, [endpoint, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading, resourceList])

    const deleteResource = useCallback((id) => {
        setLoading(true);
        axios.delete(`/${endpoint}/${query}/`, { 
            headers: {
                'Authorization': `JWT ${localStorage.getItem("accessToken")}`
            },
            data: {
                'id': id
            }
        })
        .then(() => {
            setLoading(false);
            enqueueSnackbar(`${resourceLabel} deleted`)
            window.location.reload();
        }).catch(handleRequestResourceError)
    }, [endpoint, resourceList, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading])

    const deleteMultipleResource = useCallback((list, successCallback) => {
        setLoading(true);
        axios.delete(`/${endpoint}/${query}/`, { 
            headers: {
                'Authorization': `JWT ${localStorage.getItem("accessToken")}`
            },
            data: {
                'list': list
            }
        })
        .then(() => {
            setLoading(false);
            enqueueSnackbar(`${resourceLabel} deleted`)
            if (successCallback) {
                successCallback();
            }
            // const newResourceList = {
            //     results: resourceList.results.filter((r) => {
            //         return r.id !== id
            //     })
            // }
            // setResourceList(newResourceList);
        }).catch(handleRequestResourceError)
    } , [endpoint, resourceList, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading])

    const deleteResourcesBefore = useCallback((date, successCallback) => {
        setLoading(true);
        axios.delete(`/${endpoint}/${query}/delete_before/`, { 
            headers: {
                'Authorization': `JWT ${localStorage.getItem("accessToken")}`
            },
            data: {
                'date': date
            }
        })
        .then(() => {
            setLoading(false);
            enqueueSnackbar(`${resourceLabel} deleted`)
            if (successCallback) {
                successCallback();
            }
        }).catch(handleRequestResourceError)
    }, [endpoint, resourceList, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading])

    const resendMultipleNotifications = useCallback((values, successCallback) => {
        setLoading(true);
        axios.post(`/${endpoint}/${query}/`, 
            values,
            { headers: { 'Authorization': `JWT ${localStorage.getItem("accessToken")}`
        }}).then((response) => {
            setLoading(false);
            enqueueSnackbar(`${resourceLabel} resend`)
            if (successCallback) {
                successCallback();
            }
        }).catch(handleRequestResourceError)
    } , [endpoint, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading])

    return {
        resourceList,
        getResourceList,
        getProcessedDataList,
        processedDataList,
        addResource,
        currentListSize,
        resources,
        getResource,
        updateResource,
        updatePassword,
        deleteResource,
        searchResources,
        searchList,
        deleteMultipleResource,
        deleteResourcesBefore,
        resendMultipleNotifications,
        error
    }
}
