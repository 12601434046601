import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import "../../App.css"
import Form from 'react-bootstrap/Form';
import useRequestResource from '../../hooks/useRequestResource';
import ColorPicker from "../../components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from '../../components/utils/DropdownSelect';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';


const validationSchema = yup.object({
    location_name: yup.string().required("Client name is required").max(255, "Max Length is 255!"),
    latitude: yup.number().required("Latitude is required"),
    longitude: yup.number().required("Longitude is required"),
    altitude: yup.number().required("Altitude is required"),
    postal_code: yup.number().required("Postal Code is required"),
    category: yup.string().required("Category is required").max(255, "Max Length is 255!"),
})

const CreateLocation = () => {
    const categoryInfiniteScrollRef = useRef(null);
    const categoryTextField = useRef(null); 
    const [category, setCategory] = useState(null)
    const { getResourceList: getCategoryList, resourceList: categoryList, currentListSize: currentCategoryListSize } = useRequestResource({ endpoint: "auth", query: "categorys", resourceLabel: "Category List" });
    const { addResource }  = useRequestResource({ endpoint: "auth", query: "location", resourceLabel: "location" });
    const navigate = useNavigate();
    const [ showCategoryInfiniteScroll, setCategoryInfiniteScroll ] = useState(false)
    let offset = 25;

    const [initialValues, setInitialValues] = useState({
        location_name: "",
        longitude: "",
        latitude: "",
        altitude: "",
        postal_code: "",
        category: ""
    });

    const fetchCategoryData = () => {
        getCategoryList(offset)
    }

    useEffect(() => {
        getCategoryList()
    }, [getCategoryList])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children

        if (categoryInfiniteScrollRef.current && !categoryInfiniteScrollRef.current.contains(event.target)) {
            if (categoryTextField.current && categoryTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setCategoryInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            category: values.category ? values.category : null,
            location_name: values.location_name ? values.location_name : null,
            latitude: values.latitude ? values.latitude : null,
            longitude: values.longitude ? values.longitude : null,
            altitude : values.altitude ? values.altitude : null,
            postal_code : values.postal_code ? values.postal_code : null
        }

        addResource(formattedValues, () => {
            navigate(`/locations`)
        })
    }
    return (
        <div style={{alignItems: "start"}}>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Location Information
                </Typography>

                <br />
            <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return <form onSubmit={formik.handleSubmit} style={{textAlign: "start"}}>
                                <Grid container spacing={3} style={{alignItems: "start"}}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="category"
                                                label={category ? "" : "Category "}
                                                {...formik.getFieldProps('category')}
                                                value={category}
                                                onChange={(e) => {formik.setFieldValue("category", "")}}
                                                ref={categoryTextField}
                                                onClick={() => setCategoryInfiniteScroll(true)}
                                                error={(formik.touched.category) && Boolean(formik.errors.category)}
                                                helperText={(formik.touched.category) && formik.errors.category}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showCategoryInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={categoryInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={categoryList.results.length}
                                                    next={fetchCategoryData}
                                                    hasMore={currentCategoryListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {categoryList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("category", option.id)
                                                                formik.setFieldTouched("category", false)
                                                                formik.setFieldError("category", "")
                                                                setCategory(`${option.category_name}`)
                                                                setCategoryInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.category_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="location_name"
                                        label="Location Name"
                                        {...formik.getFieldProps('location_name')}
                                        error={formik.touched.location_name && Boolean(formik.errors.location_name)}
                                        helperText={formik.touched.location_name && formik.errors.location_name}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="latitude"
                                        label="Latitude"
                                        {...formik.getFieldProps('latitude')}
                                        error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                                        helperText={formik.touched.latitude && formik.errors.latitude}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="longitude"
                                        label="Longitude"
                                        {...formik.getFieldProps('longitude')}
                                        error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                                        helperText={formik.touched.longitude && formik.errors.longitude}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="altitude"
                                        label="Altitude"
                                        {...formik.getFieldProps('altitude')}
                                        error={formik.touched.altitude && Boolean(formik.errors.altitude)}
                                        helperText={formik.touched.altitude && formik.errors.altitude}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="postal_code"
                                        label="Postal Code"
                                        {...formik.getFieldProps('postal_code')}
                                        error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
                                        helperText={formik.touched.postal_code && formik.errors.postal_code}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Button component={Link}
                                        to={`/locations`}
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        sx = {{ mr: 2 }}
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        // type="submit"
                                        onClick={() => {handleSubmit(formik.values)}}
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Create New Location
                                    </Button>

                                </Grid>
                            </Grid>
                            </form>
                        }
                    }
                </Formik>
                </Paper>
        </div>
    )
}

export default CreateLocation