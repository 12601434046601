import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/icons-material/HourglassFull';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import useRequestAuth from '../../hooks/useRequestAuth';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { render } from '@testing-library/react';
import icon_image from "../../testLogo1.png"



// Yup is a JavaScript schema builder for value parsing and validation
const validationSchema = yup.object({
    // When string is empty, we will put the following in the field
    username: yup.string().required("Username is required!"),
    password: yup.string().required("Password is required!")
})




// const theme = createTheme();

export default function SignIn() {
    // Link the useRequestAuth function and values
    const { login, loading } = useRequestAuth();
    const [message, setMessage] = useState(null);

    const navigate = useNavigate();

    // When successfully authenticated, save to local storage
    const success = async (text, username) => {

        const is_manager = localStorage.getItem("is_manager")
        const has_noise_monitoring_access = localStorage.getItem("Noise Meter")
        const has_farmo_rain_gauge_access = localStorage.getItem("Farmo Rain Gauge")
        const has_esp32_rain_gauge_access = localStorage.getItem("ESP32 Rain Gauge")
        const has_kerlink_gateway_access = localStorage.getItem("Kerlink Gateway")
        const has_vibration_meter_access = localStorage.getItem("Vibration Meter")
    
        if (is_manager === "true" || has_noise_monitoring_access === "true") {
            navigate("/datatable")
        } else if (has_farmo_rain_gauge_access === "true") {
            navigate("/rain")
        } else if (has_esp32_rain_gauge_access === "true") {
            navigate("/esp32rain")
        } else if (has_kerlink_gateway_access === "true") {
            navigate("/kerlinkreadings")
        } else if (has_vibration_meter_access === "true") {
            //TODO
            navigate("/profile")
        } else {
            navigate("/profile")
        }
        
    };

    const tryLogin = async (e) => {
        await login(e.username, e.password, success, (text) => { 
            setMessage(text);
        });
    };


    
    const renderError = (message) => 
    {
        return (
            <Alert variant="outlined" severity="error">
                {message.split(":")[1]}
            </Alert>            
        )
    }


    // On submit, we submit the values to the login callback function
    const handleSubmit = (values) => {
        tryLogin(values);
    };

    return (
        <Container component="main" maxWidth="xs" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6" noWrap sx={{ flexGrow: 1, color: "primary" }}>
                    <img src={icon_image} alt="Logo" style={{ height: 200, width: 500 }} />
                    </Typography>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {"Sign into"}
                </Typography>
                <Typography component="h1" variant="h5">
                    {"Noise Platform"}
                </Typography>
                <Formik validationSchema={validationSchema} validateOnBlur={false} onSubmit={handleSubmit}
                    initialValues={{
                        username: "",
                        password: ""
                    }}
                >
                    {(formik) => {
                        return (
                            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                                {message ? renderError(message) : <div></div>}
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    autoFocus

                                    // spread operator ( ... ) allows us to quickly copy all or part of 
                                    // an existing array or object into another array or object.
                                    {...formik.getFieldProps("username")}
                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                    helperText={formik.touched.username && formik.errors.username}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password" // So that it will be hidden when you key in
                                    id="password"
                                    autoComplete="current-password"
                                    {...formik.getFieldProps("password")}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />

                                <Link to={"/login/reset"} key={"reset"}>
                                    {"Forgot your password?"}
                                </Link>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    loading={loading}
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign In
                                </Button>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        </Container>
    );
}